<script lang="ts">
  import { comparer } from "$utils/sort";

  import RecordItem from "$components/record/RecordItem.svelte";
  export let param: string = "unit";
  export let units: Units;
</script>

<ul class="items">
  {#each Object.values(units?.items ?? {}).sort(comparer((item) => item["addr:unit"] || item.display)) as item}
    <li>
      <a
        class="unit record"
        href="{location.pathname}?{param}={item.id}&level={item.level || ''}"
        ><RecordItem {item} /></a
      >
    </li>
  {/each}
</ul>
