<script lang="ts">
  import { createEventDispatcher } from "svelte";

  let className: string = "";
  export { className as class };

  let element: HTMLFormElement;

  const events = createEventDispatcher<{
    submit: FormData;
    reset: undefined;
  }>();

  function submit() {
    events("submit", new FormData(element));
  }
  function reset() {
    events("reset");
  }
</script>

<form
  class={className || null}
  bind:this={element}
  on:submit|preventDefault={submit}
  on:reset={reset}
>
  <slot />
</form>
