<script lang="ts">
  import Image from "$components/util/Image.svelte";
  import PositionData from "./PositionData.svelte";
  import PositionWatchButton from "./PositionWatchButton.svelte";

  //import { locate, position } from "$utils/uistores";

  export let value: Position;
</script>

<figure class="position {value.status}" class:coords={!!value?.coords}>
  <figcaption>
    <!-- <h1><PositionData /></h1> -->
    {#if !value.requested}
      <PositionWatchButton autostop={false} />
    {:else if value.error}
      <PositionData />
    {/if}
  </figcaption>

  {#if value?.coords}
    <Image
      alt="Map of your location"
      src="https://api.maptiler.com/maps/dataviz/static/{value.coords
        .longitude},{value.coords
        .latitude},17/600x600.png?key=ArA8Gnz66MxvP3Hnzdj9"
    />
  {/if}
</figure>

<!-- <figure class="map position">
    <figcaption>
      
      {#if false === $position.requested}
        <button class="locate" type="button" on:click={(e) => locate()}
          >Turn on</button
        >
      {/if}
    </figcaption>
    <Image
      alt="Map of the United States"
      src="https://api.maptiler.com/maps/openstreetmap/static/-103.771556,44.967243,2/600x600.png?key=ArA8Gnz66MxvP3Hnzdj9"
    />
    {#if $position.coords}
      <Image
        alt="Map of your location"
        src="https://api.maptiler.com/maps/openstreetmap/static/{$position
          ?.coords.longitude},{$position?.coords
          .latitude},17/600x600.png?key=ArA8Gnz66MxvP3Hnzdj9"
      />
    {/if}
  </figure> -->
