<script lang="ts">
  import type { Feature } from "geojson";

  let classname: string = "";
  export { classname as class };
  export let item: Feature;
  let element: HTMLDataElement;

  function updateElement(el, feature) {
    if (!el) return;

    //if(!feature) {
    //logger("marker update", el.dataset);
    const remove = [];
    for (let i = 0; i < el.attributes.length; i++)
      if (el.attributes[i].name.startsWith("data"))
        remove.push(el.attributes[i].name);
    for (const name of remove) el.removeAttribute(name);
    //el.innerHTML = `$`;
    //}
    if (!!feature) {
      el.setAttribute("data-type", feature.type);
      for (let [key, value] of Object.entries(feature.properties)) {
        if (key == "image") continue;
        if (key == "url") continue;

        el.setAttribute("data-" + key.replace(/[^0-9a-z]/gi, "-"), value);
      }
    }
  }

  $: updateElement(element, item);
</script>

<data
  class={[item.type?.toLowerCase(), classname].filter(Boolean).join(" ")}
  value={item.id || ""}
  bind:this={element}><slot /></data
>
