<script lang="ts">
  import Submit from "$components/form/Submit.svelte";
  import { request, requestwallet } from "$utils/stripe";
  import type { Token } from "@stripe/stripe-js";
  import { createEventDispatcher } from "svelte";
  import { writable } from "svelte/store";

  export let payment: Payment;
  export let submittable: boolean = false;
  export let submitting: boolean = false;

  const events = createEventDispatcher<{ submit: Token }>();

  const pay = writable<Payment | null>(payment);
  const paymentrequest = request(pay);
  const wallet = requestwallet(paymentrequest);

  let token: Token | nullish = null;

  $: pay.set(payment);

  $: pr = $paymentrequest;
  $: valid = !!pr;
  // wire up events to an active payment request
  $: if (pr) {
    pr.on("cancel", () => {
      logger("payment request cancel");
      submitting = false;
    }).on("token", (event) => {
      logger("token", event);
      token = event.token;
      events("submit", token); // send up the token
    });
  }

  function handle() {
    if (!pr) return;
    submitting = true;
    pr.show(); // triggers the payment request, the rest is async
  }
</script>

<!-- error here? -->
<slot />
<footer>
  <Submit
    class={Object.entries($wallet || {})
      .filter(([k, v]) => v)
      .map(([k, v]) => k)
      .join(" ")}
    disabled={!valid || !submittable || submitting}
    on:submit={handle}>Pay</Submit
  >
  <!-- Pay -->
</footer>
