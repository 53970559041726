<script lang="ts" context="module">
  let i = 0;
</script>

<script lang="ts">
  import Field from "$components/form/Field.svelte";
  import Input from "$components/form/Input.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import { createEventDispatcher } from "svelte";

  export let name: string = "agreement";
  export let policy: PermitIssuePolicy;
  export let values: Record<string, string | nullish> = {};
  let value: string | nullish;

  const id = `${name}-${i++}`;

  $: value = values[name]; // pull from values

  const eventing = createEventDispatcher<{
    change: Record<string, typeof value>;
  }>();

  function change(updated: typeof value) {
    eventing("change", {
      [name]: (value = updated),
    });
  }
</script>

{#if policy?.agreement?.request}
  <UiFormFieldItem>
    <Field class="agreement">
      <Input
        type="checkbox"
        {id}
        {name}
        checked={!!value}
        required={!!policy.agreement.required}
        value={policy.agreement.text}
        on:change={(e) => change(e.detail.value)}
      /><label for={id}>{policy.agreement.text}</label>
    </Field>
  </UiFormFieldItem>
{/if}
