
import {
  base,
  viewpoint,
  responseJson,
} from "$utils/api";


export async function fetchSend(id: string, to: string[]): Promise<any> {
    const url = new URL(`${base()}/send?viewpoint=${viewpoint()}`);
    //logger("permits=", url);

    url.searchParams.set("container", id);

    for (const t of to) {
      url.searchParams.append("to", t);
    }

    const res = await fetch(url, {
        method: "POST",
    });
    //logger("spaces res", res);

    const json = await responseJson(res);

    return json;
  }

