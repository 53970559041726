<script lang="ts">
  import PropertyPhoto from "$components/property/PropertyPhoto.svelte";
  import PropertySection from "$components/property/PropertySection.svelte";
  import Nav from "$components/ui/Nav.svelte";
  import Loading from "$components/util/Loading.svelte";

  export let property: Property | nullish;
</script>

{#if property}
  <PropertySection {property}>
    <nav slot="header"></nav>
    <PropertyPhoto {property} />
    <Nav>
      <ul class="methods">
        {#if property.welcome?.enabled}
          <li>
            <a href="/{property.type}/{property.id}/units">Unit Directory</a>
          </li>
          <li>
            <a href="/{property.type}/{property.id}/directory">Community Map</a>
          </li>
        {/if}
        {#if property.parking?.enabled}
          <li>
            <a class="parking" href={property.parking.url}>Parking</a>
            <!-- <a class="parking" href="/property/{property.id}/parking"
                >Parking</a
              > -->
          </li>
        {/if}
        {#if property.amenities?.enabled}
          <li>
            <a
              class="amenities"
              href="https://amenitypass.app/properties/{property.id}"
              >Amenities</a
            >
            <!-- <a class="amenities" href="/property/{property.id}/amenities"
                >Amenities</a
              > -->
          </li>
        {/if}
      </ul>
    </Nav>
  </PropertySection>
{:else}
  <Loading />
{/if}
