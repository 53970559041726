<script lang="ts">
  import Field from "$components/form/Field.svelte";
  import ValueField from "$components/form/ValueField.svelte";
  import TenantField from "$components/tenant/TenantField.svelte";
  import { units } from "$components/unit";
  import Loading from "$components/util/Loading.svelte";
  import { createEventDispatcher } from "svelte";
  import { writable } from "svelte/store";
  export let property: Property | string | null | undefined;
  export let required: boolean = false;

  const scope = writable<string | null>(null);
  const source = units(scope);

  export let value: Tenant | Unit | string | null = null;

  const evented = createEventDispatcher<{
    change: typeof value;
  }>();

  $: if (typeof property === "string") scope.set(property);
  else scope.set(property?.id ?? null);
</script>

{#if $source}
  <TenantField
    source={$source}
    {required}
    on:change={(e) => evented("change", (value = e.detail))}
  />
{:else}
  <ValueField label="Unit"><Loading /></ValueField>
{/if}
