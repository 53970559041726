import identity from "lodash-es/identity";

const pdfContentType = "application/pdf";

export function thumbnail(fileOrUrl, width?, height?, crop?) {
  if (!fileOrUrl) return "";
  var url = new URL(fileOrUrl.url || fileOrUrl);

  var pdf =
    fileOrUrl.format == "application/pdf" || url.pathname.includes(".pdf");
  var pdf = fileOrUrl.format == pdfContentType || url.pathname.includes(".pdf");
  if (
    pdf ||
    fileOrUrl.format == "image/heic" ||
    url.pathname.includes(".heic") ||
    fileOrUrl.format == "image/tiff" ||
    url.pathname.includes(".tiff")
  ) {
    url.host = "parking-uploads.imgix.net";
    var query = url.searchParams;
    query.append("auto", "compress,format");
    if (width) query.append("w", width);
    if (height) query.append("h", height);
    if (crop) query.append("fit", "crop");
    //url.searchParams = query;
    return url.toString();
  }

  return (
    //"https://property-content.com/cdn-cgi/image/" +
    url.origin + "/cdn-cgi/image/" +
    [
      width && `w=${width}`,
      height && `h=${height}`,
      crop && `fit=cover`,
      "format=auto",
    ]
      .filter(identity)
      .join(",") +
    //"/" +
    url.pathname
  );
}
