<script lang="ts" context="module">
  function search(
    query: Readable<string>,
    index: Index<string>,
    items: Readable<FeatureCollection>
  ): Readable<FeatureCollection> {
    return derived([query, items], ([$query, $items], set) => {
      logger("$query=", $query);
      // logger(
      //   "searched=",
      //   index.search($query, 10, function ($values: string[]) {
      //     logger("callback=", $values);
      //   })
      // );
      //set(
      Promise.resolve(index.search($query, 10))
        // , function ($values: string[]) {
        //   set($values.map(($id) => $items.items[$id]).filter(Boolean));
        // });
        .then(($values) =>
          set({
            type: "FeatureCollection",
            features: $values
              .map(($id) => $items.features.find(($f) => $id === $f.id))
              .filter(Boolean),
          })
        );
      // .then(set);
      //     .map(($id) => $items?.items[$id])
      //     .filter(Boolean)
      // ); //.then(set);
    });
  }
</script>

<script lang="ts">
  //import MiniSearch from "minisearch";
  import type { Readable } from "svelte/store";
  import { writable, derived } from "svelte/store";
  import StringIndex from "flexsearch/dist/module/index";
  //import WorkerIndex from "flexsearch/dist/module/worker/index";
  //import Document from "flexsearch/dist/module/document";
  import type { Index } from "flexsearch";
  import FeatureSummaryDataItem from "./FeatureSummaryDataItem.svelte";
  import type { Feature, FeatureCollection } from "geojson";
  import FeaturesList from "./FeaturesList.svelte";

  const term = writable<string>();
  const values = writable<FeatureCollection>();

  export let source: FeatureCollection;
  export let query: string | nullish;

  // const flexsearch = new Document({
  //   //worker: true,

  //   index: ["display", "addr:unit", "building:ref", "addr:street"],
  // });

  const flexsearch: Index<string> = new StringIndex<string>({
    worker: true,
  });

  const searcher = search(term, flexsearch, values);

  $: term.set(query); // update term store on each change

  // const index = new MiniSearch<Unit>({
  //   fields: ["display", "addr:unit", "building:ref", "addr:street"], // fields to index for full-text search
  // });

  $: if (source) {
    for (const item of Object.values(source.features)) {
      if (!item.id) continue;

      flexsearch.update(
        item.id,
        [
          item.properties.name,
          // item["addr:housenumber"],
          // item["addr:street"],
          // item["addr:unit"],
          item.properties?.["building:ref"],
        ]
          .filter(Boolean)
          .join(" ")
      );
      // logger(
      //   "indexed=",
      //   item.id,
      //   [
      //     item.display,
      //     item["addr:housenumber"],
      //     item["addr:street"],
      //     item["addr:unit"],
      //     item["building:ref"],
      //   ]
      //     .filter(Boolean)
      //     .join(" ")
      // );
    }
    // index.removeAll();
    // index.addAll(Object.values(units.items));
    values.set(source);
  }

  $: results = query && $searcher; //(flexsearch.search(query, { limit: 10 }) as Unit[]); //index.search(query).map((result) => units.items[result.id]);

  $: logger("results=", results);
</script>

{#if results}
  <FeaturesList items={results} />
{/if}
