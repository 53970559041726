<script lang="ts" context="module">
  const nowutc = instant("PT1M");
</script>

<script lang="ts">
  import { TemporalZonedDateTimeInterval, instant } from "$utils/temporal";
  import { createEventDispatcher } from "svelte";
  import {
    PermitValidFromString,
    PermitValidToString,
    errored,
    predefinedIntervals,
  } from ".";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";
  import TemporalDateField from "$components/temporal/TemporalDateField.svelte";

  export let policy: PermitIssuePolicy;
  export let values: Record<string, ParamValues>;
  export let error: any | nullish = null;

  let date = Temporal.Now.plainDateISO();

  let value: TemporalZonedDateTimeInterval | nullish = valued(policy, values);

  const events = createEventDispatcher<{
    change: typeof values;
  }>();

  const name = "valid";

  $: nowzoned = $nowutc.withTimeZone(policy.timezone);
  $: value = valued(policy, values);

  $: valid = !!value;
  $: err = errored(error, name);
  $: bounds = TemporalZonedDateTimeInterval.from(
    policy.validity?.intervals?.bounds,
    policy.timezone
  );

  $: intervals = predefinedIntervals(policy, nowzoned);
  //  Object.entries(policy?.validity.intervals.items ?? {})
  //   .map(([key, value]) =>
  //     TemporalZonedDateTimeInterval.from(key, policy.timezone)
  //   )
  //   .filter(Boolean) as TemporalZonedDateTimeInterval[];

  $: logger(
    "intervals=",
    intervals.map((v) => v.toString())
  );

  // clear value if doesn't match, need to move this up a level?
  $: if (
    value &&
    intervals &&
    !intervals.find((v) => TemporalZonedDateTimeInterval.compare(v, value))
  )
    change(null); // there is no matching interval for the value

  // $: if (!value && intervals.length)
  //   change(
  //     new TemporalPlainDateInterval(
  //       intervals[0].minimum.toPlainDate(),
  //       intervals[0].maximum.toPlainDate()
  //     )
  //   );

  function valued(
    initpolicy: typeof policy,
    initvalues: typeof values
  ): typeof value {
    const valid = PermitValidFromString(initpolicy, initvalues);
    if (valid?.start && valid.end)
      return new TemporalZonedDateTimeInterval(
        valid.start,
        valid.end,
        policy.timezone
      );
    return null;
  }
  function change(
    updated:
      | TemporalZonedDateTimeInterval
      | nullish
      | CustomEvent<TemporalZonedDateTimeInterval | nullish>
  ) {
    logger("PolicyPermitAgendaStep.change", updated, value);
    if (updated instanceof CustomEvent) return change(updated.detail);
    if (!updated) return events("change", {});
    if (updated instanceof TemporalZonedDateTimeInterval)
      return events(
        "change",
        PermitValidToString({
          start: updated.minimum,
          end: updated.maximum,
        })
      );
  }
</script>

<TemporalDateField
  min={bounds?.minimum.toPlainDate()}
  max={bounds?.maximum.toPlainDate()}
  value={date}
  on:change={(e) => (date = e.detail ?? date)}
/>

{#each intervals.filter((interval) => interval.overlaps(date)) as interval}
  <button
    on:click={() => change(interval)}
    class:valid={value &&
      TemporalZonedDateTimeInterval.compare(interval, value) == 0}
  >
    {interval.toString()}
  </button>
{/each}

{#if valid && !err}
  <slot />
{:else}
  <PolicyPermitComplete {policy} />
{/if}
