<script lang="ts">
  import Policy from "$components/policy/Policy.svelte";
  import PolicySummary from "$components/policy/PolicySummary.svelte";

  export let permit;
  $: logger("PermitPolicy", permit);
  $: policy = permit.policy;
</script>

{#if policy}
  <dl>
    <dt>Policy</dt>
    <dd>
      <Policy {policy} />
    </dd>
  </dl>
{/if}
