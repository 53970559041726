<script lang="ts">
  import Image from "$components/util/Image.svelte";
  import { thumbnail } from "$utils/filepreview";
  import Policy from "./Policy.svelte";

  export let policy: Policy;
  export let width: number = null;
</script>

{#if policy.photo}
  <figure class="photo {policy.type}">
    <Image
      class="photo"
      alt="{policy.title} photo"
      src={thumbnail(policy.photo.url, width)}
    />
  </figure>
{/if}
