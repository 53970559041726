<script lang="ts" context="module">
  import { instant, iso, second, zero } from "$utils/temporal";
  import { derived, readable, type Readable } from "svelte/store";

  function duration(
    value: Temporal.Duration | string | null
  ): Temporal.Duration | null {
    if (!value) return null;
    if (typeof value === "string" && value.indexOf("P") === 0) {
      return Temporal.Duration.from(value);
    }
    if (value instanceof Temporal.Duration) return value;
    return null;
  }
  const zerostore = readable(zero);
  const now = instant(second);

  function remaining(
    value: Temporal.Instant | Temporal.ZonedDateTime | string
  ): Readable<Temporal.Duration> {
    if (!value) return zerostore;
    if (typeof value === "string" && value.indexOf("P") === 0) {
      return readable(Temporal.Duration.from(value));
    } else if (typeof value === "string") {
      value = Temporal.Instant.from(value);
    }

    if (value instanceof Temporal.Duration) return readable(value);

    const st = readable(value);

    return derived<[typeof now, typeof st], Temporal.Duration>(
      [now, st],
      ([$now, $exp]) => {
        const diff = $exp.since($now.toInstant());
        if (diff.total("milliseconds") > 0) return diff;
        return zero;
      }
    );
  }
</script>

<script lang="ts">
  import ValueField from "./ValueField.svelte";
  import Duration from "$components/temporal/TemporalDuration.svelte";
  export let label: string | nullish;

  export let datetime: Temporal.Instant | Temporal.ZonedDateTime | string;
  export let total: Temporal.Duration | string | null = null;

  $: countdown = remaining(datetime);
  $: total = duration(total);

  //$: remaining = duration(datetime as string | Temporal.Duration);
</script>

<ValueField {label} class="timer">
  <time class="remaining" datetime={iso(datetime)}
    >{`${Math.floor($countdown.total("hours")).toString().padStart(2, "0")}:${(Math.floor($countdown.total("minutes")) % 60).toString().padStart(2, "0")}:${(Math.floor($countdown.total("seconds")) % 60).toString().padStart(2, "0")}`}</time
  >
  {#if total}
    <Duration datetime={total} class="total" />
  {/if}
  <slot />
</ValueField>
