<script lang="ts">
  import Image from "$components/util/Image.svelte";
  import { thumbnail } from "../../util/filepreview";

  export let document: FileInfo = null;
</script>

{#if document}
  <figure class="file document">
    <a href={document.url} target="_blank"
      ><Image src={thumbnail(document.url, 88, 88)} alt={document.title} /></a
    >
    <figcaption><a href={document.url}>{document.name}</a></figcaption>
  </figure>
{/if}
