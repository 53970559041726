<script lang="ts">
  import MediaRecord from "./MediaRecord.svelte";
  import MediaStatusHeader from "./MediaStatusHeader.svelte";
  import PropertyHeader from "../property/PropertyHeader.svelte";
  import Help from "../util/Help.svelte";
  import Photo from "../util/Photo.svelte";
  import Warning from "../util/Warning.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import Private from "$components/util/Private.svelte";
  import Info from "$components/util/Info.svelte";
  import { datetime } from "$utils/temporal";
  export let media: Media;
  export let permits: Permits;
  export let policies: PermitIssuePolicies;

  const property = media.property;
  const active = Object.values(permits.items).filter((p) => !p.valid.expired);
</script>

<main class="vehicle media record status">
  <MediaStatusHeader {media} {permits} />
  {#if property}
    <PropertyHeader {property} />
  {/if}
  <MediaRecord {media} />
  <section class="permits">
    <ul>
      {#each Object.values(active) as permit}
        <li>
          <article class="permit">
            <dl>
              {#if permit.vehicle}
                <dt>Vehicle</dt>
                <dd><RecordItem item={permit.vehicle} /></dd>
              {/if}
              {#if permit.space}
                <dt>Park In</dt>
                <dd><RecordItem item={permit.space} /></dd>
              {/if}
            </dl>
          </article>
        </li>
      {/each}
    </ul>
    <footer>
      <p>
        <time datetime={permits.generated}
          ><dfn>Synced </dfn><abbr
            title={datetime(Temporal.Instant.from(permits.generated))}
          />{datetime(Temporal.Instant.from(permits.generated))}</time
        >
      </p>
    </footer>
  </section>

  <nav class="policies">
    {#if policies.count}
      <ul>
        {#each Object.values(policies.items) as policy}
          <li>
            <a
              href={new URL(
                `/${policy.scope}/permits/new?policy=${policy.policy}&media=${media.key}`,
                policy.url
              ).toString()}>{policy.title}</a
            >
          </li>
        {/each}
      </ul>
    {:else}{/if}
  </nav>

  <footer>
    <Help
      title="Need help?"
      message="Please contact your property or parking management with questions, concerns, or changes"
    />
    <Private
      title="Privacy"
      message="Once activated, only public info for permit and vehicle verification is
    displayed, no personal, private, or location information is available"
    />
    <Info
      class="unpermitted"
      title="Use Requirements"
      message="{media.title} must be active and valid for the parked vehicle and location.
      Use of an inactive, revoked, or invalid {media.title} may result in citation,
      booting, immobilization, or towing at owner's expense."
    />
  </footer>
</main>
