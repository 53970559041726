<script lang="ts" context="module">
</script>

<script lang="ts">
  import {
    TemporalZonedDateTimeInterval,
    availableEndDates,
    availableEndTimes,
    availableStartDates,
    availableStartTimes,
    date as formatDate,
    time as formatTime,
    indefinite,
    iso,
  } from "$utils/temporal";
  import Select from "$components/form/Select.svelte";
  import Option from "$components/form/Option.svelte";
  import Field from "$components/form/Field.svelte";
  import { createEventDispatcher, onMount, tick } from "svelte";
  import ValueField from "$components/form/ValueField.svelte";

  export let items: TemporalZonedDateTimeInterval[];
  export let value: TemporalZonedDateTimeInterval | nullish = null;
  //export let name: string = "valid";

  const events = createEventDispatcher<{
    change: typeof value;
  }>();

  onMount(function () {
    //await tick();
    events("change", value);
  });

  async function change(updated: TemporalZonedDateTimeInterval) {
    logger("TemporalIntervalField.change=", updated, value);
    //value = updated;
    //await tick();
    events("change", (value = updated));
  }

  let startdate: Temporal.PlainDate;
  let starttime: Temporal.PlainTime;
  let enddate: Temporal.PlainDate;
  let endtime: Temporal.PlainTime;

  $: startdates = availableStartDates(items);
  // $: logger(
  //   "startdates=",
  //   startdates.map((i) => i.toString())
  // );
  $: if (
    startdates?.length &&
    (null == startdate ||
      !startdates.find((i) => Temporal.PlainDate.compare(i, startdate) === 0))
  )
    startdate = startdates[0];
  $: starttimes = availableStartTimes(items, startdate);
  $: if (
    starttimes.length &&
    (null == starttime ||
      !starttimes.find((i) => Temporal.PlainTime.compare(i, starttime) === 0))
  )
    starttime = starttimes[0];

  $: enddates = availableEndDates(items, startdate, starttime);
  $: if (
    enddates?.length &&
    (null == enddate ||
      !enddates.find((i) => Temporal.PlainDate.compare(i, enddate) === 0))
  )
    enddate = enddates[0];

  $: endtimes = availableEndTimes(items, startdate, starttime, enddate);
  $: if (
    endtimes?.length &&
    (null == endtime ||
      !endtimes.find((i) => Temporal.PlainTime.compare(i, endtime) === 0))
  )
    endtime = endtimes[0];

  $: endInstant = Object.values(
    items?.reduce(
      (result: Record<string, Temporal.ZonedDateTime> | null, item) => {
        if (null == result) return result;
        if (!item.maximum) return result;
        result[iso(item.maximum) ?? ""] = item.maximum;

        return Object.values(result).length > 1 ? null : result;
      },
      {} as Record<string, Temporal.ZonedDateTime>
    ) || {}
  )[0];

  //   $: singleStart =
  //     (!!intervals?.length &&
  //       intervals
  //         .map(([a]) => a)
  //         .reduce(function (
  //           a: Temporal.ZonedDateTime | null,
  //           b: Temporal.ZonedDateTime,
  //           i: number
  //         ) {
  //           if (0 == i) return b;
  //           if (null == a) return null;
  //           return Temporal.ZonedDateTime.compare(
  //             a as Temporal.ZonedDateTime,
  //             b
  //           ) !== 0
  //             ? null
  //             : a;
  //         }, null)) ||
  //     null;

  // (!!intervals?.length && intervals.map(([a, b]) => a).dinstinct() ||
  //   intervals.reduce((value, ([a, b])) => {
  //     if(null == value)
  //   }, null) &&
  //   intervals[0][0])
  // || null;

  $: filteredintervals = items?.filter(
    (item) =>
      (!startdate ||
        Temporal.PlainDate.compare(item.minimum.toPlainDate(), startdate) ==
          0) &&
      (!starttime ||
        Temporal.PlainTime.compare(item.minimum.toPlainTime(), starttime) ==
          0) &&
      (!enddate ||
        Temporal.PlainDate.compare(item.maximum.toPlainDate(), enddate) == 0) &&
      (!endtime ||
        Temporal.PlainTime.compare(item.maximum.toPlainTime(), endtime) == 0)
  );

  // $: logger(
  //   "intervals=",
  //   items.map(
  //     ([a, b]) =>
  //       `${a.toString({
  //         timeZoneName: "never",
  //         calendarName: "never",
  //       })}/${b.toString({
  //         timeZoneName: "never",
  //         calendarName: "never",
  //       })}`
  //   )
  // );

  $: if (
    (!value && filteredintervals?.length) ||
    (value &&
      filteredintervals?.length &&
      !filteredintervals.find(
        (item) =>
          value && TemporalZonedDateTimeInterval.compare(item, value) === 0
      ))
    //   ({ start, end }) =>
    //     value.start &&
    //     Temporal.ZonedDateTime.compare(start, value.start) === 0 &&
    //     value.end &&
    //     Temporal.ZonedDateTime.compare(end, value.end) === 0
    // ))
  )
    change(filteredintervals[0]);
  //change(name, filteredintervals[0].map(iso).join("/"));

  // $: if (startdate && starttime && enddate && endtime) {
  //   valid = `${Temporal.PlainDate.from(startdate).toZonedDateTime({
  //     plainTime: starttime,
  //     timeZone: policy.timezone,
  //   })}/${Temporal.PlainDate.from(enddate).toZonedDateTime({
  //     plainTime: endtime,
  //     timeZone: policy.timezone,
  //   })}`;
  // }
  $: logger(
    `valid=`,
    value
    // startdate?.toString(),
    // starttime?.toString(),
    // enddate?.toString(),
    // endtime?.toString()
  );
  $: logger("startdate=", startdate?.toString());
  $: logger("starttime=", starttime?.toString());
  $: logger("enddate=", enddate?.toString());
  $: logger("endtime=", endtime?.toString());
</script>

<Field label="Start" id="valid-min-datetime">
  <time datetime={startdate?.toString() || ""}>
    {#if startdate}{formatDate(startdate, false)}{/if}
    {#if startdates?.length > 0}
      <!-- {startdate?.toString()} -->
      <Select
        on:change={(e) => (startdate = Temporal.PlainDate.from(e.detail.value))}
      >
        {#each startdates as date}
          <Option
            value={date.toString()}
            selected={startdate &&
              Temporal.PlainDate.compare(date, startdate) === 0}
            >{formatDate(date, false)}</Option
          >
        {/each}
      </Select>
    {/if}
    <!-- {rightNowOnly ? "right now" : "start"} -->
  </time>
  <time datetime={starttime?.toString() || ""}>
    {#if starttime}
      {formatTime(starttime)}
    {/if}
    {#if starttimes?.length > 0}
      <!-- {starttime?.toString()} -->
      <Select
        on:change={(e) => (starttime = Temporal.PlainTime.from(e.detail.value))}
      >
        {#each starttimes as time}
          <Option
            selected={starttime &&
              Temporal.PlainTime.compare(time, starttime) === 0}
            value={time.toString()}>{formatTime(time)}</Option
          >
        {/each}
      </Select>
      <!-- {:else}
          <time datetime="">{formatTime(starttime)}</time> -->
    {/if}
    <!-- {rightNowOnly ? "right now" : "start"} -->
  </time>
</Field>
<!-- </UiFormFieldItem> -->
{#if !!endInstant}
  <!-- <UiFormFieldItem> -->
  <ValueField label="End">
    <time datetime={iso(endInstant)}
      >{!endInstant || indefinite(endInstant)
        ? "when revoked"
        : `${formatDate(endInstant, false)} ${formatTime(endInstant)}`}</time
    >
  </ValueField>
{:else}
  <Field label="End" id="valid-max-datetime">
    <time datetime={enddate?.toString() || ""}>
      {#if enddate}{formatDate(enddate, false)}{/if}
      {#if enddates?.length > 0}
        <!-- {enddate?.toString()} -->
        <Select
          on:change={(e) => (enddate = Temporal.PlainDate.from(e.detail.value))}
        >
          {#each enddates as date}
            <Option
              selected={enddate &&
                Temporal.PlainDate.compare(date, enddate) === 0}
              value={date.toString()}>{formatDate(date, false)}</Option
            >
          {/each}
        </Select>
      {/if}
    </time>
    <time datetime={endtime?.toString() || ""}>
      {#if endtime}{formatTime(endtime)}{/if}
      {#if endtimes?.length > 0}
        <!-- {endtime?.toString()} -->
        <Select
          on:change={(e) => (endtime = Temporal.PlainTime.from(e.detail.value))}
        >
          {#each endtimes as time}
            <Option
              selected={endtime &&
                Temporal.PlainTime.compare(time, endtime) === 0}
              value={time.toString()}>{formatTime(time)}</Option
            >
          {/each}
        </Select>
        <!-- {:else if endtime}
          <time datetime={endtime.toString()}>{formatTime(endtime)}</time> -->
      {/if}
      <!-- {rightNowOnly ? "right now" : "start"} -->
    </time>
  </Field>
{/if}
