<script lang="ts">
  import { createEventDispatcher } from "svelte";

  const eventing = createEventDispatcher<{
    change: string;
  }>();

  export let levels: { [key: string]: Leveled } = {};
  export let level: string | nullish;
  //$: logger("level=", level);

  $: levelslist = Object.values(levels ?? {})
    .map((v: any) => ({
      ...v,
      value: v.level,
      ref: v["level:ref"],
    }))
    //.filter((i) => "" !== i.level)
    .sort(
      (a: any, b: any) =>
        nandor(parseFloat(a.level), -1000) - nandor(parseFloat(b.level), -1000)
    );
  function nandor(value, fallback) {
    return isNaN(value) ? fallback : value;
  }
  function label(ref: string) {
    if (ref === "Outdoors") return "Outside";
    return ref;
  }
  //$: if (null != level) eventing("change", level);

  function change(newvalue: string) {
    eventing("change", (level = newvalue));
  }
</script>

<select
  name="level"
  value={level}
  on:change={({ currentTarget: target }) => change(target.value)}
>
  {#each levelslist as level}
    <option value={level.value}>{label(level.ref)}</option>
  {:else}
    <option value="outside">Outside</option>
  {/each}
</select>
