<script lang="ts">
  export let property: Property = null;
</script>

{#if property && property.id}
  <data class={property.type} value={property.id}>
    <dfn>{property.name}</dfn>
    <address>
      <span class="street">{property.address.street}</span>
      <span class="city">{property.address.city}</span>
      <span class="state">{property.address.state}</span>
      <span class="postal">{property.address.postal}</span>
    </address>
  </data>
{/if}
