<script lang="ts">
  export let fee: any;
</script>

{#if fee}
  <data class={fee.type} value={fee.id}>
    <data class="amount" value={fee.total.value}>{fee.total.display}</data>
    <dfn>{fee.description}</dfn>
  </data>
{/if}
