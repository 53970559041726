import debug from "debug";

// we must force tsc to interpret this file as a module, resolves
// "Augmentations for the global scope can only be directly nested in external modules or ambient module declarations."
// error
export {}

declare global {
  const logger: debug.Debugger;
}

const _global = (window /* browser */ || global /* node */) as any
_global.logger = debug("boss");

if(location?.hostname === "localhost" || location?.hostname.endsWith(".dev") || location?.hostname.endsWith(".local")) {
  debug.enable("boss");
}