<script lang="ts">
  export let policy: PermitIssuePolicy;
  export let title: string | nullish = null;
  export let expand: string | nullish = "more";
  export let expanded: boolean = true;
  export let toggle: boolean = true;
</script>

<aside class="policy info">
  <header>
    {#if title}
      <h1>{title}</h1>
    {/if}
    {#if toggle}
      <button
        type="button"
        class="disclose"
        class:active={expanded}
        on:click={() => (expanded = !expanded)}
        >{expanded ? "Hide" : "Show"} rules & info&hellip;</button
      >
    {/if}
  </header>
  {#if !toggle || expanded}
    <slot></slot>
  {/if}
</aside>
