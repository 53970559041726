<script lang="ts">
  import Permit from "$components/permit/Permit.svelte";
  import UiNav from "$components/ui/Nav.svelte";
  import UiPrimaryView from "$components/ui/PrimaryView.svelte";
  import UiSection from "$components/ui/Section.svelte";
  import Loading from "$components/util/Loading.svelte";
  import type { Readable } from "svelte/store";

  export let permits: Readable<Permits | null>;

  $: logger("permits=", $permits);
</script>

<UiPrimaryView class="permits">
  <UiNav></UiNav>
  <UiSection class="permits">
    {#if !$permits}
      <Loading />
    {:else}
      <ul class="permits">
        {#each Object.values($permits.items) as permit}
          <li>
            <Permit {permit} />
          </li>
        {/each}
      </ul>
    {/if}
  </UiSection>
</UiPrimaryView>
