export function parsejwt(token: string): any | null {
    if(!token) return null;
    if(!token.split) return null;
    var base64Url = token.split(".")[1];
    if(!base64Url) return null;
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }

  export function claim(token: string, claim: string): any | null {
    if(!token) return null;
    if(!claim) return null;
    return parsejwt(token)?.[claim] ?? null;
  }

  export function sub(token: string): string | null {
    return claim(token, "sub") as string;
  }

  export function exp(token: string): Temporal.Instant | null {
    var value = claim(token, "exp");
    if(!value) return null;
    return Temporal.Instant.fromEpochSeconds(value as number);
  }
