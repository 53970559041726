<script lang="ts">
  import PolicyAdditional from "./PolicyAdditional.svelte";
  import PolicyInfo from "./PolicyInfo.svelte";

  export let policy: PermitIssuePolicy | null = null;
</script>

{#if true === policy?.issue?.enabled}
  <PolicyInfo {policy}>
    <PolicyAdditional {policy} />
    <slot />
  </PolicyInfo>
{:else if policy}
  <PolicyInfo {policy} />
{/if}
