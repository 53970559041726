<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let name: string | nullish = null;
  export let id: string | nullish = null;
  export let value: string = "";
  export let required = false;
  export let title: string | nullish = null;

  const events = createEventDispatcher<{
    change: {
      name?: string;
      value: string;
    };
  }>();

  function change(name: string | undefined, newvalue: string) {
    if (newvalue === value) return;
    logger("select.change", name, newvalue, value);
    value = newvalue;

    //if (name)
    events("change", {
      name: name || undefined,
      value,
    });
  }
</script>

<select
  id={id || null}
  name={name || null}
  title={title || null}
  {required}
  {value}
  on:change={({ currentTarget: target }) => change(target.name, target.value)}
>
  <slot />
</select>
