<script lang="ts">
  import Fee from "$components/payment/Fee.svelte";
  import Payment from "$components/payment/Payment.svelte";

  export let permit: Permit;

  import PaymentCard from "$components/payment/PaymentCard.svelte";

  $: logger("fees=", permit?.fees);

  $: fees = Object.values(permit?.fees?.items ?? {}) as any[];
  $: cancelled = !!permit?.cancelled;
</script>

{#if fees?.length}
  <aside class={permit.fees.type}>
    <dl>
      {#each fees as fee}
        <dt>{fee.name}</dt>
        <dd>
          <Fee {fee} />
        </dd>
        {#each Object.values(fee.payments?.items || fee.payments || []) as payment}
          <dd>
            <Payment {payment} {cancelled} />
          </dd>
        {:else}
          <dd>Unpaid</dd>
        {/each}
      {/each}
    </dl>
  </aside>
{/if}
