<script lang="ts">
  import {
    openingHoursToHuman,
    openingHoursToDetails,
  } from "@cullylarson/openinghours";
  import parsePhoneNumber from "libphonenumber-js";
  //import { unitPrefixed } from "../util/unit";
  import Image from "$components/util/Image.svelte";
  import PolicyInfoDetails from "$components/policy/PolicyInfoDetails.svelte";
  import Info from "$components/util/Info.svelte";

  export let feature;

  // $: logger("feature=", feature);

  // $: logger(
  //   "opening_hours",
  //   openingHoursToDetails("Mo-Fr 09:00-17:00; Sa 10:00-17:00; Su,PH off", {
  //     dayNames: {
  //       Mo: "Mon",
  //       Tu: "Tue",
  //       We: "Wed",
  //       Th: "Thu",
  //       Fr: "Fri",
  //       Sa: "Sat",
  //       Su: "Sun",
  //     },
  //     am: " AM",
  //     pm: " PM",
  //   })
  // );

  $: phone =
    feature &&
    feature.properties &&
    feature.properties.phone &&
    parsePhoneNumber(feature.properties.phone + "", "US");
  $: emergencyPhone =
    feature &&
    feature.properties &&
    feature.properties["emergency:phone"] &&
    parsePhoneNumber(feature.properties["emergency:phone"] + "", "US");
</script>

{#if feature && feature.properties}
  {#if phone || emergencyPhone}
    <nav>
      {#if emergencyPhone}
        <a href={emergencyPhone.getURI()} rel="emergency"
          >{emergencyPhone.formatNational()}</a
        >
      {/if}
      {#if phone}
        <a href={phone.getURI()}>{phone.formatNational()}</a>
      {/if}
    </nav>
  {/if}
  {#if feature.properties.image}
    <figure class="photo">
      <Image alt="Image" src={feature.properties.image} />
    </figure>
  {/if}
  <!-- {#if feature.properties["amenity"] && feature.properties["access"] != "no" && feature.properties["post:box"]}<p><data class="post-box" value="{feature.properties["post:box"]}">Mailbox {feature.properties["post:box"]}</data></p>{/if} -->
  <dl>
    {#if feature.properties.opening_hours}
      <dt>Hours</dt>
      {#each openingHoursToHuman( feature.properties.opening_hours, { dayNames: { Mo: "Mon", Tu: "Tue", We: "Wed", Th: "Thu", Fr: "Fri", Sa: "Sat", Su: "Sun" }, am: " AM", pm: " PM" } ) as item}
        <dd>
          <time datetime={item.openingHours}
            >{item.human?.replace("NaN PM", "closed") ||
              item.openingHours}</time
          >
        </dd>
      {/each}

      <!-- <ul class="opening_hours">
      
    </ul> -->
    {/if}
  </dl>
  <!-- {#if feature.properties["post:box"] && feature.properties["ref:boss:subject"]}
    <p>Mailing address:</p>
    <address>
        <data class="street">{[feature.properties["addr:housenumber"], feature.properties["addr:street"],  unitPrefixed(feature.properties["addr:unit"], feature.properties.format) ].filter(i => !!i).join(" ")}</data>
        <data class="city">{feature.properties["addr:city"]}</data>
        <data class="state">{feature.properties["addr:state"]}</data>
        <data class="postcode">{feature.properties["addr:postcode"]}</data>
    </address>
    {/if} -->
  {#if feature.properties.email || feature.properties.website}
    <dl>
      <dt>Info</dt>
      {#if feature.properties.email}
        <dd><a href="mailto:{feature.properties.email}">Email</a></dd>
      {/if}
      {#if feature.properties.website}
        <dd>
          <a href={feature.properties.website} target="_blank">Website</a>
        </dd>
      {/if}
    </dl>
  {/if}

  <Info message={feature.properties.description} />

  <!-- {#if feature.properties.description}
    <p class="description">{}</p>
  {/if} -->

  {#if feature.properties.policy}
    <aside class="policy">
      <PolicyInfoDetails policy={feature.properties.policy} />
    </aside>
  {/if}
{/if}
