import {
  type Stripe,
  loadStripe,
  type CanMakePaymentResult,
  type PaymentRequest,
} from "@stripe/stripe-js";
import { derived, readable, type Readable, type Writable } from "svelte/store";

const key = readable("pk_live_ERdgZ1FtE6lFvBhFnem3IT7Q");

// I believe we determined that we could use our global stripe regardless of connected account
export const stripe = load(key);

// readable<Stripe | null>(null, (set) => {
//   loadStripe("pk_live_ERdgZ1FtE6lFvBhFnem3IT7Q").then((stripe) => set(stripe));
// });

export const wallet = derived<
  typeof stripe,
  CanMakePaymentResult | false | null
>(stripe, ($stripe, set) => {
  if (!$stripe) return set(null);

  $stripe
    ?.paymentRequest({
      country: "US",
      currency: "usd",
      total: {
        label: "Test",
        amount: 0,
      },
    })
    .canMakePayment()
    .then((wallet) => set(wallet ?? false));
});

export function load(key: Readable<string | null>): Readable<Stripe | null> {
  return derived<typeof key, Stripe | null>(key, ($key, set) => {
    if (!$key) return set(null);
    set(null);
    loadStripe($key).then((stripe) => set(stripe));
  });
}

export function requestwallet(payment: Readable<PaymentRequest | null>) {
  return derived<typeof payment, CanMakePaymentResult | false | null>(
    payment,
    ($payment, set) => {
      if (!$payment) return set(null);

      $payment.canMakePayment().then((wallet) => set(wallet ?? false));
    }
  );
}

export function request(
  payment: Readable<Payment | null>,
  stripeapi: Readable<Stripe | null> = stripe
): Readable<PaymentRequest | null> {
  return derived<[typeof payment, typeof stripeapi], PaymentRequest | null>(
    [payment, stripeapi],
    ([payment, $stripe], set) => {
      if (!payment || !$stripe) return set(null);
      return set(
        $stripe.paymentRequest({
          country: "US",
          currency: "usd",
          total: {
            label: payment.payee || "Total",
            amount: Object.values(payment.fees.items || payment.fees).reduce(
              (sum, fee) => {
                if (fee.contingent) return sum;
                return sum + fee.total.value;
              },
              0
            ),
          },
          displayItems: Object.values(payment.fees.items || payment.fees).map(
            (fee) => ({
              label: fee.name,
              amount: fee.total.value,
            })
          ),
        })
      );
    }
  );
}
