<script lang="ts">
  import { param } from "$utils/params";

  import PolicyPreauth from "$components/policy/PolicyPermitPreauth.svelte";
  import UiHeader from "$components/ui/Header.svelte";
  import UiTitle from "$components/ui/Title.svelte";
  import Loading from "$components/util/Loading.svelte";
  import UiSection from "$components/ui/Section.svelte";
  import PropertySection from "$components/property/PropertySection.svelte";

  export let policy: PermitIssuePolicy;
  export let referrer: string | nullish = null;
  export let base: string;

  $: property = policy.property;
</script>

<PropertySection {property} title="Preauthorize">
  <UiSection class="policy preauth">
    {#if policy}
      <UiHeader>
        <!-- <UiTitle>Preauthorize {policy.title}</UiTitle> -->
        <p>
          Allow registration with a private link instead of sharing your
          passcode.
        </p>
      </UiHeader>
      <PolicyPreauth {policy} />
      <slot />
    {:else}
      <Loading />
    {/if}
  </UiSection>
</PropertySection>
