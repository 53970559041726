<script lang="ts">
  import Photo from "../util/Photo.svelte";

  export let property: Property;
</script>

<Photo {property}>
  <h1>
    <data class="property" value={property.id}>{property.name}</data>
    <address>
      <data class="city">{property.address.city}</data>
      <data class="state">{property.address.state}</data>
    </address>
  </h1>
</Photo>
