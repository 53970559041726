<script lang="ts">
  import { onMount } from "svelte";
  import {
    getSvelteContext,
    type MapGeoJSONFeature,
    type MapLayerMouseEvent,
    type MapMouseEvent,
  } from "./mapping";

  import type { VectorGLMap } from "./mapping";

  const { styledmap } = getSvelteContext();
  let MAP: VectorGLMap;

  export let filter:
    | any[]
    | ((feature: MapGeoJSONFeature | GeoJSON.Feature) => Boolean) = undefined;
  let layer: string | undefined | null = undefined;

  export let hovered: MapGeoJSONFeature = null;

  function hover($map: VectorGLMap, $feature?: MapGeoJSONFeature) {
    if (!$map) return;
    const isFeatureHoverable = !!$feature?.id && !!$feature?.source; //$feature?.state?.selectable;
    const canvas = $map.getCanvas();
    if (
      canvas?.style &&
      (!canvas?.style.cursor || "pointer" === canvas?.style.cursor)
    )
      canvas.style.cursor = isFeatureHoverable ? "pointer" : "";
    if (hovered == $feature || hovered?.id === $feature?.id) return; // same, no more
    //logger("checking hovered=", hovered);
    if (hovered?.id && !$feature) {
      try {
        $map?.setFeatureState(hovered, {
          hover: false,
        });
      } catch (e) {}
      hovered = null;
    } else if (hovered?.id) {
      $map?.setFeatureState(hovered, {
        hover: false,
      });
      hovered = null;
    }

    if (isFeatureHoverable) {
      $map?.setFeatureState($feature, {
        hover: true,
      });
      hovered = $feature;
    }

    //hovered = null;
  }

  function onmouseover(e: MapMouseEvent) {
    //logger("space map hover popup onmouseover=", e);
    const value = e.target
      .queryRenderedFeatures(
        e.point,
        filter instanceof Function
          ? undefined
          : {
              filter,
            }
      )
      .filter(filter instanceof Function ? filter : () => true)[0];
    hover(e.target, value);
  }

  function onmouseenter(e: MapLayerMouseEvent) {
    const value =
      filter instanceof Function
        ? e.features?.filter(filter)[0]
        : e.features?.[0];

    hover(e.target, value as MapGeoJSONFeature);

    // e.target.getCanvas().style.cursor = "";
    // if (!e.features) {
    //   if (hovered) {
    //     e.target.setFeatureState(hovered, {
    //       hover: false,
    //     });
    //   }
    //   return;
    // }
    // //logger("mouseenter=", e);

    // for (const feature of e.features) {
    //   if (!feature.state?.selectable) continue;
    //   if (hovered && hovered.id == feature.id) return;

    //   if (hovered) {
    //     e.target.setFeatureState(hovered, {
    //       hover: false,
    //     });
    //   }
    //   e.target.setFeatureState((hovered = feature), {
    //     hover: true,
    //   });
    //   e.target.getCanvas().style.cursor = "pointer";
    //   //logger("hovered=", feature);
    //   break;
    // }
  }
  function onmouseleave(e) {
    //if (!e.features) return;
    //logger("onmouseleave=", e);
    // e.target.getCanvas().style.cursor = "";
    // if (hovered)
    //   e.target.setFeatureState(hovered, {
    //     hover: false,
    //   });
    // hovered = null;
    hover(e.target);
  }

  function init($map: VectorGLMap, $layer?: string) {
    cleanup($layer); // cleanup previous map
    MAP = $map; // set

    // events
    if ($layer) {
      MAP?.on("mouseenter", $layer, onmouseenter);
      MAP?.on("mouseleave", $layer, onmouseleave);
    } else {
      MAP?.on("mousemove", onmouseover);
    }
  }
  function cleanup($layer?: string) {
    if (!MAP) return; // no map to cleanup

    // events
    if ($layer) {
      MAP?.off("mousemove", $layer, onmouseover);
      MAP?.off("mouseenter", $layer, onmouseenter);
      MAP?.off("mouseleave", $layer, onmouseleave);
    }
    MAP?.off("mousemove", onmouseover);
    MAP?.off("mouseenter", onmouseenter);
    MAP?.off("mouseleave", onmouseleave);

    hover(MAP);
    MAP = null; //set
  }

  $: init($styledmap, layer);

  onMount(function () {
    //const unsubscribe = map.subscribe(init);
    return function destroy() {
      cleanup(layer);
      //unsubscribe();
    };
  });

  //   onMount(function () {
  //     map.on("mouseenter", layer, onmouseenter);
  //     map.on("mouseleave", layer, onmouseleave);
  //     return function destroy() {
  //       if (!map) return;
  //       map.off("mouseenter", layer, onmouseenter);
  //       map.off("mouseleave", layer, onmouseleave);
  //     };
  //   });

  //   map.on("mouseenter", function (e) {
  //     if (!e.features) return;
  //     logger("mouseenter=", e.features);
  //     // for(const feature of e.features) {
  //     //     feature.
  //     // }
  //     // var item =
  //     //   !!e.features && e.features.length > 0
  //     //     ? e.features[0]
  //     //     : feature(e.target, e.point);
  //     // if (!item || !item.id) return;
  //     // mapUI.setFeatureState(
  //     //   { source: "property", id: (id = item.id) },
  //     //   { hover: true }
  //     // );
  //   });
</script>

<slot />
