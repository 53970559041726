<script lang="ts">
  import { locate, position } from "$utils/uistores";
  import type { Polygon } from "geojson";
  import { states } from "./stores";
  export let boundary: Polygon = null;

  $: value = $position;
</script>

<data
  class="position {value.status}"
  value={(value?.coords &&
    `${value.coords.longitude},${value.coords.latitude}`) ||
    value.status}><dfn>{states[value.status]}</dfn></data
>
