<script lang="ts">
  import RecordExtras from "./RecordExtras.svelte";
  import RecordSpaceCovered from "./RecordSpaceCovered.svelte";

  export let addressed: boolean | null | undefined = null;

  export let url = false;
  export let item;

  $: building = item?.["building:ref"];
  $: floor = item?.level && item?.["level:ref"];

  $: addrdisplay =
    !item?.["addr:unit"] &&
    [item?.["addr:housenumber"], item?.["addr:street"]]
      .filter(Boolean)
      .join(" ");

  $: includeAddressExtra =
    addressed ??
    ((!addrdisplay && item?.["addr:housenumber"] && item?.["addr:street"]) ||
      Boolean(building) ||
      Boolean(floor) ||
      !!item.covered ||
      !!item.indoor ||
      !!item.outdoor);
</script>

{#if item}
  <!-- <dfn><abbr title="Cascading Style Sheets">CSS</abbr></dfn> -->
  <dfn
    ><abbr
      title={[
        item["addr:housenumber"],
        item["addr:street"],
        item["addr:unit"] && `#${item["addr:unit"]}`,
      ]
        .filter(Boolean)
        .join(" ") || item.display}
      >{item["addr:unit"] || addrdisplay || item.display}
      <slot><RecordExtras {item} /></slot></abbr
    ></dfn
  >
  {#if includeAddressExtra}
    <address>
      {#if !addrdisplay && item["addr:housenumber"] && item["addr:street"]}
        <data
          class="street"
          value="{item['addr:housenumber']} {item['addr:street']}"
          >{item["addr:housenumber"]} {item["addr:street"]}</data
        >
        <!-- {:else if item["addr:street"]}
          <data class="street" value={item["addr:street"]}
            >{item["addr:street"]}</data
          > -->
      {/if}{#if building}
        <data class="building" value={building}>{building}</data>
      {/if}{#if floor}
        <data class="level" value={floor}>{floor}</data>
      {/if}
      <RecordSpaceCovered {item} label />
    </address>{/if}
{/if}
