<script lang="ts">
  import Info from "./Info.svelte";
  let classname = "";

  export let message: string = "";
  export let title = "";
  export { classname as class };
</script>

<Info class="warning {classname}" {title} {message} />
