export function formdata(entries:FormData | Record<string, FormDataEntryValue | FormDataEntryValue[]>) {
    if(entries instanceof FormData) return entries;
  const form = new FormData();
  for (const [key, value] of Object.entries(entries ?? {})) {
    if (null == value) continue;
    if(Array.isArray(value)) {
        for(const v of value) {
            if(null == v) continue;
            form.append(key, v);
        }
    } else {
        form.append(key, value);
    }
    
  }
  return form;
}