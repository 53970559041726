<script lang="ts">
  import type { Properties } from "types";
  import PropertySummaryItem from "./PropertySummaryItem.svelte";

  export let properties: Properties;
</script>

<ul class="properties" data-count={properties?.count ?? ""}>
  {#each Object.values(properties?.items ?? {}) as item}
    {#if !!item && item.id}
      <li>
        <a href="/{item.type}/{item.id}">
          <PropertySummaryItem {item} />
        </a>
      </li>
    {/if}
  {/each}
</ul>
