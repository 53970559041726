<script lang="ts" context="module">
  const monthname: Record<number, string> = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
</script>

<script lang="ts">
  import {
    dateparts,
    daysparts,
    iso,
    months,
    plaindate,
    plainyearmonth,
    TemporalPlainDateInterval,
  } from "$utils/temporal";
  import { createEventDispatcher } from "svelte";

  export let value: TemporalPlainDateInterval | nullish;
  export let values: TemporalPlainDateInterval[] = []; // these are the values can possibly choose from

  // calculate this from values?
  export let min: Temporal.PlainDate | Temporal.PlainDateLike | string =
    Temporal.Now.plainDateISO();
  export let max: Temporal.PlainDate | Temporal.PlainDateLike | string =
    Temporal.Now.plainDateISO();

  let minimized: boolean = false;

  let maxvalue: TemporalPlainDateInterval | nullish;

  // normalize min/max into plaindates
  let minbound: Temporal.PlainDate = plaindate(min);
  let maxbound: Temporal.PlainDate = plaindate(max);

  // let start: Temporal.PlainDate | nullish;
  // let end: Temporal.PlainDate | nullish;

  $: minbound = plaindate(min);
  $: maxbound = plaindate(max);

  $: if (value)
    maxvalue = values
      .filter((v) => value && v.contains(value))
      .sort(TemporalPlainDateInterval.compare)
      .pop();

  $: logger("value=", value, "maxvalue=", maxvalue);

  const events = createEventDispatcher<{
    change: typeof value;
  }>();

  function change(updated: Temporal.PlainDate | nullish) {
    if (!updated) return events("change", null);
    if (!value || !available(updated, maxvalue)) {
      // value is the first one that starts with date
      events(
        "change",
        values
          .filter((v) => v.starts(updated))
          .sort(TemporalPlainDateInterval.compare)
          .shift()
      );
    } else if (value && available(updated, maxvalue)) {
      events("change", new TemporalPlainDateInterval(value.minimum, updated));
    }
    // var newinterval =
    // events("change", updated);
  }

  function active(
    plaindate: Temporal.PlainDate,
    selected: typeof value
  ): boolean {
    return !!selected?.contains(plaindate);
  }

  function available(
    plaindate: Temporal.PlainDate,
    selectable: typeof maxvalue
  ): boolean {
    return !!selectable?.contains(plaindate);
  }
  function start(plaindate: Temporal.PlainDate, items: typeof values): boolean {
    return items.some((v) => v.starts(plaindate));
  }

  function enabled(
    plaindate: Temporal.PlainDate,
    items: typeof values,
    selectable: typeof maxvalue
  ): boolean {
    return available(plaindate, selectable) || start(plaindate, items);
  }
</script>

<!-- 
        class:valid={day.selected}
        class:maxvalid={day.maxvalid}
        class:maxvalidmin={day.maxvalidmin}
        class:maxvalidmax={day.maxvalidmax}
        class:validmin={day.validmin}
        class:validmax={day.validmax}
 -->
<time
  class="calendar"
  class:minimal={minimized}
  datetime="{minbound.toString()}/{maxbound.toString()}"
>
  {#each months(plainyearmonth(minbound), plainyearmonth(maxbound)) as yearmonth}
    <time
      class="month days{yearmonth.daysInMonth}"
      datetime={yearmonth.toString()}
    >
      <h1>{monthname[yearmonth.month]} {yearmonth.year}</h1>
      {#if !minimized}
        <time class="weekday sun" datetime="7">S</time>
        <time class="weekday mon" datetime="1">M</time>
        <time class="weekday tue" datetime="2">T</time>
        <time class="weekday wed" datetime="3">W</time>
        <time class="weekday thu" datetime="4">T</time>
        <time class="weekday fri" datetime="5">F</time>
        <time class="weekday sat" datetime="6">S</time>
      {/if}
      {#each daysparts(yearmonth) as [plaindate, parts]}
        {#if minimized}
          <time
            class="weekday {parts.weekday.toLowerCase()}"
            datetime={iso(plaindate)}>{parts.weekday.charAt(0)}</time
          >
        {/if}
        <time
          class="monthday {parts.weekday.toLowerCase()}"
          datetime={iso(plaindate)}
          ><button
            disabled={!enabled(plaindate, values, maxvalue)}
            value={iso(plaindate)}
            class:active={active(plaindate, value)}
            class:available={available(plaindate, maxvalue)}
            on:click={(e) => change(plaindate)}
            type="button">{plaindate.day}</button
          ></time
        >
      {/each}
    </time>
  {/each}
</time>
