<script context="module" lang="ts">
  import identity from "lodash-es/identity";
</script>

<script lang="ts">
  import RecordContent from "./RecordContent.svelte";
  import RecordLink from "./RecordLink.svelte";
  export let addressed: boolean | null | undefined = null;
  export let url = false;
  export let item;
</script>

{#if item}
  <RecordLink {item} {url}>
    <data
      class={["id", item.type, item.format].filter(identity).join(" ")}
      value={item.id || item.key}
    >
      <RecordContent {item} {addressed}><slot /></RecordContent>
    </data></RecordLink
  >
{/if}
