<script lang="ts">
  import Camera from "$components/camera/Camera.svelte";
  import { event } from "$utils/track";

  import { createEventDispatcher, onMount } from "svelte";

  export let selected = null;

  const dispatch = createEventDispatcher<{
    url: URL;
  }>();

  let preview;
  let cam;

  //$: cam?.scrollIntoView();

  onMount(() => {
    return clearPreview;
  });

  function clearPreview() {
    setPreview(null);
  }

  function setPreview(fileOrBlob) {
    if (preview) {
      URL.revokeObjectURL(preview);
    }
    preview = fileOrBlob && URL.createObjectURL(fileOrBlob);
    //logger("preview=", preview);
  }

  function select(value: URL) {
    selected = value;
    event("urlscanned", { component: "URLBarcodeScanner", value: value.href });
    if (value) dispatch("url", selected);
    clearPreview();
  }

  async function delayClearPreview(sec) {
    await new Promise((r) => setTimeout(r, sec * 1000));
    clearPreview();
  }

  let last;

  async function onbarcode(e) {
    // If we're already processing, don't process more than one at a time.
    if (preview) return;

    //logger("onbarcode=", e);

    const { barcode, blob } = e.detail;

    const photo = blob;

    if (!photo || !barcode || barcode == last) clearPreview();

    if (!barcode) return;
    if (barcode === last) return; // no new barcode
    let url: URL;

    if (URL.canParse && !URL.canParse(barcode)) return;
    if (URL.canParse) {
      url = new URL(barcode);
    } else {
      try {
        url = new URL(barcode);
      } catch {
        // not a url
        return clearPreview();
      }
    }

    //logger("onbarcode=", barcode, blob);

    // try {
    //   url = new URL(barcode);
    // } catch {
    //   // not a url
    //   return clearPreview();
    // }

    setPreview(photo);

    last = barcode;

    select(url);
  }
</script>

<nav class="scan">
  <Camera
    bind:this={cam}
    class="smart-decal"
    barcode={true}
    cover={preview}
    upload={false}
    on:barcode={onbarcode}
  />
  <slot />
</nav>
