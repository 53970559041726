<script lang="ts">
  import PropertyDataItem from "$components/property/PropertyDataItem.svelte";
  import PropertyLogo from "$components/property/PropertyLogo.svelte";
  import PropertyPhoto from "$components/property/PropertyPhoto.svelte";
  import PropertySummaryItem from "$components/property/PropertySummaryItem.svelte";
  import UiFooter from "$components/ui/Footer.svelte";
  import UiHeader from "$components/ui/Header.svelte";
  import UiNav from "$components/ui/Nav.svelte";
  import UiPrimaryView from "$components/ui/PrimaryView.svelte";
  import UiTitle from "$components/ui/Title.svelte";
  import Loading from "$components/util/Loading.svelte";
  import PropertyContact from "./PropertyContact.svelte";

  export let property: Property | nullish;
  export let title: string | nullish = null;

  $: console.log("slots=", $$slots);
</script>

<svelte:head>
  <meta name="theme-color" content="#fff" />
  <title
    >{(property && [title, property?.name].filter(Boolean).join(" - ")) ||
      "Loading…"}</title
  >
</svelte:head>

{#if property}
  <UiPrimaryView class="property">
    <UiHeader>
      <UiTitle>
        <PropertyDataItem {property} />
      </UiTitle>
      <slot name="header">
        <nav>
          <a href="/{property.type}/{property.id}">Menu</a>
        </nav>
      </slot>
    </UiHeader>
    <slot></slot>

    <UiFooter>
      <PropertyContact {property} />
      <PropertyLogo {property} />
    </UiFooter>
  </UiPrimaryView>
{:else}
  <Loading />
{/if}
