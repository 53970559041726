<script lang="ts">
  import { createEventDispatcher } from "svelte";

  export let id: string | nullish = null;
  export let type = "text";
  export let name: string | nullish = null;
  export let title: string | nullish = null;
  export let placeholder: string | nullish = null;
  export let required = false;
  export let readonly = false;
  export let value: string | nullish = "";
  export let minlength: string | nullish = null;
  export let maxlength: string | nullish = null;
  export let min: string | number | nullish = null;
  export let max: string | number | nullish = null;
  export let multiple = false;
  export let autocomplete: string | nullish = null;
  export let autocorrect: string | nullish = null;
  export let spellcheck: boolean | "true" | "false" | nullish = null;
  export let autocapitalize: string | nullish = null;
  export let disabled: boolean = false;
  export let checked: boolean = false;
  export let inputmode:
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search"
    | null
    | undefined = null;
  export let pattern: string | nullish = null;

  let element: HTMLInputElement;

  const events = createEventDispatcher<{
    change: {
      name: string;
      value: string | null;
    };
    // input: {
    //   name: string;
    //   value: string;
    // };
    blur: {
      name: string;
      value: string;
    };
  }>();

  function change(name: string, newvalue: string, ischecked?: boolean) {
    if (type === "checkbox") {
      checked = !!ischecked;
      return events("change", {
        name,
        value: element.checked ? value : null,
      });
    }
    value = newvalue;
    if (name)
      events("change", {
        name,
        value,
      });
  }
  function parseIntOrNull(input: string | nullish) {
    if (null == input) return null;
    const parsed = parseInt(input);
    if (isNaN(parsed)) return null;
    return parsed;
  }
</script>

<input
  bind:this={element}
  {type}
  {id}
  {name}
  {required}
  {readonly}
  {placeholder}
  {autocomplete}
  {spellcheck}
  {autocorrect}
  {autocapitalize}
  {disabled}
  {multiple}
  {title}
  {checked}
  {inputmode}
  {pattern}
  value={value ?? ""}
  minlength={parseIntOrNull(minlength)}
  maxlength={parseIntOrNull(maxlength)}
  {min}
  {max}
  on:change={({ currentTarget: target }) =>
    change(target.name, target.value, target.checked)}
  on:input={({ currentTarget: target }) =>
    change(target.name, target.value, target.checked)}
  on:blur={({ currentTarget: target }) => (
    change(target.name, target.value, target.checked),
    events("blur", { name: target.name, value: target.value })
  )}
/>
