<script lang="ts" context="module">
</script>

<script lang="ts">
  import Field from "$components/form/Field.svelte";
  import { createEventDispatcher } from "svelte";
  import ValueField from "$components/form/ValueField.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import { stripe, wallet } from "$utils/stripe";
  import StripeCardStep from "./StripeCardStep.svelte";
  import StripePaymentRequestStep from "./StripePaymentRequestStep.svelte";
  import Select from "$components/form/Select.svelte";
  import Option from "$components/form/Option.svelte";
  import type { Token } from "@stripe/stripe-js";
  export let payment: Payment;
  export let submitting: boolean = false;
  export let submittable: boolean = false;

  let token: Token | nullish = null;

  $: loading = !$stripe || null == $wallet;

  //let usewallet = true;
  let entercard = false;

  $: if (false === $wallet) {
    entercard = true;
  }

  const events = createEventDispatcher<{ submit: string }>();

  function submit(e: CustomEvent<Token>) {
    token = e.detail;
    events("submit", token.id); // pass up the token
  }
</script>

{#if !loading && $stripe && null != $wallet}
  {#if true || $wallet}
    <!-- <UiFormFieldList> -->
    <!-- <UiFormFieldItem>
      {#if null == $wallet}
        <ValueField label="Wallet">
          <data class="wallet" value="">checking&hellip...</data></ValueField
        >
      {:else if false === $wallet}
        <ValueField label="Wallet">
          <data class="wallet" value={!!$wallet}>not available</data
          ></ValueField
        >
      {:else}
        <ValueField label="Wallet">
          <data class="wallet" value={!!$wallet}>{JSON.stringify($wallet)}</data
          ></ValueField
        >
      {/if}
    </UiFormFieldItem> -->
    <!-- <UiFormFieldItem> -->
    <Field class="payment" label="Method">
      <data
        class="payment {entercard ? 'cards' : 'wallet'}"
        value={entercard
          ? "visa mastercard americanexpress discover"
          : $wallet && $wallet.applePay
            ? "applePay"
            : $wallet && $wallet.googlePay
              ? "googlePay"
              : ""}
      ></data>
      <!-- <data
            class="payment cards"
            value="visa mastercard americanexpress discover"
          ></data> -->
      {#if $wallet}
        <Select
          title="Payment Method"
          value={entercard + ""}
          on:change={(e) => (entercard = "true" === e.detail.value)}
        >
          <Option value="false"
            >{$wallet?.applePay
              ? "Apple Pay"
              : $wallet?.googlePay
                ? "Google Pay"
                : "Saved payment info"}</Option
          >
          <Option value="true">Credit/Debit Card</Option>
        </Select>
      {/if}
    </Field>
    <!-- <SelectField label="Pay With" value={entercard + ""}>
          <option value="false"
            >{$wallet?.applePay
              ? "Apple Pay"
              : $wallet?.googlePay
                ? "Google Pay"
                : "Saved payment info"}</option
          >
          <option value="true">Credit/Debit Card</option></SelectField
        > -->
    <!-- <Field label="Pay With">
          
          <ul>
            <li>
              <input
                id="stripe-method-wallet"
                type="radio"
                bind:group={entercard}
                value={false}
                disabled={!$wallet}
              /><data
                class="payment wallet"
                value={Object.entries($wallet || {})
                  .filter(([k, v]) => v)
                  .map(([k, v]) => k)
                  .join(" ")}
                >{false === $wallet
                  ? "no wallet available"
                  : $wallet?.applePay
                    ? "Apple Pay"
                    : $wallet?.googlePay
                      ? "Google Pay"
                      : "Saved payment info"}</data
              >
            </li>
            <li>
              <input
                id="stripe-method-card"
                type="radio"
                bind:group={entercard}
                value={true}
              /><data
                class="payment cards"
                value="visa mastercard americanexpress discover"
                >credit/debit card</data
              >
            </li>
          </ul>
        </Field> -->
    <!-- </UiFormFieldItem>
    </UiFormFieldList> -->
  {/if}
  {#if entercard}
    <StripeCardStep {submittable} {submitting} on:submit={submit}
      ><slot /></StripeCardStep
    >
  {:else}
    <StripePaymentRequestStep
      {submittable}
      {submitting}
      {payment}
      on:submit={submit}><slot /></StripePaymentRequestStep
    >
  {/if}
{:else}
  <ValueField label="Payment">
    <p>loading&hellip;</p>
  </ValueField>
  <!-- <UiFormFieldList>
    <UiFormFieldItem>
      
    </UiFormFieldItem>
  </UiFormFieldList> -->
{/if}
