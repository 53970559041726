<script>
  import Help from "$components/util/Help.svelte";
  import ViolationSearch from "$components/violation/ViolationSearch.svelte";
  import WarningScanInstructions from "$components/violation/WarningScanInstructions.svelte";
  import WarningScanner from "$components/violation/WarningScanner.svelte";

  function onviolation(e) {
    var value = e.detail;
    logger("violation=", value);
    if (value.url) window.location.href = value.url;
  }
</script>

<svelte:head>
  <meta name="theme-color" content="#fcfcfc" />
  <title>Scan Warning</title>
</svelte:head>

<main class="utility">
  <!-- <header>
    <a href="/">Back</a>
  </header> -->
  <WarningScanner on:warning={onviolation} />
  <WarningScanInstructions />
  <ViolationSearch
    on:violation={onviolation}
    description="Or search for violation:"
  />
  <footer>
    <Help
      title="Need help?"
      message="Please contact property or management office with questions"
    />
  </footer>
</main>
