<script>
  import minisvg from "mini-svg-data-uri";
  import Image from "./Image.svelte";
  export let image = "/parkingsign.svg";
  export let title = "Title";
  export let description = "Description.";
  let classname = "";
  export { classname as class };
</script>

<figure class={["instructions", classname].filter(Boolean).join(" ")}>
  <Image src={0 === image.indexOf("<svg") ? minisvg(image) : image} />
  <figcaption>
    <h1>{title}</h1>
    <p>
      {description}
    </p>
  </figcaption>
</figure>
