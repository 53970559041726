import {
  instant,
  parseInterval,
  validateInstant,
  iso,
  TemporalZonedDateTimeInterval,
  TemporalInstantInterval,
  maxInstant,
  date,
  time,
} from "$utils/temporal";
import { derived, type Readable } from "svelte/store";

import { fetchPolicy } from "$components/policy/api";
import { param } from "$utils/params";

//export const unitId = param("unit");
export const policyId = param("policy");
export { policyId as id };

export function policy(id: Readable<string | null>): Readable<PermitIssuePolicy | null> {
  return derived<typeof id, PermitIssuePolicy | null>(id, function updater($id, set) {
    set(null);
    if (!$id) return;
    fetchPolicy($id).then(set);
  });
}

// export const policy = derived<typeof policyId, PermitIssuePolicy | null>(
//   policyId,
//   function updater($policyId, set) {
//     if (!$policyId) return set(null);
//     fetchPolicy($policyId).then(set);
//   }
// );

export function PermitValidToString(
  valid: PermitValid
): Record<string, ParamValues> {
  return {
    start: iso(valid.start),
    end: iso(valid.end),
    duration: iso(valid.duration),
  };
}
export function PermitValidFromString(
  policy: PermitIssuePolicy,
  values: Record<string, ParamValues>
): PermitValid {

  return {
    start:
      values.start ?
        Temporal.Instant.from(values.start).toZonedDateTimeISO(policy.timezone) : null,
    end:
      values.end ?
        Temporal.Instant.from(values.end).toZonedDateTimeISO(policy.timezone) : null,
    duration: values.duration ? Temporal.Duration.from(values.duration) : null,
    //label: policy.validity.items?.[`${values.start}/${values.end}` || values.duration || ""] ?? null,
  };
}

export function PermitValidEffectiveInterval(
  valid: PermitValid,
  now: Temporal.ZonedDateTime
): TemporalZonedDateTimeInterval {
  if (valid.start && valid.end) return new TemporalZonedDateTimeInterval(valid.start, valid.end, now.timeZoneId);
  if (valid.start && valid.duration)
    return new TemporalZonedDateTimeInterval(valid.start, valid.start.add(valid.duration), now.timeZoneId);
  if (valid.duration && valid.end)
    return new TemporalZonedDateTimeInterval(valid.end.subtract(valid.duration), valid.end, now.timeZoneId);
  if (valid.start && !valid.end && !valid.duration) return new TemporalZonedDateTimeInterval(valid.start, maxInstant, now.timeZoneId);
  if (valid.duration) return new TemporalZonedDateTimeInterval(now, now.add(valid.duration), now.timeZoneId);
  if (valid.end && Temporal.ZonedDateTime.compare(valid.end, now) > 0)
    return new TemporalZonedDateTimeInterval(now, valid.end, now.timeZoneId);
  return new TemporalZonedDateTimeInterval(now, maxInstant, now.timeZoneId);
}

export function PermitValidFromInterval(
  policy: PermitIssuePolicy,
  interval: TemporalZonedDateTimeInterval | TemporalInstantInterval | nullish
): PermitValid {
  if (!interval) return {};
  if (interval instanceof TemporalInstantInterval) interval = interval.toZonedDateTimeInterval(policy.timezone);
  return {
    start: interval.minimum,
    end: interval.maximum,
    //duration: interval.duration,
  };
}

export function now(
  policy: PermitIssuePolicy
): Readable<Temporal.ZonedDateTime> {
  return instant("PT1M", policy.timezone);
}

export function nowIntervals(
  policy: PermitIssuePolicy,
  now: Temporal.Instant | Temporal.ZonedDateTime // don't expect this to change too often
): TemporalZonedDateTimeInterval[] {
  const nowzoned =
    now instanceof Temporal.ZonedDateTime
      ? now
      : now.toZonedDateTimeISO(policy.timezone);

  if (!startNowEnabled(policy)) return []; // now start isn't enabled    

  const starts = Object.values(policy.validity.intervals.start).map(
    parseInterval
  );
  const ends = Object.values(policy.validity.intervals.end).map(parseInterval);


  // TODO: how is starts supposed to be used?
  // if (!starts.length)
  if (starts.length && !starts.some(([a, b]) => validateInstant(now, a, b)))
    return []; // there are start intervals and now isn't in any of them

  const nowintervals = (Object.values(policy.validity.durations) as string[])
    .map(duration => duration ? Temporal.Duration.from(duration) : null)
    .reduce((result, item) => {
      result.push(new TemporalZonedDateTimeInterval(nowzoned, item ? nowzoned.add(item) : maxInstant, policy.timezone));
      return result;
    }, [] as TemporalZonedDateTimeInterval[]);
  return nowintervals;

  // check ends...
}

export function validateInterval(
  policy: PermitIssuePolicy,
  now: Temporal.Instant | Temporal.ZonedDateTime,
  min: Temporal.Instant | Temporal.ZonedDateTime,
  max: Temporal.Instant | Temporal.ZonedDateTime
): boolean {
  return true;
}

export function namedIntervals(
  policy: PermitIssuePolicy,
  now: Temporal.Instant | Temporal.ZonedDateTime // don't expect this to change too often
): Map<TemporalZonedDateTimeInterval, string> {
  const nowzoned =
    now instanceof Temporal.ZonedDateTime
      ? now
      : now.toZonedDateTimeISO(policy.timezone);
  return Object.entries((policy.validity.intervals.named ?? {}) as Record<string, string>).reduce((map, [interval, name]) => {
    var key = TemporalInstantInterval.from(interval)?.toZonedDateTimeInterval(policy.timezone);
    if (!key) return map;
    if (Temporal.ZonedDateTime.compare(key.maximum, nowzoned) < 0) return map;
    map.set(key, name);
    return map;
  }, new Map<TemporalZonedDateTimeInterval, string>());
}

export function predefinedIntervals(
  policy: PermitIssuePolicy,
  now: Temporal.Instant | Temporal.ZonedDateTime // don't expect this to change too often
): TemporalZonedDateTimeInterval[] {
  const nowzoned =
    now instanceof Temporal.ZonedDateTime
      ? now
      : now.toZonedDateTimeISO(policy.timezone);
  // const nowinstant =
  //   now instanceof Temporal.ZonedDateTime ? now.toInstant() : now;
  return Object.keys(policy.validity.intervals.items)
    .map(TemporalInstantInterval.from)
    .map(interval => interval?.toZonedDateTimeInterval(nowzoned.timeZoneId))
    .filter(interval => interval && (!now || Temporal.ZonedDateTime.compare(interval.maximum, nowzoned) >= 0) && available(policy, interval)) as TemporalZonedDateTimeInterval[];
  // ^^ this will validate interval availability if it's defined

  //   parseInterval(str)
  //     ?.map((instant) => instant?.toZonedDateTimeISO(policy.timezone))
  //     .filter(Boolean)
  // )
  // .map((interval) => ({
  //   //interval: 2 === interval?.length ? new TemporalZonedDateTimeInterval(interval[0], interval[1], policy.timezone) : null,
  //   //label: policy.validity.items[interval?.map(iso).join("/") ?? ""],
  //   value: interval?.map(iso).join("/") ?? "",
  //   start: interval?.[0],
  //   end: interval?.[1],
  // }))
  // .filter(
  //   (interval) =>
  //     interval?.end &&
  //     (!now || Temporal.ZonedDateTime.compare(interval.end, nowzoned) >= 0)
  // ) as PermitValid[];
}

export function spaces(policy: PermitIssuePolicy): Spaces {
  return Object.values(policy?.availability?.["for"] ?? policy?.spaces?.items ?? {}).reduce(
    (result, availability) => {

      const space = availability?.subject ?? availability;
      if (space?.type == "space" && policyCanPermitSpace(policy, space)) {
        result.count++;
        result.items[space.id] = space;
      }
      return result;
    },
    { items: {}, count: 0 } as Spaces
  ) as Spaces;
}

export function available(
  policy: PermitIssuePolicy,
  valid: TemporalZonedDateTimeInterval | TemporalInstantInterval
): boolean {

  //logger("available", policy.availability, valid?.toString());

  if (!policy.availability?.items) return true;
  if (Object.entries(policy.availability.items as Record<string, number>).some(([interval, count]) => {
    if (count || count > 0) return false;
    //logger(valid.toString(), "overlaps", interval, valid.overlaps(TemporalInstantInterval.from(interval)));
    return valid.overlaps(TemporalInstantInterval.from(interval));
    // const b = parseInterval(interval);
    // return overlappingIntervals(parsed, b);
  })) return false;

  return true;
}

export function availableSpaces(
  policy: PermitIssuePolicy,
  valid: TemporalZonedDateTimeInterval | TemporalInstantInterval
): Spaces | null {
  logger("availableSpaces", policy, valid?.toString());
  if (!policy?.availability) return policy.spaces ?? null; // no availability to check
  //if (!policy.space?.unpermitted?.required) return policy.spaces ?? null; // no unpermitted/capacity checking is required
  return Object.values(policy?.availability?.["for"] ?? {}).reduce(
    (result, availability) => {
      if (availability.subject?.type !== "space") return result;
      if (!policyCanPermitSpace(policy, availability.subject)) return result;
      if (
        Object.entries(availability.intervals ?? availability.items).find(([interval, count]) => {
          if (count || count > 0) return false;

          return valid.overlaps(TemporalInstantInterval.from(interval));
          // const b = parseInterval(interval);
          // return overlappingIntervals(parsed, b);
        })
      )
        return result;

      // ok to add
      result.count++;
      result.items[availability.subject.id] = availability.subject;

      //result.push(availability.subject); // ok to add

      return result;
    },
    { items: {}, count: 0 } as Spaces
  ) as Spaces;
}


export function startNowEnabled(policy: PermitIssuePolicy) {
  if (!policy) return true;
  if (policy.permit?.continuous) return true;
  if (policy.validity) return policy.validity.min.now.enabled;
  const futuremin = Temporal.Duration.from(
    policy.valid.min.future.min ?? policy.valid.min.future
  );

  return futuremin.total("milliseconds") == 0;
}

export function startNowRequired(policy: PermitIssuePolicy) {
  if (policy.permit?.continuous) return true;
  if (policy.validity) return policy.validity.min.now.required;
  const futuremin = Temporal.Duration.from(
    policy.valid.min.future.min ?? policy.valid.min.future
  );
  const futuremax = Temporal.Duration.from(
    policy.valid.min.future.max ?? policy.valid.min.future
  );
  return (
    futuremin.total("milliseconds") == 0 && futuremax.total("milliseconds") == 0
  );
}

export function labelIntervalStart(
  policy: PermitIssuePolicy,
  min: Temporal.ZonedDateTime | [Temporal.ZonedDateTime, Temporal.ZonedDateTime]
): string {
  if (Array.isArray(min)) min = min[0];
  return `${date(min)} ${time(min)}`;
}

export function labelInterval(
  policy: PermitIssuePolicy,
  interval:
    | [Temporal.ZonedDateTime, Temporal.ZonedDateTime]
    | Temporal.ZonedDateTime[]
): string {
  if (!Array.isArray(interval) || interval.length != 2) return "";
  // if starts now, duration only
  if (startNowRequired(policy))
    return interval[1]
      .since(interval[0], { smallestUnit: "minute" })
      .toString();

  // if fixed start
  return `${date(interval[1])} ${time(interval[1])}`;
}

export function policyCanPermitSpace(
  policy: PermitIssuePolicy,
  item: Space
): boolean {
  if (null == policy) return false;
  if (null == item) return false;
  // if items has to be in it
  if (policy.spaces?.items && !policy.spaces.items[item.id]) return false;
  //   return !!policy.spaces.items[item.id];
  // }
  // has to be parking
  if (
    policy.amenity === "parking" &&
    item.amenity !== "parking" &&
    item.amenity !== "parking_space"
  )
    return false;
  // what about other spaces?
  // if (policy.amenity && policy.amenity === item.amenity) return true;
  // if (policy.leisure && policy.leisure === item.leisure) return true;
  return true;
}

export function errored(error: any, param: string) {
  logger("errored=", error, param, error?.[param]);
  if (!error) return error;
  if (error?.[param]) return error[param];
  if (error?.param === param) return error;
  return null;
  //return error?.[param] ?? (error?.param === param ? error : null);
}