<script lang="ts">
  import FileDocument from "$components/file/FileDocument.svelte";
  import Info from "$components/util/Info.svelte";
  import Warning from "$components/util/Warning.svelte";
  import PolicyPhoto from "./PolicyPhoto.svelte";

  export let policy;

  $: issue = policy && policy.issue && policy.issue.enabled;
  $: disabled = policy && policy.disabled && policy.disabled;
  $: disabledReason = policy && policy.disabled && policy.disabled.reason;
  //$: alert = policy && policy.alert && policy.alert.text;
  //$: info = policy && policy.info && policy.info.text;
  $: pricing = policy && policy.pricing && policy.pricing.description;
  $: metering = policy && policy.metered && policy.metered.description;
  $: rules = policy && policy.rules && policy.rules.description;
  $: custom = policy && policy.rules && policy.rules.custom;
  $: instructions = policy && policy.instructions;
</script>

{#if policy && policy.type == "policy"}
  {#if issue}
    <nav>
      <a href={policy.url}>{policy.issue.title}</a>
    </nav>
  {/if}
  <PolicyPhoto {policy} width={500} />
  <!-- <data class="policy" value="{policy.id}">{policy.title}</data> -->

  {#if disabled}
    <Warning class="closed" message={disabledReason} />
  {:else}
    {#if policy.schedule}
      <ul class="opening_hours">
        {#each Object.entries(policy.schedule.friendly).filter(([desc, status]) => status == "open") as [desc]}
          <li>{desc}</li>
        {/each}
      </ul>
    {/if}
    {#if policy.alert?.text}
      <Warning message={policy.alert.text} />
    {/if}
    {#if policy.info?.text}
      <Info message={policy.info.text} />
    {/if}
    {#if rules}
      <Info class="rules" message={rules} />
      <!-- <aside class="rules info"><p>{rules || ""}</p></aside> -->
    {/if}
    {#if metering}
      <aside class="metering info"><p>{metering || ""}</p></aside>
    {/if}
    {#if pricing}
      <aside class="pricing info"><p>{pricing || ""}</p></aside>
    {/if}
    {#if custom}
      <!-- <aside class="rules info"><p>{custom || ""}</p></aside> -->
      <Info class="rules" message={custom} />
    {/if}
    {#if instructions}
      <!-- <aside class="instructions info"><p>{instructions || ""}</p></aside> -->
      <Info class="instructions" message={instructions} />
    {/if}
    <ul class="files">
      {#each Object.values(policy.documents?.items ?? {}) as document}
        <li>
          <FileDocument {document} />
        </li>
      {/each}
    </ul>
  {/if}
{/if}
