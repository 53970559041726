<script lang="ts">
  import FileDocument from "$components/file/FileDocument.svelte";
  import PolicyInfoDisclosure from "./PolicyInfoDisclosure.svelte";

  export let policy: PermitIssuePolicy;
  $: pricing = policy?.pricing?.description?.trim();
  $: metering = policy?.metered?.description?.trim();
  $: rules = policy?.rules?.description?.trim();
  $: custom = policy?.rules?.custom?.trim();
  $: instructions = policy?.instructions?.trim();
  $: expanded = !policy?.issue?.enabled ?? false;
</script>

{#if policy}
  <PolicyInfoDisclosure {policy} {expanded}>
    {#if rules}
      <aside class="rules info"><p>{rules || ""}</p></aside>
    {/if}
    {#if metering}
      <aside class="metering info"><p>{metering || ""}</p></aside>
    {/if}
    {#if pricing}
      <aside class="pricing info"><p>{pricing || ""}</p></aside>
    {/if}
    {#if custom}
      <aside class="rules info"><p>{custom || ""}</p></aside>
    {/if}
    {#if instructions}
      <aside class="instructions info"><p>{instructions || ""}</p></aside>
    {/if}
    <ul class="files">
      {#each Object.values(policy.documents?.items ?? {}) as document}
        <li>
          <FileDocument {document} />
        </li>
      {/each}
    </ul>
    <slot />
  </PolicyInfoDisclosure>
{/if}
