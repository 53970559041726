function autocreate(key: string, scope?: string): Vehicle {
  key = plate(key);
  if (!key) return null;
  if (key.length < 3) return null;
  return {
    scope,
    type: "vehicle",
    id: key,
    key: key,
    display: key,
  };
}

function plate(key: string): string {
  if (!key || typeof key !== "string") return null;
  key = key
    .toUpperCase()
    .replace(/[^A-Z0-9]/gi, "")
    .replace("O", "0");
  if (key.length <= 1) return null;
  if (key.length >= 10) return null;
  return key;
}

export class Vehicle implements Typed {
  generated?: string;
  id?: string;
  uuid?: string;
  type: string = "vehicle";
  scope?: string;
  plate?: string;
  make?: string;
  model?: string;
  color?: string;
  year?: number;
  constructor(props: Vehicle) {
    //super(props);
    Object.assign(this, props);
  }

  static from(init: Vehicle | string): Vehicle | null {
    if (null == init) return null;
    if (typeof init === "string") {
      const autoinit = autocreate(init);
      if (autoinit) return new Vehicle(autoinit);
      return null;
    }
    return new Vehicle(init);
  }
}
