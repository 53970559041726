import { derived, writable } from "svelte/store";
import { param, params } from "./params";
import { geo } from "@parkingboss/svelte-utils";

export const camera = writable({});
export const view = param("view");
export const scan = derived(
  params,
  ($params) => null != $params?.scan || "scan" === $params.view
);

export const minimal = derived(
  param("minimal"),
  ($minimal) => $minimal == "true"
);

export const valid = derived([params], ([params]) => params.valid);
export const search = derived(
  [params],
  ([params]) => params.q || params.search
);

//view.subscribe($view => logger("view=", $view));

export const position = writable<Position>({
  requested: false,
  status: "unstarted",
  coords: undefined,
  timestamp: undefined,
}); // defaults to no position...
// on demand call for updating location
export const locate = function () {
  // once pattern
  var unsubscribe = geo.subscribe((value) => {
    position.set({
      requested: true,
      status: value.status,
      error: value.error,
      coords: value.position?.coords,
      timestamp: value.position?.timestamp,
    });

    // when we get a value, unsubscribe...
    if (unsubscribe && (value.position || value.error)) {
      unsubscribe();
      if (value.position)
        position.update(($value) => ({ ...$value, status: "idle" })); // update position to idle
    }
  });

  return position; // return the store
};
export const watch = function () {
  // watch pattern
  var unsubscribe = geo.subscribe((value) => {
    position.set({
      requested: true,
      status: value.status,
      error: value.error,
      coords: value.position?.coords,
      timestamp: value.position?.timestamp,
    });
  });

  return function unwatch() {
    unsubscribe();
    //if (value.position)
    position.update(($value) => ({ ...$value, status: "idle" })); // update position to idle
  };

  // when we get a value, unsubscribe...
  //if (unsubscribe && (value.position || value.error)) unsubscribe();
  //});

  //return unsubscribe; //position; // return the store
};
position.subscribe((value) => logger("position=", value));
