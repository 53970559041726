<script context="module" lang="ts">
  function debounce(fn) {
    let raf;

    return (...args) => {
      if (raf) {
        //logger("debounced");
        return;
      }

      raf = requestAnimationFrame(() => {
        fn(...args); // run useful code
        raf = undefined;
      });
    };
  }
</script>

<script>
  import { createEventDispatcher, onMount } from "svelte";

  //import resize from "svelte-use-resize-observer";
  import { resize } from "svelte-resize-observer-action";

  import QRScanner from "qr-scanner";

  const dispatch = createEventDispatcher();

  //const qrengine = QRScanner.createQrEngine();

  //logger("engine=", qrengine);

  // Component API
  export function scrollIntoView(opts) {
    figureEl?.scrollIntoView(opts);
  }

  // Params
  let mode;
  export { mode as class };
  export let cover = null;
  export let upload = true;
  export let barcode = false;

  // Element Bindings
  let figureEl = null;
  let videoEl = null;
  let qrscanner = null;

  // State
  let loading = true;
  let fallback = false;

  $: if (videoEl && !qrscanner)
    qrscanner = new QRScanner(videoEl, onresult, {
      returnDetailedScanResult: true,
      onDecodeError: function () {},
      // calculateScanRegion: ($video) => ({
      //   x: 0,
      //   y: 0,
      //   width: $video.videoWidth,
      //   height: $video.videoHeight,
      //   downScaledWidth: $video.videoWidth,
      //   downScaledHeight: $video.videoHeight,
      // }),
      //highlightScanRegion: true,
      //highlightCodeOutline: true,
    });

  $: if (qrscanner) {
    startStream();
  }

  function onresult(result) {
    //logger("decoded qr code:", result);
    fireBarcode({ barcode: result.data, blob: null });
  }

  function startStream() {
    if (fallback || !barcode) return;
    //logger("qrscanner=", qrscanner);
    qrscanner
      ?.start()
      .then(function () {})
      .catch(function (error) {
        logger("error=", error);
        fallback = true;
      })
      .finally(() => {
        loading = false;
      });
  }

  function stopStream() {
    qrscanner?.stop();
  }

  function onVisibilityChange() {
    if (document.hidden) {
      stopStream();
    } else {
      startStream();
    }
  }

  onMount(() => {
    // this is handled by qrscanner
    //document.addEventListener("visibilitychange", onVisibilityChange);
    return () => {
      //document.removeEventListener("visibilitychange", onVisibilityChange);
      stopStream();
      qrscanner?.destroy();
      qrscanner = null;
    };
  });

  // Event Handlers:
  async function onFileChanged(evt) {
    const blob = evt.target.files[0];
    if (!blob) return;

    fireFrame({ blob });

    if (barcode) {
      QRScanner.scanImage(blob, {
        returnDetailedScanResult: true,
        //qrEngine: qrengine,
      })
        .then(function (result) {
          logger("result=", result);
          fireBarcode({ barcode: result.data, blob });
        })
        .catch(function (error) {
          logger("error=", error);
        });
    }
  }

  async function onClick(evt) {
    // if (cover) {
    //   return;
    // }
    // const imgData = await getFrame(videoEl, null);
    // const blob = await imageDataToBlob(imgData);
    // fireFrame({ blob });
    // if (barcode) {
    //   const result = await getBarcode(imgData);
    //   fireBarcode({ barcode: result, blob });
    // }
  }

  // Event Dispatchers
  function fireFrame({ blob }) {
    dispatch("frame", { blob });
  }

  function fireBarcode({ barcode, blob }) {
    dispatch("barcode", { barcode, blob });
  }

  const onresize = debounce(() => {
    //logger("resizing video", videoEl, qrscanner);
    //qrscanner?._updateOverlay();
    if (qrscanner)
      qrscanner._scanRegion = qrscanner._calculateScanRegion(qrscanner.$video);
    //logger("resized video", videoEl, qrscanner);
  });
</script>

<figure
  class="camera {mode}"
  bind:this={figureEl}
  class:loading
  class:video={!fallback}
>
  {#if fallback}
    <input
      type="file"
      accept="image/*"
      capture="environment"
      on:change={onFileChanged}
    />
  {:else if !loading}
    <button type="button" on:click={onClick} />
  {/if}

  <img src={cover || ""} alt="scanning preview" />

  {#if upload}
    <input type="file" accept="image/*" on:change={onFileChanged} />
  {/if}

  <video
    bind:this={videoEl}
    playsinline
    autoplay
    muted
    use:resize={onresize}
    on:resize={onresize}
  />

  {#if !loading && !fallback}
    <figcaption>
      <slot />
    </figcaption>
  {/if}
</figure>
