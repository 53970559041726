import "temporal-polyfill/global";
import "./logging";
import { router, state, end, exit } from "./util/router";
import App from "./App.svelte";
import "./app.scss";
import "$utils/stripe";
// import package from "../package.json";
// import * as Sentry from "@sentry/svelte";

new App({ target: document.body });

// Sentry.init({
//   dsn: "https://627e0a1d5f7e6722dc2b114ad37540b1@o4505835498897408.ingest.sentry.io/4505835525701632",

//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       //tracePropagationTargets: ["localhost", "*"],
//     }),
//     new Sentry.Replay(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

function view(viewname: string) {
  return function (ctx: any, next: any) {
    ctx.params.view = viewname;
    next();
  };
}
router("/account/:account/directory", view("accountdirectory"), state, end);
router("/account/:account/map", view("accountmap"), state, end);
router("/accounts/:account", view("account"), state, end);
router("/account/:account", view("account"), state, end);

// page("/properties/:property/*", state);
router("/properties/nearby", view("nearby"), state, end);
router("/property/:property/amenities", view("propertyamenities"), state, end);
router("/property/:property/parking", view("propertyparking"), state, end);
router("/property/:property/directory", view("propertydirectory"), state, end);
//router("/property/:property/spaces", view("propertyspaces"), state, end);
router("/property/:property/units", view("propertyunits"), state, end);
router("/properties/:property", view("property"), state, end);
router("/property/:property", view("property"), state, end);
router("/property", "/properties");

router("/policies/:policy/spaces/map", view("policyspacemap"), state, end);
router("/policies/:policy", view("policy"), state, end);
router("/policy/:policy/preauth", view("policypreauth"), state, end);
router("/policy/:policy/spaces/map", view("policyspacemap"), state, end);
router("/policy/:policy", view("policy"), state, end);


router("/permits", view("permits"), state, end);
router("/permit/:permit", view("permit"), state, end);
router("/violation/:violation", view("violation"), state, end);

router("/wallet/:media", view("wallet"), end);
router("/media/:media/wallet", view("wallet"), end);
router("/media/:media/:subview", view("media"), end);
router(
  "/media/:media/policy/:policy",
  function (ctx, next) {
    logger("media route", ctx);
    next();
  },
  view("media"),
  end
);
router(
  "/media/:media",
  function (ctx, next) {
    logger("media route", ctx);
    next();
  },
  view("media"),
  end
);

// page("/p/:permit", view("permit"), state, end);
// page("/permits/:permit", view("permit"), state, end);

// // policy catch all
// page("/properties/:property/amenities/:policy/*", state);
// page("/properties/:property/amenities/:policy/permits/new", view("newpermit"), state, end);

// // policy home
// page("/properties/:property/amenities/:policy/:subview", view("policy"), state, end);
// page("/properties/:property/amenities/:policy", view("policy"), state, end);

// // property subview
// page(`/properties/:property/:view`, state, end);

// // property subview
// page(`/permits`, view("permits"), state, end);

// page(`/demo`, view("demo"), state, end);

// index
router("/scan", view("scan"), state, end);
router("/smartdecal/activate", "/smartdecal");
router("/smartdecal", view("smartdecal"), state, end);

router("/violations", view("violations"), state, end);
router("/violation", view("violations"), state, end);
router("/warning", view("violations"), state, end);
router("/validate", view("validate"), state, end);

//router("/violation", legacy);
//router("/warning", legacy);
//router("/validate", legacy);
router("/activate", "/smartdecal");
//router("/smartdecal", legacy);

// default view
router("/:view", state, end);

// intercept root requests for special query params
function queryToPath(ctx, next) {
  var media = ctx.query.media || ctx.query.m || ctx.query.id || ctx.query.d;
  // var query = new URLSearchParams(ctx.querystring);
  // var media = query.get("media") || query.get("m") || query.get("id") || query.get("d");
  if (!!media) return router.redirect("/media/" + media);
  if (ctx.query.parkingavailable)
    return router.redirect(`/policy/${ctx.query.parkingavailable}/spaces/map`);
  if (ctx.query.property)
    return router.redirect(`/property/${ctx.query.property}`);
  if (ctx.query.account)
    return router.redirect(`/account/${ctx.query.account}/directory`);
  //return router("/media/activate");
  next();
}

router("/", queryToPath);
router("/", "/properties");

// index
router("*", state, end);

router.start();
