import { derived, type Readable } from "svelte/store";
import { fetchMedia } from "./api";
import { param } from "./params";

export const mediaId = param("media");

// const mediaStatus = derived<typeof mediaId, any>(mediaId, ($mediaId, set) => {
//   if (!$mediaId) return set(null);

//   fetchMedia($mediaId).then(set);
// });

export const mediaDetails = derived<typeof mediaId, Media | null>(mediaId, ($id, set) => {
    logger("mediaId", $id);
    if (!$id) return set(null);
    fetchMedia($id).then(set);
  })

// export function mediaDetails(id: Readable<string>) {
//   return ;
// }

export function media(status: Readable<any>): Readable<Media | null> {
  return derived(status, ($mediaStatus) => {
    if (!$mediaStatus) return null;
    return $mediaStatus?.items[$mediaStatus.media.item] ??
      $mediaStatus?.media.item;
  });
}

// export const media = derived<[typeof mediaId, typeof mediaStatus], Media>(
//   [mediaId, mediaStatus],
//   ([$mediaId, $mediaStatus]) => {
//     return (
//       $mediaStatus?.items[$mediaId] ??
//       $mediaStatus?.items[$mediaStatus.media.item] ??
//       $mediaStatus?.media.item
//     );
//   }
// );

export function mediaPermits(status: Readable<any>) {
  const item = media(status);
  return derived<[ typeof item, typeof status], Permits>([ item, status], ([$media, $data]) => {
    if (null == $media) return null;
    if (null == $data) return null;

    return {
      ...($data.permits["for"][$media.id] ?? {
        generated: $data.generated,
        count: 0,
        items: [],
      }),
      subject: $media,
    };
  });
}

export function mediaPolicies(status: Readable<any>) {
  const item = media(status);
  return derived<[ typeof item, typeof status], PermitIssuePolicies>([ item, status], ([$media, $data]) => {
    if (null == $media) return null;
    if (null == $data) return null;
    return {
      ...($data.policies?.["for"]?.[$media.id] ?? {
        generated: $data.generated,
        count: 0,
        items: [],
      }),
      subject: $media,
    };
  });
}

// export const mediaPermits = derived<
//   [typeof media, typeof mediaStatus],
//   Permitted
// >([media, mediaStatus], ([$media, $data]) => {
//   if (null == $media) return null;
//   if (null == $data) return null;

//   return {
//     ...($data.permits["for"][$media.id] ?? {
//       generated: $data.generated,
//       count: 0,
//       items: [],
//     }),
//     subject: $media,
//   };
// });
// export const mediaPolicies = derived<
//   [typeof media, typeof mediaStatus],
//   Policies
// >([media, mediaStatus], ([$media, $data]) => {
//   if (null == $media) return null;
//   if (null == $data) return null;
//   return {
//     ...($data.policies?.["for"]?.[$media.id] ?? {
//       generated: $data.generated,
//       count: 0,
//       items: [],
//     }),
//     subject: $media,
//   };
// });
