<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Field from "./Field.svelte";

  export let name: string | nullish = null;
  export let label: string | nullish = null;
  export let value: File | nullish = null;
  export let required: boolean | nullish = null;
  export let readonly: boolean | nullish = null;

  const events = createEventDispatcher<{ change: typeof value }>();

  let url: string | nullish = null;

  function change(
    updated?:
      | typeof value
      | (Event & { currentTarget: EventTarget & HTMLInputElement })
  ) {
    if (updated instanceof Event) {
      updated = updated.currentTarget?.files?.[0];
    }
    logger("image change=", updated);
    events("change", (value = updated));
  }

  $: if (!value && url) {
    URL.revokeObjectURL(url);
    url = null;
  }
  $: if (value) {
    if (url) URL.revokeObjectURL(url);
    url = URL.createObjectURL(value);
  }
</script>

<Field {label}>
  <figure class="image">
    <img src={url || ""} alt="Selected" />
    <figcaption>
      <!-- {#if label}
        <h1>{label}</h1>
      {/if} -->
      <!-- upload from library or camera -->
      {#if value}
        <button class="clear" on:click={(e) => change(null)}>clear</button>
      {/if}
      <input type="file" accept="image/*" on:change={change} />
      <input
        type="file"
        accept="image/*"
        capture="environment"
        on:change={change}
      />
    </figcaption>
  </figure>
</Field>
