<script lang="ts">
  import Field from "$components/form/Field.svelte";
  import type {
    StripeCardElement,
    StripeElementChangeEvent,
    StripeElements,
    Token,
    TokenResult,
  } from "@stripe/stripe-js";
  import { createEventDispatcher } from "svelte";
  import Submit from "$components/form/Submit.svelte";
  import { stripe } from "$utils/stripe";

  export let submittable: boolean = false;
  export let submitting: boolean = false;

  let valid: boolean = false;
  let state: StripeElementChangeEvent | null = null;
  let result: TokenResult | null = null;
  let token: Token | nullish = null;

  const events = createEventDispatcher<{
    submit: Token;
  }>();

  let container: HTMLElement | null;
  let cardElement: StripeCardElement | null = null;
  let elements: StripeElements | null = null;

  //$: events("valid", valid); // trip event on valid change

  $: elements ??= $stripe?.elements() ?? null;
  $: cardElement ??= elements?.create("card") ?? null;

  $: if (cardElement && container) {
    cardElement.mount(container);
    cardElement.on("change", function (event) {
      valid = event.complete;
      state = event;
      //events("change", event);
    });
  }

  // handle the card payment
  async function handle() {
    logger("stripe payment card handle=", cardElement, state, $stripe);
    if (cardElement && valid && $stripe) {
      submitting = true;
      result = await $stripe.createToken(cardElement);
      token = result?.token;

      // const result = await $stripe.createPaymentMethod({
      //   type: "card",
      //   card: cardElement,
      // });
      if (result?.error) {
        // Display error.message in your UI.
        console.error(result.error.message);
        alert(result.error.message); // needs to be better
        submitting = false;
      }

      // cannot submit without a token
      if (token) events("submit", token);
    }
  }
</script>

<!-- <UiFormFieldList>
  <UiFormFieldItem> -->
<Field class="payment">
  <!-- <legend
    >How do you want to pay? <a href="https://stripe.com" target="_blank"
      >Secure</a
    ></legend
  > -->
  <!-- we need info here-->
  <div class="stripe-payment-card" bind:this={container}></div>
</Field>
<!-- </UiFormFieldItem>
</UiFormFieldList> -->
<slot />
<footer>
  <Submit disabled={!valid || !submittable || submitting} on:submit={handle}
    >Pay Now</Submit
  >
  <!-- Pay -->
</footer>
