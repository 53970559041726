<script lang="ts">
  import TextField from "$components/form/TextField.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import { createEventDispatcher } from "svelte";
  import PolicyError from "./PolicyError.svelte";
  import { errored } from ".";
  import Submit from "$components/form/Submit.svelte";

  // const name: string = "token";

  const eventing = createEventDispatcher<{
    change: typeof values;
  }>();

  export let policy: PermitIssuePolicy;
  export let values: Params;

  let field = policy?.permit?.authentication;
  let name = field.param ?? "authentication";

  let value: string | nullish; // = values[name] as ParamValue;
  let changed: boolean = false;
  let required = !!field?.required;
  let proceed: boolean = !required;

  $: field = policy?.permit?.authentication;
  $: if (field) name = field.param;
  $: required = !!field?.required;

  //$: value = values[name] as ParamValue;

  export let error: any | nullish = null;
  $: err = changed
    ? null
    : errored(error, name) ?? errored(error, "authentication");

  //$: logger("policy auth=", required, value, values);

  $: proceed = !required || (!!value && !changed && !err);

  function change(updated: typeof value) {
    // if (updated == temp) return; // no change
    // temp = updated;
    eventing("change", {
      [name]: updated,
    });
    changed = false;
  }
</script>

{#if required}
  <UiFormFieldList>
    <UiFormFieldItem>
      <TextField
        {name}
        label={field?.label ?? "Passcode"}
        {required}
        {value}
        on:change={(e) => ((value = e.detail.value), (changed = true))}
      />
      <PolicyError data={err} />
    </UiFormFieldItem>
  </UiFormFieldList>
{/if}
{#if proceed}
  <slot />
{:else}
  <footer>
    <fieldset>
      <Submit disabled={!value || !changed} on:submit={(e) => change(value)}
        >Continue</Submit
      >
    </fieldset>
  </footer>
{/if}
