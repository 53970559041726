<script lang="ts">
  import { watch, position } from "$utils/uistores";
  import { onDestroy } from "svelte";
  import type { Unsubscriber } from "svelte/store";
  import { states } from "./stores";

  export let autostop: boolean = true;

  $: value = $position;

  let stop: Unsubscriber;
  function click() {
    if (stop) stop();
    if (stop) return (stop = null);
    stop = watch();
  }

  onDestroy(function () {
    logger("stop watching position");
    if (autostop && stop) stop();
  });
</script>

<button
  type="button"
  class="position watch {value.status}"
  title={states[value.status]}
  on:click={click}>Use My Location</button
>
