<script lang="ts">
  let classname: string | nullish = null;
  export { classname as class };
  export let label: string | nullish = "Unit";
</script>

<figure class={["value", classname].filter(Boolean).join(" ")}>
  {#if label}
    <figcaption>{label}</figcaption>
  {/if}
  <slot />
</figure>
