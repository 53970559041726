<script lang="ts">
  import { createEventDispatcher } from "svelte";
  let classname = "";
  export { classname as class };
  export let type: "button" | "submit" | "reset" = "button";
  export let disabled: boolean = false;
  const events = createEventDispatcher<{
    click: MouseEvent;
  }>();
</script>

<button
  class={classname || null}
  on:click|preventDefault={(e) => events("click", e)}
  {type}
  {disabled}><slot /></button
>
