<script lang="ts">
  export let value: string | nullish;
  export let text: string | nullish = undefined;
  export let label: string | nullish = undefined;
  export let selected: boolean | nullish = undefined;
</script>

<option {value} {label} {selected}>
  {#if text}{text}{:else}<slot />{/if}
</option>
