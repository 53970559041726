<script lang="ts">
  import { thumbnail } from "../../util/filepreview";

  export let property: Property;
</script>

{#if property?.photo}
  <figure class="photo {property.type}">
    <img
      class="photo"
      alt="{property.name} photo"
      src={thumbnail(property.photo)}
    />
    <figcaption>
      <slot />
    </figcaption>
  </figure>
{/if}
