<script>
  import MediaScanInstructions from "$components/media/MediaScanInstructions.svelte";
  import MediaScanner from "$components/media/MediaScanner.svelte";
  import Help from "$components/util/Help.svelte";

  export let property = null;
  export let policy = null;

  function onmedia(e) {
    var value = e.detail;
    if (value.url) window.location.href = value.url;
  }
</script>

<svelte:head>
  <meta name="theme-color" content="#fcfcfc" />
  <title>Scan Smart Decal/Tag</title>
</svelte:head>

<main class="utility">
  <!-- <header>
    <a href="/">Back</a>
  </header> -->
  <MediaScanner on:media={onmedia} />
  <MediaScanInstructions />
  <footer>
    <Help
      title="Need help?"
      message="Please contact property or management office with questions"
    />
  </footer>
</main>
