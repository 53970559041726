import { fetchUnits } from "$utils/api";
import { derived, writable, type Readable } from "svelte/store";

export const unit = writable("");

export function units(scope: Readable<string | null>): Readable<Units | null> {
    return derived<typeof scope, Units | null>(scope, ($scope, set) => {
        set(null);
        if (!$scope) return;
        fetchUnits($scope).then(function (json) {
            const meta = json.units["for"][$scope];
            const items = meta.items;

            for (const [k, v] of Object.entries(items)) {
                items[k] = json.items[v as string] ?? json.items[k] ?? v;
            }

            return meta;
        }).then(set).catch(console.error);
    })
}