<script>
  import PropertiesSearch from "$components/property/PropertiesSearch.svelte";
  import { camera, locate, search } from "$utils/uistores";
  import { pastProperties as recent } from "$utils/propertystores";
  import PropertiesList from "$components/property/PropertiesList.svelte";
  import Image from "$components/util/Image.svelte";
  import Help from "$components/util/Help.svelte";
</script>

<svelte:head>
  <meta name="theme-color" content="#fcfcfc" />
  <title>Parking & Amenities</title>
</svelte:head>

<main class="utility">
  <header>
    <Image src="/qr-products.png" />
  </header>
  <nav class="properties">
    <header>
      <PropertiesSearch />

      <!-- <a data-status="{!$camera ? "" : $camera.video === false ? "unpermitted" : $camera.active === true ? "active" : $camera.loading === true ? "requesting" : "" }" href="/?scan=true">Scan Barcode</a> -->

      <!-- <button
        type="button"
        class="barcode"
        on:click={(e) => (scan = true)}
        data-status={!$camera
          ? ""
          : $camera.video === false
          ? "unpermitted"
          : $camera.active === true
          ? "active"
          : $camera.loading === true
          ? "requesting"
          : ""}>Scan Barcode</button
      > -->

      <!-- <a  href="/?locate=true">
                Scan Nearby
            </a> -->
    </header>
    <PropertiesList properties={$recent} />
  </nav>
  <ul class="methods">
    <li>
      <a
        href="/scan"
        class="barcode"
        data-status={!$camera
          ? ""
          : $camera.video === false
          ? "unpermitted"
          : $camera.active === true
          ? "active"
          : $camera.loading === true
          ? "requesting"
          : ""}>Scan Barcode</a
      >
    </li>
    <li>
      <a href="/properties/nearby" on:click={(e) => locate()} class="nearby"
        >Scan Nearby</a
      >
    </li>
  </ul>
  <footer>
    <Help
      title="Need help?"
      message="Please contact property or management office with questions"
    />
  </footer>
  <!-- <nav class="options">
    <ul>
      <li><a href="/smartdecal">Activate Smart Decal/Tag</a></li>
      <li><a href="/violation">Lookup Violation</a></li>
    </ul>
  </nav> -->
</main>
