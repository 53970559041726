<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Button from "./Button.svelte";
  export let disabled: boolean = false;
  export let preventDefault: boolean = false;
  let classname = "";
  export { classname as class };
  const events = createEventDispatcher<{
    submit: void;
  }>();
</script>

<Button
  class={classname}
  type="submit"
  {disabled}
  on:click={(e) => events("submit")}><slot /></Button
>
