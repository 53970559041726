<script lang="ts">
  import Warning from "$components/util/Warning.svelte";

  export let policy: PermitIssuePolicy;
  export let tag: "header" | "aside" | "article" | "section" = "aside";
</script>

{#if policy}
  <svelte:element this={tag} class="policy">
    <h1>{policy.title}</h1>
    {#if policy.pricing}
      {#if policy.pricing.always}
        <data value="always" class="pricing">Always</data>
      {:else}
        <data value="partial" class="pricing">Sometimes</data>
      {/if}
    {/if}
    {#if policy.alert?.text}
      <Warning message={policy.alert.text} />
    {/if}
    <slot />
  </svelte:element>
{/if}
