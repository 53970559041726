<script>
  import { createEventDispatcher } from "svelte";
  import { fetchViolation } from "$utils/api";

  export let description = null;

  export let property = null;
  export let item = null;
  const eventing = createEventDispatcher();

  function select(value) {
    item = value;
    if (value) eventing("violation", value);
  }

  let submitting = false;
  let submittable = false;

  async function submit(e) {
    e.preventDefault();
    const form = e.target;

    if (submitting || !submittable) return;

    submitting = true;

    fetchViolation(
      form.elements["violation"]?.value,
      form.elements["property"]?.value,
      form.elements["vehicle"]?.value
    )
      .then(function (json) {
        var item = json.violations.items[json.violations.item];
        item = json.items[item] ?? item;

        if (!item) return alert("No Violation found");

        select(item);
        submitting = false;
      })
      .catch(function (error) {
        logger("error", error);
        submitting = false;
        alert("No violation found");
      });
  }

  function change(e) {
    submittable = !!(e.target.form && e.target.form.checkValidity());
  }
</script>

<form
  class="violation standalone"
  on:input={change}
  on:change={change}
  on:submit={submit}
  class:submitting
>
  <input type="hidden" name="property" value={property?.id || property || ""} />
  {#if description}
    <header>
      <h1>
        {description}
      </h1>
    </header>
  {/if}
  <ul>
    <li>
      <fieldset>
        <label for="violation-number">Number</label>
        <input
          id="violation-number"
          name="violation"
          type="text"
          pattern="[0-9\s]*"
          inputmode="numeric"
          placeholder="0000000"
          autocapitalize="characters"
          autocorrect="off"
          autocomplete="off"
          spellcheck="false"
          maxlength="8"
          required
        />
      </fieldset>
    </li>
    {#if !property}
      <li>
        <fieldset>
          <label for="vehicle-plate">Vehicle</label>
          <input
            id="vehicle-plate"
            type="text"
            maxlength="8"
            autocorrect="off"
            autocapitalize="characters"
            spellcheck="false"
            name="vehicle"
            required
            pattern="[\w\s-]*"
            placeholder="plate/tag"
          />
        </fieldset>
      </li>
    {/if}
  </ul>
  <footer>
    <fieldset>
      <button type="submit" disabled={submitting || !submittable}
        >{submitting ? "Searching…" : "Search"}</button
      >
    </fieldset>
  </footer>
</form>
