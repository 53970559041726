<script lang="ts">
  import { iso } from "$utils/temporal";

  let classname: string | nullish = null;
  export { classname as class };

  export let datetime:
    | string
    | Temporal.Duration
    | Temporal.DurationLike
    | nullish;
  export let definition: string | nullish = undefined;

  $: duration = datetime ? Temporal.Duration.from(datetime) : null;
</script>

<time datetime={iso(duration) ?? ""} class={classname}>
  {#if definition}
    <dfn>{definition}</dfn>
  {/if}
  <abbr>{duration?.total("minutes") || ""} minutes</abbr>
  <slot />
</time>
