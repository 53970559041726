import words from "lodash-es/words";
import capitalize from "lodash-es/capitalize";

export function title(text: string) {
  if (!text) return text;
  return words(text)
    .map((word) => {
      if (word === "BBQ") return word;
      return capitalize(word);
    })
    .join(" ");
}

export function pluralize(count: number, singular: string, plural?: string) {
  if (!plural) plural = singular + "s";
  return count === 1 ? singular : plural;
}
