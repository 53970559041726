<script lang="ts">
  import Field from "$components/form/Field.svelte";
  import Input from "$components/form/Input.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import { createEventDispatcher } from "svelte";
  import { fetchSend } from "$components/send/api";
  import AccountSection from "$sections/AccountSection.svelte";

  export let permit: Permit | null;
  export let policy: PermitIssuePolicy;
  export let values: Record<string, string | nullish> = {};
  //   export let email: string | null;
  //   export let tel: string | null;
  export let label = "Notify";
  //export let submitting: boolean = false;

  let sendemail = true;
  let sendsms = false;

  const events = createEventDispatcher<{
    complete: typeof permit;
  }>();

  $: email = values.email;
  $: tel = values.tel || values.phone;

  // set flags
  $: enabled = true === (policy?.permit?.send ?? policy?.send)?.enabled;
  $: emailenabled = enabled && false !== policy?.send?.email;
  $: smsenabled = enabled && false !== policy?.send?.sms;

  // $: if(!emailenabled) sendemail = false;
  // $: if(!smsenabled) sendsms = false;

  // if we have permit and someone to send to, do the send...

  async function notify(permit: Permit, to: string[] = []) {
    if (!permit) return;
    if (!enabled || !to?.length) {
      // no send to or not enabled, complete immediately
      events("complete", permit);
      return;
    }

    // do the send
    await fetchSend(permit.id, to);

    events("complete", permit);
  }

  // fire off the notify
  $: permit &&
    notify(
      permit,
      [emailenabled && sendemail && email, smsenabled && sendsms && tel].filter(
        Boolean
      ) as string[]
    );

  // as long as there's no permit this is enabled
</script>

<!-- inject our own notify pickers -->
{#if enabled}
  <UiFormFieldList>
    <UiFormFieldItem>
      <Field {label}>
        <label class="send"
          ><Input
            title="{label} by email"
            type="checkbox"
            bind:checked={sendemail}
            disabled={!emailenabled || !email}
          /><span>Email</span></label
        >
        <label class="send"
          ><Input
            title="{label} by text"
            type="checkbox"
            bind:checked={sendsms}
            disabled={!smsenabled || !tel}
          /><span>Text</span></label
        >
      </Field>
    </UiFormFieldItem>
  </UiFormFieldList>
{/if}
<!-- continue -->
<slot />
