<script lang="ts">
  import TextField from "$components/form/TextField.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import { pick } from "lodash-es";
  import { createEventDispatcher } from "svelte";
  export let policy: PermitIssuePolicy;
  export let values: Record<string, string> = {};
  const events = createEventDispatcher<{ change: typeof values }>();
  function change(updated: typeof values) {
    events("change", pick(updated, ["notes", "name", "email", "tel"]));
  }
</script>

<UiFormFieldList>
  {#if policy?.notes?.request}
    <UiFormFieldItem>
      <TextField
        name="notes"
        label="Info"
        required={!!policy.notes.required}
        value={values.notes}
        placeholder={policy.notes.label || null}
        on:change={(e) => change({ notes: e.detail.value })}
      />
    </UiFormFieldItem>
  {/if}
  {#if policy?.name?.request}
    <UiFormFieldItem>
      <TextField
        name="name"
        label="Name"
        required={!!policy.name.required}
        value={values.name}
        autocomplete="name"
        on:change={(e) => change({ name: e.detail.value })}
      />
    </UiFormFieldItem>
  {/if}
  {#if policy?.email?.request}
    <UiFormFieldItem>
      <TextField
        type="email"
        name="email"
        label="Email"
        value={values.email}
        required={!!policy.email.required}
        autocomplete="email"
        multiple
        on:change={(e) => change({ email: e.detail.value })}
      />
    </UiFormFieldItem>
  {/if}
  {#if policy?.tel?.request}
    <UiFormFieldItem>
      <TextField
        type="tel"
        name="tel"
        label="Phone"
        value={values.tel}
        autocomplete="tel"
        required={!!policy.tel.required}
        on:change={(e) => change({ tel: e.detail.value })}
      />
    </UiFormFieldItem>
  {/if}
</UiFormFieldList>
<slot />
