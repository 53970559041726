import {
  base,
  viewpoint,
  responseJson,
} from "$utils/api";



export async function fetchMedia(
  id: string, scope: string
) {
  const url = new URL(`${base()}/media/${id}?viewpoint=${viewpoint()}`);
  // url.searchParams.set("viewpoint", viewpoint());
  // url.searchParams.set("Authorization", authHeader);
  url.searchParams.set("property", scope);
  //url.searchParams.set("id", id);


  return fetch(url).then(async function (res) {
    const json = await responseJson(res);
    if (!res.ok) return json;
    // process media
    return json;
  });
}
