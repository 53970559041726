<script lang="ts" context="module">
  const CACHE: Record<string, Promise<any>> = {}; // we need to store fetched medias

  async function fetch(value: string, scope: string): Promise<Media | null> {
    return (CACHE[value] ??= fetchMedia(value, scope)).then((json) => {
      CACHE[value] = Promise.resolve(json);
      const media = json.media?.item ?? null;
      if (media?.id) CACHE[media.id] = Promise.resolve(json);
      return media;
    });
  }

  function resolve(
    value: Readable<Media | string | null>,
    property: Readable<Property | string | null>
  ) {
    return derived<[typeof value, typeof property], Media | null | false>(
      [value, property],
      ([$value, $property], set) => {
        if (!$value || !$property) return set(null); // waiting
        var scope = typeof $property === "string" ? $property : $property.id;
        if ($value && typeof $value === "string") {
          fetch($value, scope).then((media) => {
            set(media);
          });
        } else {
          set($value as Media);
        }
      }
    );
  }

  function search(
    value: Readable<string | null>,
    property: Readable<Property | string | null>
  ): Readable<Media | false | null> {
    return derived<[typeof value, typeof property], Media | false | null>(
      [value, property],
      ([$value, $property], set) => {
        set(null);
        if (!$value || !$property) return; // waiting
        var scope = typeof $property === "string" ? $property : $property.id;

        fetch($value, scope).then((media) => {
          set(media ?? false);
        });
      }
    );
  }
</script>

<script lang="ts">
  import TextField from "$components/form/TextField.svelte";

  import ValueField from "$components/form/ValueField.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";

  import { createEventDispatcher } from "svelte";
  import { derived, writable } from "svelte/store";
  import MediaScanner from "./MediaScanner.svelte";
  import type { Readable } from "svelte/motion";
  import { fetchMedia } from "./api";
  import Loading from "$components/util/Loading.svelte";
  export let label: string | nullish = "Smart Decal";
  export let property: Property | string | null; // for lookup purposes
  export let value: Media | string | nullish;
  export let readonly: boolean = false;
  export let required: boolean = false;

  let scan = false;

  const valued = writable<Media | string | null>(value);

  // stores to react on
  const prop = writable<Property | string | null>(property);
  const resolved = resolve(valued, prop);
  const query = writable<string | null>(null);
  const result = search(query, prop);

  const eventing = createEventDispatcher<{
    change: typeof value;
  }>();

  function change(updated: typeof value) {
    // if clearing, clear input too
    if (!updated) query.set(null);
    eventing("change", (value = updated));
    scan = false;
  }

  $: valued.set(value || null);
  $: prop.set(property);

  $: record = $resolved;

  $: searching = !!$query && null == $result;

  // if searched resolves, set it
  $: $result && change($result);
</script>

{#if !value}
  <TextField
    name="media"
    {label}
    {required}
    placeholder={required ? "enter number" : "optional"}
    inputmode="numeric"
    maxlength="10"
    on:change={(e) => query.set(e.detail.value)}
  >
    {#if true === searching}
      <figure class="activity" />
    {/if}

    <button
      type="button"
      class="scan media"
      class:scanning={scan}
      on:click={(e) => (scan = !scan)}>Scan</button
    >
  </TextField>
  {#if scan}
    <MediaScanner on:change={(e) => change(e.detail)} />
  {/if}
{:else if false === record}
  Couldn't resolve
{:else if null == record}
  <Loading />
{:else}
  <ValueField label={record?.label ?? label}>
    <RecordItem item={record} />
    {#if !readonly}
      <button class="clear" type="button" on:click={(e) => change(null)}
        >change</button
      >
    {/if}
  </ValueField>
{/if}
<slot />
