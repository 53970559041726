<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { sortBy } from "../../util/sort";
  import Loading from "../util/Loading.svelte";

  const dispatchEvent = createEventDispatcher<{
    change: Record<string, string | nullish>;
  }>();

  const name = "space";

  export let level: string;
  export let values: ByLevels<Space>;
  let all = false;

  $: allLevels = sortBy(Object.entries(values ?? {}), ([level]) => level).map(
    ([spacelevel, item]) => item
  );

  $: currentLevel = values?.[level];
  $: if (!currentLevel && allLevels?.length === 1) {
    currentLevel = allLevels[0];
  }

  // $: logger(
  //   "parking policy = ",
  //   policy,
  //   "level=",
  //   level,
  //   "selected=",
  //   selected
  // );

  function levelclick(e: Event) {
    const button = e.currentTarget as HTMLButtonElement;
    all = false;
    //level = button.value;

    dispatchEvent("change", {
      [button.name]: button.value,
      [name]: null,
    });
  }
</script>

{#if !values}
  <Loading message="Loading levels" />
  <!-- {:else if policy && !Object.values(availability).length}
  <Warning
    message="Sorry, there are no spaces available right now. Please check back later."
  /> -->
{:else}
  <!-- <h1>Parking Availability:</h1> -->

  {#if (all || !currentLevel) && allLevels.length > 1}
    <ul class="levels">
      {#each allLevels as item}
        <li>
          <!-- <input
          type="radio"
          name="level"
          value={spacelevel + ""}
          checked={level == spacelevel}
          on:change={onchange}
        /> -->
          <button
            class="level"
            name="level"
            value={item.level}
            disabled={!item.available || !allLevels.length}
            on:click={levelclick}
          >
            <data class="level" value={item.level}>{item["level:ref"]}</data>

            <data class="available" value={item.available}></data>
            <!-- <data value={item.available}
                >{item.available} of {item.count} space{item.available == 1
                  ? ""
                  : "s"} available</data
              > -->
          </button>

          <!-- {#if level != spacelevel} -->
          <!-- {#if item.available && allLevels.length > 1}
            <button
              name="level"
              type="button"
              value={item.level + ""}
              on:click={levelclick}>select</button
            >
          {/if} -->
          <!-- {/if} -->
        </li>
      {/each}
    </ul>
  {:else if currentLevel}
    <figure class="level">
      <h1>
        <data class="level" value={currentLevel.level}
          >{currentLevel["level:ref"]}</data
        >
      </h1>
      <p>
        <data value={currentLevel.available}
          >{currentLevel.available} of {currentLevel.count} space{currentLevel.available ==
          1
            ? ""
            : "s"} available</data
        >
      </p>
      {#if allLevels.length > 1}
        <button name="all" type="button" on:click={(e) => (all = true)}
          >change&hellip;</button
        >
      {/if}
    </figure>
  {/if}
{/if}

<!-- form-y stuff -->
