<script lang="ts">
  import PropertySection from "$components/property/PropertySection.svelte";

  import Loading from "$components/util/Loading.svelte";
  import PolicyPermitCreate from "$components/policy/PolicyPermitCreate.svelte";
  import { params } from "$utils/params";
  import { smscreen } from "$utils/behaviorstores";
  import Policy from "$components/policy/Policy.svelte";
  import Section from "$components/ui/Section.svelte";
  import { route } from "$utils/router";

  export let policy: PermitIssuePolicy;
  export let referrer: string | nullish = null;
  export let base: string;

  //$: logger("policysection.valid=", valid, policy);

  function create(e: Permit | CustomEvent<Permit>) {
    if (e instanceof CustomEvent) {
      route(`/${e.detail.type}/${e.detail.id}`);
      //location.href = e.detail.url;
    } else {
      route(`/${e.type}/${e.id}`);
      //location.href = e.url;
    }
  }

  $: property = policy.property;
</script>

<PropertySection {property} title={policy?.title}>
  <Section class="policy">
    {#if policy}
      <Policy {policy}>
        <PolicyPermitCreate
          class={$smscreen ? "" : "standalone"}
          {policy}
          values={{
            ...$params,
          }}
          on:create={create}
        />
        <slot />
      </Policy>
    {:else}
      <Loading />
    {/if}
  </Section>
</PropertySection>
