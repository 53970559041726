<script lang="ts">
  import PositionStatus from "$components/position/PositionStatus.svelte";
  import PropertiesList from "$components/property/PropertiesList.svelte";
  import Help from "$components/util/Help.svelte";
  import Image from "$components/util/Image.svelte";
  import { nearbyProperties as nearby } from "$utils/propertystores";
  import { locate, position } from "$utils/uistores";
</script>

<svelte:head>
  <meta name="theme-color" content="#fcfcfc" />
  <title>Scan Nearby</title>
</svelte:head>

<main class="properties nearby utility">
  <header>
    <a href="/">Back</a>
  </header>

  <PositionStatus value={$position} />

  <!-- <figure class="map position">
    <figcaption>
      <Position value={$position} />
      {#if false === $position.requested}
        <button class="locate" type="button" on:click={(e) => locate()}
          >Turn on</button
        >
      {/if}
    </figcaption>
    <Image
      alt="Map of the United States"
      src="https://api.maptiler.com/maps/openstreetmap/static/-103.771556,44.967243,2/600x600.png?key=ArA8Gnz66MxvP3Hnzdj9"
    />
    {#if $position.coords}
      <Image
        alt="Map of your location"
        src="https://api.maptiler.com/maps/openstreetmap/static/{$position
          ?.coords.longitude},{$position?.coords
          .latitude},17/600x600.png?key=ArA8Gnz66MxvP3Hnzdj9"
      />
    {/if}
  </figure> -->
  <nav class="properties">
    <PropertiesList properties={$nearby} />
  </nav>
  <footer>
    <Help
      title="Need help?"
      message="Please contact property or management office with questions"
    />
  </footer>
</main>
