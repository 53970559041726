<script lang="ts">
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import { createEventDispatcher } from "svelte";
  import VehicleField from "$components/vehicle/VehicleField.svelte";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";
  import PolicyError from "./PolicyError.svelte";
  import { errored } from ".";

  const name: string = "vehicle";

  export let policy: PermitIssuePolicy;
  export let values: Record<string, string | nullish> = {};
  let value: Vehicle | string | nullish;
  export let error: any | nullish = null;
  //export let error: any | nullish;

  const eventing = createEventDispatcher<{
    change: typeof values;
  }>();

  function change(updated: typeof value) {
    eventing("change", {
      [name]: (updated as Vehicle)?.id || (updated as string),
    });
  }
  $: value = values[name]; // pull from policy
  $: err = errored(error, name);

  $: request = !!policy?.vehicle?.request;
  $: required = !!policy?.vehicle?.required;
  $: property = policy.property as Property; // need?
</script>

{#if request}
  <UiFormFieldList>
    <UiFormFieldItem>
      <VehicleField {value} on:change={(e) => change(e.detail)} {required} />
      <PolicyError data={err} />
    </UiFormFieldItem>
  </UiFormFieldList>
{/if}
{#if !err && (!required || value)}
  <slot />
{:else if required}
  <PolicyPermitComplete {policy} />
{/if}
