<script lang="ts">
  import ImageField from "$components/form/ImageField.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import { createEventDispatcher } from "svelte";

  // this might need to be flexy?
  const name = "upload";

  export let policy: PermitIssuePolicy;
  //export let value: File[];
  const values: Record<string, File> = {};

  const events = createEventDispatcher<{ change: Record<string, File[]> }>();

  function change(id: string, updated: File | nullish) {
    if (updated) values[id] = updated;
    else delete values[id];
    events("change", {
      [name]: Object.values(values),
    });
  }

  $: enabled = !!policy?.files?.request;
</script>

{#if enabled}
  <UiFormFieldList>
    {#each Object.values(policy.files.items ?? []) as item, i}
      <UiFormFieldItem>
        <ImageField
          label={item.title}
          on:change={(e) => change(i.toString(), e.detail)}
        ></ImageField>
      </UiFormFieldItem>
    {/each}
  </UiFormFieldList>
{/if}
<slot />
