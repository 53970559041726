//import hash from "object-hash";

export async function hash(data: Record<string, any>): Promise<string> {
  const text = new URLSearchParams(data).toString();

  return Array.from(
    new Uint8Array(
      await crypto.subtle.digest("SHA-1", new TextEncoder().encode(text))
    )
  )
    .map((b) => b.toString(16).padStart(2, "0"))
    .join("");

  //   const hashAsString = Array.from(uint8ViewOfHash)
  //   .map((b) => b.toString(16).padStart(2, "0"))
  //   .join("");
  // return hashAsString;
}

export default hash;
