<script lang="ts" context="module">
  let i = 0;
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Select from "./Select.svelte";

  export let name: string = "";
  export let label: string;
  export let required = false;
  export let value: string = "";

  //export let multiple = false;

  const id = `${name || "select"}-${i++}`;

  const events = createEventDispatcher<{
    change: {
      name?: string;
      value: string;
    };
  }>();

  function change(name: string | undefined, newvalue: string) {
    if (newvalue === value) return;
    value = newvalue;
    //if (name)
    logger("selectfield.change", name, newvalue, value);
    events("change", {
      name,
      value,
    });
    //else events("change", value);
  }
</script>

<fieldset>
  {#if label}
    <label for={id}>{label}</label>
  {/if}
  <Select
    {id}
    {name}
    {required}
    {value}
    on:change={({ detail }) => change(detail.name, detail.value)}
    ><slot /></Select
  >
</fieldset>
