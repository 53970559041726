<script lang="ts">
  let classname = "info";

  export let message: string = "";
  export let title = "";
  export { classname as class };

  $: parts =
    message?.split("\n").filter((part) => part.trim().length > 0) ?? [];
</script>

{#if message || title}
  <figure role="alert" class={classname || null}>
    {#if title}
      <figcaption>{title}</figcaption>
    {/if}
    {#each parts as part}
      <p>{part}</p>
    {/each}
    <!-- <p>{message}</p> -->
    <slot />
  </figure>
{/if}
