<script lang="ts" context="module">
  const level = param("level");
</script>

<script lang="ts">
  import Loading from "$components/util/Loading.svelte";
  import { param } from "$utils/params";
  import { hover } from "$utils/behaviorstores";
  import { units, unitsByLevel } from "$utils/propertystores";
  import { query } from "$utils/router";
  import MapFeatureClick from "$components/map/MapFeatureClick.svelte";
  import MapFeatureHover from "$components/map/MapFeatureHover.svelte";
  import MapFeatureState from "$components/map/MapFeatureState.svelte";
  import PropertyMap from "$components/map/PropertyMap.svelte";
  import PropertyDataItem from "$components/property/PropertyDataItem.svelte";
  import UnitsDirectory from "$components/unit/UnitsDirectory.svelte";
  import MapLevelSelect from "$components/map/MapLevelSelect.svelte";
  import MapPositionMarker from "$components/map/MapPositionMarker.svelte";
  import { position } from "$utils/uistores";
  import MapCompass from "$components/map/MapCompass.svelte";
  import PositionWatchButton from "$components/position/PositionWatchButton.svelte";
  import debounce from "lodash-es/debounce";
  import UnitsSearchResults from "$components/unit/UnitsSearchResults.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import SearchForm from "$components/form/SearchForm.svelte";
  import { event, trackPage, type TrackData } from "$utils/track";

  export let property: Property;
  export let referrer: string = null;
  export let unit: Unit = null;

  //$: logger("units=", $units);

  //let query: string = null;

  let tracked = false;
  $: if (property?.id) {
    let trackData: TrackData = {
      propertyId: property.id,
      propertyName: property.name,
      component: "PropertyUnitsSection",
    };
    if (unit?.id && (unit?.display || unit?.name)) {
      event("unit_map_navigation", {
        ...trackData,
        unit: unit.display || unit.name,
      });
    } else if (!tracked) {
      tracked = true;
      trackPage(trackData);
    }
  }

  const filter = [
    "all",
    ["==", ["geometry-type"], "Polygon"],
    ["==", ["get", "type"], "unit"],
    ["has", "ref:boss:subject"],
  ];

  let levels;

  $: if (unit?.id || !unit?.id || $level || !$level) scrollTo(0, 0);

  let searching: string = null;
  const search = debounce((value: string) => {
    logger("change=", value, searching);
    searching = value;
  }, 150);

  $: if (unit?.id) searching = null;
</script>

<svelte:head>
  <meta name="theme-color" content="#fff" />
  <title>{property?.name || "Loading…"}</title>
</svelte:head>

{#if property}
  <main class="map">
    <nav>
      <header>
        <h1>
          <PropertyDataItem {property} />
          <a href="/{property.type}/{property.id}">menu</a>
        </h1>
        <SearchForm
          name="search"
          title="Search units"
          placeholder="Search units"
          value={searching}
          on:change={(e) => search(e.detail.value)}
        >
          <PositionWatchButton />
          <MapLevelSelect
            {levels}
            level={$level}
            on:change={(e) => query({ level: e.detail })}
          />
        </SearchForm>
      </header>
      <UnitsSearchResults query={searching} source={$units} />
      <slot />
      {#if !searching}
        {#if unit}
          <section class={unit.type}>
            <header>
              <h1>
                <figure class="record">
                  <RecordItem item={unit} />
                  <button
                    type="button"
                    on:click={(e) => query({ unit: null })}
                    class="close">close</button
                  >
                </figure>
              </h1>
            </header>
            <!-- <IntercomStartAction subject={unit} /> -->
          </section>
        {:else}
          <section class="available">
            <UnitsDirectory units={$units} />
          </section>

          <!-- {#each sortBy(Object.values($unitsByLevel ?? {}), "level", numericAsc) as level}
          <section class="available">
            <data class="level" value={level.level}>{level["level:ref"]}</data>
            <ul class="items">
              {#each sortBy(Object.values(level.items), "display") as item}
                <li>
                  <a
                    class="record"
                    href="{location.pathname}?selected={item.id}&level={level.level}"
                    ><RecordItem {item} /></a
                  >
                </li>
              {/each}
            </ul>
          </section>
        {/each} -->
        {/if}
      {/if}
    </nav>
    <PropertyMap
      property={property.id}
      level={$level}
      bind:levels
      selected={unit?.id}
      on:level={(e) => query({ level: e.detail }, { history: false })}
      minzoom={16}
    >
      <MapCompass />
      <MapPositionMarker position={$position.coords} />
      <MapFeatureState
        state={(feature) => ({
          selectable: !!$units?.items[feature?.properties["ref:boss:subject"]],
          //selected: unit && unit.id == feature.properties["ref:boss:subject"],
        })}
        {filter}
      />
      {#if $hover}
        <MapFeatureHover {filter} />
      {/if}
      <MapFeatureClick
        {filter}
        on:click={(e) => {
          //logger("map click=", e);
          $units?.items[e.detail.properties["ref:boss:subject"]] &&
            query({ selected: e.detail.properties["ref:boss:subject"] });
        }}
      />
    </PropertyMap>
  </main>
{:else}
  <Loading />
{/if}
