import type { Feature, FeatureCollection } from "geojson";

export interface Levels extends Record<string, Boolean> {
  base: boolean;
  //[key: string]: boolean
}

export function filterLevels(
  geo: FeatureCollection | Feature,
  levels: any
): FeatureCollection {
  //logger("filtering level = ", level);

  const features: Feature[] = [];

  if (!geo)
    return {
      type: "FeatureCollection",
      features,
    } as FeatureCollection;

  if ("features" in geo) {
    //because config contains a type property
    //typescript knows config must be of type A in here

    for (const f of geo.features) {
      if (isInLevels(f, levels)) features.push(f);
    }
  } else {
    if (isInLevels(geo as Feature, levels)) features.push(geo);
  }
  // const features = (geo.features || geo || []).reduce?.((features : Feature[], feature: Feature) => {

  //     if(isInLevels(feature, levels)) features.push(feature);

  //     return features;
  // }, []) ?? [];

  return {
    type: "FeatureCollection",
    features,
  } as FeatureCollection;

  // return Object.assign({}, geo, {
  //     features
  // });
}

export function isInLevels(feature: any, levels?: Levels) {
  const props = feature?.properties ?? feature?.tags;
  if (!props) return true;

  // level has to exist and cannot be null, undefined, or "" -- it can be 0
  const hasLevel = props.hasOwnProperty("level") && props.level != null;
  // && feature.properties.level !== "";
  if (!hasLevel) return levels?.base;

  //if(feature.properties.level == "" && (level == "" || null == level))

  // has level
  //if(null == level) return false; // but we didnt' supply one

  //const testLevel = null == level ? "" : (level+"");

  // do string comparison
  for (const l of props?.level.split
    ? props.level.split(";")
    : [props?.level]) {
    //if(feature.properties.amenity == "parking_entrance") logger("parking entrance level", l+"", "=", testLevel, (l+"") === testLevel);

    if (levels?.[l + ""]) return true;
  }

  return false;
}