<script lang="ts" context="module">
</script>

<script lang="ts">
  import Time from "$components/temporal/Time.svelte";

  import {
    TemporalZonedDateTimeInterval,
    iso,
    datetime,
  } from "$utils/temporal";
  import PermitValidStatus from "./PermitValidStatus.svelte";

  export let permit: Permit;
  // cancelled
  // revoked
  // pending
  let interval = TemporalZonedDateTimeInterval.from(
    permit?.valid.interval,
    permit.timezone
  );

  $: interval = TemporalZonedDateTimeInterval.from(
    permit?.valid.interval,
    permit.timezone
  );
</script>

<dl>
  <dt>Status</dt>
  <dd><PermitValidStatus {permit} /></dd>
</dl>
<dl>
  <dt>Start</dt>
  <dd>
    <Time datetime={interval?.minimum} />
    <!-- <time datetime={iso(interval?.minimum) ?? ""}
      >{datetime(interval?.minimum)}</time
    > -->
  </dd>
  <dt>End</dt>
  <dd>
    {#if interval?.maximum}
      <Time datetime={interval?.maximum} indefinite="when revoked" />
      <!-- <time datetime={iso(interval?.maximum) ?? ""}
        >{datetime(interval?.maximum) || "when revoked"}</time
      > -->
    {:else}
      <time datetime="">when revoked</time>
    {/if}
  </dd>
</dl>
