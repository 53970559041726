<script lang="ts">
  export let label: string | nullish = "";
  export let id: string = "";
  let classname: string = "";
  export { classname as class };
</script>

<fieldset class={classname || null}>
  {#if label}
    <label for={id || null}>{label}</label>
  {/if}
  <slot />
</fieldset>
