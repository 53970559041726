<script>
  import PermitPassInstructions from "$components/permit/PermitPassInstructions.svelte";
  import Help from "$components/util/Help.svelte";
  import InfoTagScanner from "$components/util/InfoTagScanner.svelte";

  //logger(infotag.parse("https://scanme.io?p=7fzs29y28n58v0n4x4tb31rvtr"));

  function onpermit(e) {
    var value = e.detail;
    logger("permit=", value);
    if (value.url) window.location.href = value.url;
  }
</script>

<svelte:head>
  <meta name="theme-color" content="#fcfcfc" />
  <title>Validate</title>
</svelte:head>

<main class="utility">
  <InfoTagScanner on:infotag={onpermit} />
  <PermitPassInstructions />
  <footer>
    <Help
      title="Need help?"
      message="Please contact property or management office with questions"
    />
  </footer>
</main>
