<script lang="ts">
  import Form from "$components/form/Form.svelte";
  import Submit from "$components/form/Submit.svelte";
  import TenantField from "$components/tenant/TenantField.svelte";
  import SelectField from "$components/form/SelectField.svelte";
  import Option from "$components/form/Option.svelte";
  import TenantAuth from "$components/tenant/TenantAuth.svelte";
  import UiHeader from "$components/ui/Header.svelte";
  import UiSection from "$components/ui/Section.svelte";
  import UiTitle from "$components/ui/Title.svelte";
  import Loading from "$components/util/Loading.svelte";
  import Warning from "$components/util/Warning.svelte";
  import ValueField from "$components/form/ValueField.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import PolicyValidField from "./PolicyPermitValidStep.svelte";

  export let policy: PermitIssuePolicy;
  let tenant: Unit | Tenant | nullish;
  let authenticated: boolean = false;
  let ttl: string = "PT24H";
  let valid: string = "false";

  $: logger("valid=", valid);

  $: preauth = true === policy?.preauthentication?.enabled;
  $: loading = null === policy;
  $: logger("policy=", policy);

  $: submittable = policy && tenant && authenticated;

  function submit({ detail: formdata }: CustomEvent<FormData>) {}
</script>

{#if loading}
  <Loading />
{:else if preauth}
  <Form class="editor standalone" on:submit={submit}>
    <UiHeader>
      <UiTitle>Preauthorize {policy.title}:</UiTitle>
    </UiHeader>
    <TenantAuth subject={tenant} bind:authenticated>
      <UiFormFieldItem>
        <TenantField
          required
          items={Object.values(policy.units?.items ?? {})}
          on:change={(e) => (tenant = e.detail.value)}
        />
      </UiFormFieldItem>
    </TenantAuth>

    {#if tenant && authenticated}
      <UiFormFieldList>
        <UiFormFieldItem>
          <SelectField
            label="Date & time"
            value={valid + ""}
            on:change={(e) => (valid = e.detail.value)}
          >
            <Option value="false">let recipient choose</Option>
            <Option value="true">pre-set start & end</Option>
          </SelectField>
        </UiFormFieldItem>
        {#if valid === "true"}
          <PolicyValidField {policy} />
        {:else}
          <UiFormFieldItem>
            <SelectField
              value={ttl}
              name="ttl"
              label="Link expires"
              on:change={(e) => (ttl = e.detail.value)}
            >
              <!-- <Option value="">choose specific time</Option> -->
              <Option value="PT24H">24 hours from now</Option>
              <Option value="PT48H">3 days from now</Option>
              <Option value="PT{7 * 24}H">7 days from now</Option>
            </SelectField>
          </UiFormFieldItem>
        {/if}
      </UiFormFieldList>
      <Submit disabled={!submittable}>Continue</Submit>
    {/if}
  </Form>
{:else}
  <Warning message="Pre-authorization is not enabled for this policy" />
{/if}
