<script lang="ts">
  import Info from "../util/Info.svelte";

  import PolicyIntro from "./PolicyIntro.svelte";

  export let policy: PermitIssuePolicy = null;
</script>

{#if policy}
  <article class="policy">
    <PolicyIntro {policy} tag="header" />
    {#if policy.info?.text}
      <Info message={policy.info.text} />
    {/if}
    <slot></slot>
  </article>
{/if}
