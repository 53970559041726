<script lang="ts" context="module">
</script>

<script lang="ts">
  import debounce from "lodash-es/debounce";
  //import { route, query } from "$utils/router";
  import { propertySearch } from "$utils/propertystores";
  import SearchForm from "$components/form/SearchForm.svelte";
  import PropertiesList from "./PropertiesList.svelte";
  import { writable } from "svelte/store";

  const query = writable<string>("");
  const results = propertySearch(query);

  // let value = $search;

  // $: if (!value) value = $search;

  // const doSearch = debounce(
  //   function (query) {
  //     var qs = new URLSearchParams(location.search);
  //     if (!query) qs.delete("q");
  //     else qs.set("q", query);
  //     value = query;
  //     route(`${location.pathname}?${qs.toString()}`);
  //   },
  //   500,
  //   {
  //     trailing: true,
  //   }
  // );

  // function onInput(e) {
  //   doSearch(e.target.value);
  // }

  //$:result = $searchProperties;

  const search = debounce(function (value) {
    query.set(value);
  }, 250);
</script>

<SearchForm
  class="properties"
  searching={$results?.loading}
  placeholder="Property name or code"
  value={$query}
  on:change={(e) => search(e.detail.value || null)}
/>
<PropertiesList properties={$results} />
