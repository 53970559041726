<script lang="ts" context="module">
</script>

<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import { fetchMedia } from "$components/media/api";
  import { derived, writable } from "svelte/store";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import type { Readable } from "svelte/store";
  import MediaField from "$components/media/MediaField.svelte";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";
  import PolicyError from "./PolicyError.svelte";
  import { errored } from ".";

  const name: string = "media";

  export let policy: PermitIssuePolicy;
  let value: Media | string | nullish;
  export let values: Record<string, string | nullish> = {};
  export let error: any | nullish = null;

  // const valued = writable<Media | string | null>(value);

  //   // stores to react on
  // const prop = writable<Property | string | null>(policy.scope);
  // const resolved = resolve(valued, prop);

  //$: logger("media value = ", value);

  const eventing = createEventDispatcher<{
    change: typeof values;
  }>();

  function change(updated: typeof value) {
    eventing("change", {
      [name]: (updated as Media)?.id || (updated as string),
    });
  }
  $: value = values[name]; // pull from policy
  $: err = errored(error, name);

  // function validate(updated: typeof value) {
  //   // if (typeof updated === "string") updated = Media.from(updated);
  //   // if (updated) change(updated);
  // }

  // $: record = $resolved;

  $: request = !!policy?.media?.request;
  $: required = !!policy?.media?.required;
  $: property = policy.property as Property;
</script>

{#if request}
  <UiFormFieldList>
    <UiFormFieldItem>
      <MediaField
        {value}
        {property}
        {required}
        on:change={(e) => change(e.detail)}
      />

      <PolicyError data={err} />
    </UiFormFieldItem>
  </UiFormFieldList>
{/if}

{#if !request || !required || value}
  <slot />
{:else if required}
  <PolicyPermitComplete {policy} />
{/if}
