<script lang="ts" context="module">
  const nowutc = instant("PT1M");
</script>

<script lang="ts">
  import SpaceField from "$components/space/SpaceField.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import { createEventDispatcher, onMount } from "svelte";
  import PolicyError from "./PolicyError.svelte";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";
  import {
    PermitValidEffectiveInterval,
    PermitValidFromString,
    availableSpaces,
    errored,
  } from ".";
  import { TemporalZonedDateTimeInterval, instant } from "$utils/temporal";
  import PolicyPermitSpaceLockedStep from "./PolicyPermitSpaceLockedStep.svelte";
  import { resolve } from "$components/space";
  import ValueField from "$components/form/ValueField.svelte";

  const name: string = "space";

  const eventing = createEventDispatcher<{
    change: typeof values;
  }>();

  export let policy: PermitIssuePolicy;
  export let values: Params = {};
  //export let available: Spaces | null; // move this logic into the step?
  export let error: any | nullish;

  let valid: TemporalZonedDateTimeInterval = validify(policy, values);

  let locked: any = null;

  let source: Spaces | null = availableSpaces(policy, valid); // move this logic into the step?
  let value: Space | nullish = resolve(values[name] as ParamValue, source);
  //(values[name] && source?.items[values[name]]) || values[name];

  // build valid from policies and incoming values

  // dynamically resolve the available spaces
  $: valid = validify(policy, values);
  $: source = availableSpaces(policy, valid);
  $: single(source); // reevaluate on source change
  $: value = resolve(values[name] as ParamValue, source); // pull from policy

  $: err = errored(error, name);

  $: request = !!policy?.space?.request;
  $: required = !!policy?.space?.required;
  $: proceed = !err && (!required || value);

  //$: source = available ?? (policy?.spaces as Spaces);
  //$: property = policy.property as Property; // need?

  // // move this logic down into the field
  // $: if (Object.values(source?.items ?? {}).length === 1)
  //   change(Object.values(source?.items ?? {})[0]);

  $: logger("policy space field item=", policy, value, values);

  function change(updated: Space | string | nullish) {
    // value is resolved from the values coming in
    // logger(
    //   "space step change=",
    //   updated,
    //   value,
    //   (value as Space)?.id === (updated as Space)?.id || updated === value
    // );
    if ((value as Space)?.id === (updated as Space)?.id || updated === value)
      return; // no actual change?
    eventing("change", {
      [name]: (updated as Space)?.id ?? (updated as string),
    });
  }

  function validify(
    p: typeof policy,
    v: typeof values
  ): TemporalZonedDateTimeInterval {
    return PermitValidEffectiveInterval(
      PermitValidFromString(policy, values),
      $nowutc.withTimeZone(policy.timezone)
    );
    // return ((valid, timezone) =>
    //   new TemporalZonedDateTimeInterval(valid[0], valid[1], timezone))(
    //   PermitValidEffectiveInterval(
    //     PermitValidFromString(policy, values),
    //     $nowutc.withTimeZone(policy.timezone)
    //   ),
    //   policy.timezone
    // );
  }

  function single(source: Spaces | null) {
    if (!source) return;
    if (Object.values(source?.items ?? {}).length !== 1) return;
    change(Object.values(source.items)[0]);
  }

  onMount(() => {
    // logger(
    //   "policy space field mount=",
    //   policy,
    //   valid.toString(),
    //   source,
    //   values
    // );
    single(source);
  });
</script>

{#if request}
  <UiFormFieldList>
    <UiFormFieldItem>
      <SpaceField
        name="space"
        {value}
        {source}
        {required}
        readonly={Object.values(source?.items ?? {}).length === 1}
        on:change={(e) => change(e.detail)}
      />
      <PolicyError data={err} />
      {#if value?.pricing && !policy.pricing}
        <ValueField label="Price">
          <data class="fee" value="yes">{value?.pricing?.description}</data>
        </ValueField>
      {/if}
    </UiFormFieldItem>
  </UiFormFieldList>
{/if}
{#if proceed}
  <PolicyPermitSpaceLockedStep {policy} {values}>
    <slot />
  </PolicyPermitSpaceLockedStep>
{:else if required}
  <PolicyPermitComplete {policy} />
{/if}
