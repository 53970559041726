<script lang="ts" context="module">
  const nowutc = instant("PT1M");
</script>

<script lang="ts">
  import TemporalDateIntervalField from "$components/temporal/TemporalDateIntervalField.svelte";
  import {
    TemporalPlainDateInterval,
    TemporalZonedDateTimeInterval,
    instant,
  } from "$utils/temporal";
  import { createEventDispatcher } from "svelte";
  import {
    PermitValidFromString,
    PermitValidToString,
    errored,
    predefinedIntervals,
  } from ".";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";

  export let policy: PermitIssuePolicy;
  export let values: Record<string, ParamValues>;
  export let error: any | nullish = null;

  let value: TemporalZonedDateTimeInterval | nullish = valued(policy, values);

  const events = createEventDispatcher<{
    change: typeof values;
  }>();

  const name = "valid";

  $: nowzoned = $nowutc.withTimeZone(policy.timezone);
  $: value = valued(policy, values);

  $: valid = !!value;
  $: err = errored(error, name);
  $: bounds = TemporalZonedDateTimeInterval.from(
    policy.validity?.intervals?.bounds,
    policy.timezone
  );

  $: intervals = predefinedIntervals(policy, nowzoned);
  //  Object.entries(policy?.validity.intervals.items ?? {})
  //   .map(([key, value]) =>
  //     TemporalZonedDateTimeInterval.from(key, policy.timezone)
  //   )
  //   .filter(Boolean) as TemporalZonedDateTimeInterval[];

  $: logger(
    "intervals=",
    intervals.map((v) => v.toString())
  );

  $: dateintervals = intervals.map(
    (v) =>
      new TemporalPlainDateInterval(
        v.minimum.toPlainDate(),
        v.maximum.toPlainDate()
      )
  );

  // clear value if doesn't match, need to move this up a level?
  $: if (
    value &&
    intervals &&
    !intervals.find((v) => TemporalZonedDateTimeInterval.compare(v, value))
  )
    change(null); // there is no matching interval for the value

  // $: if (!value && intervals.length)
  //   change(
  //     new TemporalPlainDateInterval(
  //       intervals[0].minimum.toPlainDate(),
  //       intervals[0].maximum.toPlainDate()
  //     )
  //   );

  function valued(
    initpolicy: typeof policy,
    initvalues: typeof values
  ): typeof value {
    const valid = PermitValidFromString(initpolicy, initvalues);
    if (valid?.start && valid.end)
      return new TemporalZonedDateTimeInterval(
        valid.start,
        valid.end,
        policy.timezone
      );
    return null;
  }

  function change(
    updated:
      | TemporalZonedDateTimeInterval
      | TemporalPlainDateInterval
      | nullish
      | CustomEvent<TemporalPlainDateInterval | nullish>
  ) {
    logger("PolicyPermitCalendarStep.change", updated, value);
    if (updated instanceof CustomEvent) return change(updated.detail);
    if (!updated) return events("change", {});
    if (updated instanceof TemporalZonedDateTimeInterval)
      return events(
        "change",
        PermitValidToString({
          start: updated.minimum,
          end: updated.maximum,
        })
      );

    let match = intervals.find((v) => {
      return (
        Temporal.PlainDate.compare(v.minimum.toPlainDate(), updated.minimum) ==
          0 &&
        Temporal.PlainDate.compare(v.maximum.toPlainDate(), updated.maximum) ==
          0
      );
    });
    return change(match);

    // return events("change", PermitValidToString({

    // }));
    // //if (!updated) updated = {};
    // //value = updated;
    // //await tick();
    // logger("calling change event=", PermitValidToString(updated));
    // events(
    //   "change",
    //   PermitValidToString(
    //     updated
    //       ? {
    //           start: updated.start.toPlainDate(),
    //           end: updated.end.toPlainDate(),
    //         }
    //       : null
    //   )
    // );
  }
</script>

<TemporalDateIntervalField
  value={value
    ? new TemporalPlainDateInterval(
        value.minimum.toPlainDate(),
        value.maximum.toPlainDate()
      )
    : null}
  values={dateintervals}
  min={bounds?.minimum.toPlainDate()}
  max={bounds?.maximum.toPlainDate()}
  on:change={change}
/>

{#if valid && !err}
  <slot />
{:else}
  <PolicyPermitComplete {policy} />
{/if}
