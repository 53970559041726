import { param } from "$utils/params";
import { propertyId } from "$utils/propertystores";
import type { Feature, FeatureCollection } from "geojson";
import { derived, writable, type Readable } from "svelte/store";
import { fetchDirectory } from "$utils/api";
import { comparer } from "$utils/sort";
import { filterLevels } from "./levels";
import { type FeatureGroup, groups } from "./";

export const selectedId = param("place");
export const level = param("level");

export const directoryAll = derived<[typeof propertyId], FeatureCollection>(
  [propertyId],
  function updater([$propertyId], set) {
    if (!$propertyId) return set(null);
    fetchDirectory($propertyId).then(set);
  }
);



export const directory = derived<[typeof directoryAll], FeatureGroup[]>(
  [directoryAll],
  ([$directory]) => {
    return $directory?.features
      .reduce(
        (grouped: any[], feature: Feature) => {
          if (!feature.id) return grouped;
          //logger(grouped, feature);
          // find the first matching group and add to it's features
          grouped.find((group) => group.match(feature))?.features?.push(feature);

          return grouped;
        },
        groups.map((group) => ({ ...group, features: [] as Feature[] }))
      )
      .filter((group) => group.features.length)
      .map((group) => {
        group.features.sort(
          comparer((feature: Feature) => feature.properties?.name)
        );
        return group;
      });
  }
);

//directory.subscribe(($value) => logger("directory=", $value));

export const selected = derived<
  [typeof selectedId, typeof directoryAll],
  Feature
>(
  [selectedId, directoryAll],
  ([$selectedId, $directory]) =>
    !!$selectedId &&
    $directory?.features.find((item) => item.id === $selectedId)
);

export const leveled = derived<
  [typeof level, typeof directoryAll],
  FeatureCollection
>([level, directoryAll], ([$level, $directory]) =>
  filterLevels($directory, { base: true, [$level || "outside"]: true })
);
