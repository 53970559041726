import type { Feature, FeatureCollection } from "geojson";
import { derived, type Readable } from "svelte/store";
import { fetchDirectory } from "$utils/api";
import { comparer } from "$utils/sort";
import { filterLevels } from "./levels";

export interface FeatureGroup extends FeatureCollection {
    title: string;
    match: (feature: Feature) => Boolean;
    ///features?: Feature[];
}

export const groups: FeatureGroup[] = [
    {
        type: "FeatureCollection",
        features: [],
        title: "Parking",
        match: (feature: Feature) => feature.properties?.amenity === "parking",
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Offices",
        match: (feature: Feature) => feature.properties?.office,
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Mail & Packages",
        match: (feature: Feature) =>
            [
                "package_lockers",
                "parcel",
                "post_office",
                "post_box",
                "letter_box",
                "post_depot",
            ].includes(feature.properties?.amenity),
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Pets",
        match: (feature: Feature) =>
            feature.properties?.waste === "dog_excrement" ||
            feature.properties?.amenity === "dog_toilet" ||
            feature.properties?.leisure === "dog_park" ||
            feature.properties?.vending === "excrement_bags",
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Laundry",
        match: (feature: Feature) => feature.properties?.shop === "laundry",
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Pools",
        match: (feature: Feature) =>
            feature.properties?.leisure === "swimming_pool",
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Bikes",
        match: (feature: Feature) =>
            ["bicycle_parking", "bicycle_repair_station"].includes(
                feature.properties?.amenity
            ),
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Games & Entertainment",
        match: (feature: Feature) =>
            [
                "cinema",
                "theatre",
                "lounge",
                "library",
                "toy_library",
                "playground",
                "park",
                "garden",
            ].includes(feature.properties?.amenity) ||
            ["amusement_arcade"].includes(feature.properties?.leisure) ||
            ["cornhole"].includes(feature.properties?.sport),
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Fitness & Sports",
        match: (feature: Feature) =>
            feature.properties?.leisure === "fitness_centre" ||
            feature.properties?.leisure === "track" ||
            !!feature.properties?.sport,
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Work & Business",
        match: (feature: Feature) =>
            ["meeting", "coworking_space"].includes(feature.properties?.amenity) ||
            feature.properties?.office === "coworking",
    },
    {
        type: "FeatureCollection",
        features: [],
        title: "Food & Cooking",
        match: (feature: Feature) =>
            ["kitchen", "cafe", "bbq"].includes(feature.properties?.amenity),
    },

    {
        type: "FeatureCollection",
        features: [],
        title: "Other",
        match: (feature: Feature) => feature.properties?.type !== "unit" && feature.properties?.type !== "space",
    },
];



export function features(scope: Readable<string | null | undefined>, unit: Readable<string | null | undefined>, spaces: Readable<string[]>): Readable<FeatureCollection | null> {
    return derived([scope, unit, spaces], ([$scope, $unit, $spaces], set) => {
        if (!$scope) return set(null);
        fetchDirectory($scope, $unit, new Set<string>($spaces)).then(set);
    });
}

export function grouped(features: Readable<FeatureCollection | null | undefined>, template: typeof groups = groups): Readable<FeatureGroup[] | null | undefined> {

    return derived(features, ($directory) => {
        return $directory?.features.reduce(
            (grouped: any[], feature: Feature) => {
                if (!feature.id) return grouped;
                //logger(grouped, feature);
                // find the first matching group and add to it's features
                grouped.find((group) => group.match(feature))?.features.push(feature);

                return grouped;
            },
            template.map((group) => ({ ...group, features: [] as Feature[] }))
        )
            .filter((group) => group.features.length)
            .map((group) => {
                group.features.sort(
                    comparer((feature: Feature) => feature.properties?.name)
                );
                return group;
            });
    });

}

export function selected(id: Readable<string | null | undefined>, features: Readable<FeatureCollection | null>): Readable<Feature | null | undefined> {

    return derived<
        [typeof id, typeof features],
        Feature | null | undefined
    >(
        [id, features],
        ([$id, $features]) =>
            !!$id ? $features?.features.find((item) => item.id === $id) : null
    );
}
export function leveled(level: Readable<string | null | undefined>, features: Readable<FeatureCollection | null>): Readable<FeatureCollection | null | undefined> {

    return derived<
        [typeof level, typeof features],
        Feature | null | undefined
    >(
        [level, features],
        ([$level, $features]) => filterLevels($features, { base: true, [$level || "outside"]: true })
    );
}
// export function leveled = derived<
//     [typeof level, typeof directoryAll],
//     FeatureCollection
// >([level, directoryAll], ([$level, $directory]) =>
//     filterLevels($directory, { base: true, [$level || "outside"]: true })
// );

// = derived<
//     [typeof selectedId, typeof directoryAll],
//     Feature
// >(
//     [selectedId, directoryAll],
//     ([$selectedId, $directory]) =>
//         !!$selectedId &&
//         $directory?.features.find((item) => item.id === $selectedId)
// );