<script lang="ts">
  import { PermitValidToString } from "$components/policy";
  import RecordItem from "$components/record/RecordItem.svelte";
  import { createEventDispatcher } from "svelte";
  import { comparer, sortBy } from "../../util/sort";
  import Loading from "../util/Loading.svelte";

  const name: string = "space";

  const dispatchEvent = createEventDispatcher<{
    change: Record<string, string | nullish>;
  }>();

  export let values: Spaces | nullish;

  $: logger("space availability values=", values);

  function change(updated: Space) {
    logger("space availability change", updated);
    dispatchEvent("change", {
      [updated.type]: updated.id,
      level:
        null == updated.level || updated.level === ""
          ? "outside"
          : updated.level,
    });
  }
</script>

<section class="available">
  {#if !values}
    <Loading message="Loading available spaces" />
  {:else}
    <header>
      <h1>Available Spaces</h1>
    </header>
    <ul class="items">
      {#each Object.values(values.items).sort(comparer("display")) as item}
        <li>
          <button
            name={item.type}
            value={item.id}
            class="record"
            on:click={(e) => change(item)}
          >
            <RecordItem {item} /></button
          >
        </li>
      {/each}
    </ul>
  {/if}
</section>
<!-- form-y stuff -->
