<script lang="ts">
  import { comparer } from "$utils/sort";

  import RecordItem from "$components/record/RecordItem.svelte";
  export let param = "space";
  export let spaces: Spaces;
  $: logger("spaces=", spaces);
</script>

<ul class="items">
  {#each Object.values(spaces?.items ?? {}).sort(comparer((item) => item.display)) as item}
    <li>
      <a
        class="space record"
        href="{location.pathname}?{param}={item.id}&level={item.level || ''}"
        ><RecordItem {item} /></a
      >
    </li>
  {/each}
</ul>
