<script lang="ts" context="module">
  const unitId = param("unit");
  const permitIds = ids(params);
  const permit = single("permit");
  const activePolicy = policy(single("policy"));
</script>

<script lang="ts">
  import Scan from "$sections/ScanSection.svelte";
  import Media from "$sections/MediaLookupSection.svelte";
  import Violations from "$sections/ViolationsSection.svelte";
  import PropertiesSection from "$sections/PropertiesSection.svelte";
  import Validate from "$sections/ValidatePassSection.svelte";
  import PolicySpaceMapSection from "$sections/PolicySpaceMapSection.svelte";
  import { property, units } from "$utils/propertystores";
  import { view } from "$utils/uistores";
  import Loading from "$components/util/Loading.svelte";
  import { param, params, single } from "$utils/params";
  import PropertiesNearbySection from "$sections/PropertiesNearbySection.svelte";
  import MediaSection from "$sections/MediaStatusSection.svelte";
  import { mediaDetails } from "$utils/recordstores";
  import PropertyIndexSection from "$sections/PropertyIndexSection.svelte";
  import PropertyUnitsSection from "$sections/PropertyUnitsSection.svelte";
  import PropertyDirectorySection from "$sections/PropertyDirectorySection.svelte";
  import AccountSection from "$sections/AccountSection.svelte";
  import { policy } from "$components/policy";
  import { tenant, permits } from "$components/tenant/api";
  import {
    ids,
    permits as permitsById,
    permit as permitById,
  } from "$components/permit/api";
  import MediaWalletSection from "$sections/MediaWalletSection.svelte";
  import PermitSection from "$sections/PermitSection.svelte";
  import PolicyPreauthSection from "$sections/PolicyPreauthSection.svelte";
  import PropertyParkingSection from "$sections/PropertyParkingSection.svelte";
  import PolicySection from "$sections/PolicySection.svelte";
  import PermitsSection from "$sections/PermitsSection.svelte";
  import PropertyAmenitiesSection from "$sections/PropertyAmenitiesSection.svelte";
  import AccountMapSection from "$sections/AccountMapSection.svelte";

  //$: if ($property) window.location.href = $property.public.parking;
</script>

{#if "policyspacemap" == $view}
  {#if $activePolicy}
    <PolicySpaceMapSection policy={$activePolicy} values={$params} />
  {:else}
    <Loading />
  {/if}
{:else if "policypreauth" == $view}
  {#if $activePolicy}
    <PolicyPreauthSection
      base="/policies/{$activePolicy.id}/preauth"
      policy={$activePolicy}
    />
  {:else}
    <Loading />
  {/if}
{:else if "policy" == $view}
  {#if $activePolicy}
    <PolicySection
      base="/policies/{$activePolicy.id}/"
      policy={$activePolicy}
    />
  {:else}
    <Loading />
  {/if}
{:else if $view === "properties"}
  <PropertiesSection />
{:else if $view === "scan"}
  <Scan />
{:else if $view === "nearby"}
  <PropertiesNearbySection />
{:else if $view === "smartdecal"}
  <Media />
{:else if $view === "violations"}
  <Violations />
{:else if $view === "validate"}
  <Validate />
{:else if $view === "property"}
  <PropertyIndexSection property={$property} />
{:else if $view === "propertyunits"}
  <PropertyUnitsSection property={$property} unit={$units?.items?.[$unitId]} />
{:else if $view === "propertydirectory"}
  <PropertyDirectorySection property={$property} />
{:else if $view === "propertyparking"}
  <PropertyParkingSection property={$property} />
{:else if $view === "propertyamenities"}
  <PropertyAmenitiesSection property={$property} />
{:else if $view === "account"}
  <AccountMapSection account={$tenant} permits={$permits} />
  <!-- <AccountSection item={$tenant} permits={$permits} /> -->
{:else if $view === "accountmap"}
  <AccountMapSection account={$tenant} permits={$permits} />
{:else if $view === "accountdirectory"}
  <AccountMapSection account={$tenant} permits={$permits} />
{:else if $view === "permits"}
  <PermitsSection permits={permitsById(permitIds)} />
{:else if $view === "permit"}
  <PermitSection permit={permitById(permit)} />
{:else if $view === "media"}
  <MediaSection details={mediaDetails} />
{/if}
<!-- {:else if $mediaId}
  {#if $media && "wallet" == $view}
    <MediaWalletSection media={$media} />
  {:else if $media && "media" == $view}
    <MediaSection media={$media} />
  {:else}
    <Loading />
  {/if}
{/if} -->
