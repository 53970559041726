<script lang="ts" context="module">
  const monthname: Record<number, string> = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };
  var formatter = Intl.DateTimeFormat("en-US", {
    weekday: "long",
    month: "short",
    day: "numeric",
    year: "numeric",
  });
</script>

<script lang="ts">
  import {
    TemporalPlainDateInterval,
    dateparts,
    daysparts,
    iso,
    months,
    plaindate,
    plainyearmonth,
  } from "$utils/temporal";
  import { createEventDispatcher } from "svelte";

  export let value: Temporal.PlainDate | nullish = Temporal.Now.plainDateISO();
  export let min: Temporal.PlainDate | Temporal.PlainDateLike | string =
    Temporal.Now.plainDateISO();
  export let max: Temporal.PlainDate | Temporal.PlainDateLike | string =
    Temporal.Now.plainDateISO();

  export let minimized: boolean = true;

  // normalize min/max into plaindates
  let minbound: Temporal.PlainDate = plaindate(min);
  let maxbound: Temporal.PlainDate = plaindate(max);
  let bounds: TemporalPlainDateInterval = new TemporalPlainDateInterval(
    minbound,
    maxbound
  );

  $: minbound = plaindate(min);
  $: maxbound = plaindate(max);
  $: bounds = new TemporalPlainDateInterval(minbound, maxbound);

  const events = createEventDispatcher<{
    change: typeof value;
  }>();

  function change(updated: typeof value) {
    events("change", updated);
  }
  function active(
    plaindate: Temporal.PlainDate,
    selected: typeof value
  ): boolean {
    return !!selected && Temporal.PlainDate.compare(plaindate, selected) == 0;
  }

  function enabled(
    plaindate: Temporal.PlainDate,
    bounds: TemporalPlainDateInterval
  ): boolean {
    return bounds.contains(plaindate);
  }
</script>

<!-- 
        class:valid={day.selected}
        class:maxvalid={day.maxvalid}
        class:maxvalidmin={day.maxvalidmin}
        class:maxvalidmax={day.maxvalidmax}
        class:validmin={day.validmin}
        class:validmax={day.validmax}
 -->
<time
  class="calendar"
  class:minimal={minimized}
  datetime="{minbound.toString()}/{maxbound.toString()}"
>
  {#each months(plainyearmonth(minbound), plainyearmonth(maxbound)) as yearmonth}
    <time
      class="month days{yearmonth.daysInMonth}"
      datetime={yearmonth.toString()}
    >
      <h1>{monthname[yearmonth.month]} {yearmonth.year}</h1>
      {#if !minimized}
        <time class="weekday sun" datetime="7">S</time>
        <time class="weekday mon" datetime="1">M</time>
        <time class="weekday tue" datetime="2">T</time>
        <time class="weekday wed" datetime="3">W</time>
        <time class="weekday thu" datetime="4">T</time>
        <time class="weekday fri" datetime="5">F</time>
        <time class="weekday sat" datetime="6">S</time>
      {/if}
      {#each daysparts(yearmonth, formatter) as [plaindate, parts]}
        <time
          class="monthday {parts.weekday.toLowerCase()}"
          datetime={iso(plaindate)}
        >
          <button
            value={iso(plaindate)}
            disabled={!enabled(plaindate, bounds)}
            class:active={active(plaindate, value)}
            on:click={(e) => change(plaindate)}
            type="button"
            >{#if minimized}
              <abbr class="weekday" title={parts.weekday}
                >{parts.weekday.substring(0, 3)}</abbr
              >
            {/if}
            {plaindate.day}</button
          ></time
        >
      {/each}
    </time>
  {/each}
</time>
