<script lang="ts" context="module">
  const unitId = param("unit");
  const spaceId = param("space");

  function isInDelimited(findValue: string, delimitedValues: string) {
    //console.log("isInDelimited", findValue, delimitedValues);
    if (!delimitedValues) return false;
    if (findValue == delimitedValues) return true;
    if (!!delimitedValues.split)
      return delimitedValues.split(";").some((valueOrRange) => {
        //console.log("test", valueOrRange, findValue, valueOrRange == findValue);
        if (valueOrRange == findValue) return true;
        const rangeStartEnd = valueOrRange.split("-");
        if (rangeStartEnd.length < 2) return false;

        // const a = +findValue;
        // const b = +rangeStartEnd[0];
        // const c = +rangeStartEnd[1];
        //console.log("test", a, b, c, a >= b && a <= c);
        //if (!isNaN(a) && !isNaN(b) && !isNaN(c)) return a >= b && a <= c;

        // console.log(
        //   "test",
        //   rangeStartEnd[0],
        //   rangeStartEnd[1],
        //   findValue,
        //   findValue >= rangeStartEnd[0] && findValue <= rangeStartEnd[1]
        // );

        //console.log("test", a, b, c, a >= b && a <= c);
        return (
          textAsc(findValue, rangeStartEnd[0]) >= 0 &&
          textAsc(findValue, rangeStartEnd[1]) <= 0
        );
      });
    return false;
  }

  function doesHandlePost(post: any, addr: any) {
    //console.log("doesHandlePost", post, addr);
    // first does street number match
    if (
      post["post:housenumber"] &&
      //!addr["addr:housenumber"] ||
      !isInDelimited(addr["addr:housenumber"], post["post:housenumber"])
    )
      return false;
    if (post["addr:street"] && addr["addr:street"] != post["post:street"])
      return false;

    // do we check city, state, unit, flats, boxes?
    if (
      post["post:box"] &&
      addr["post:box"] &&
      !isInDelimited(addr["post:box"], post["post:box"])
    )
      return false;

    if (
      post["post:flats"] &&
      addr["addr:unit"] &&
      !isInDelimited(addr["addr:unit"], post["post:flats"])
    )
      return false;

    return true;
  }
  const parcelamenities = new Set<string>(["package_lockers", "parcel"]);
  const postamenities = new Set<string>([
    "post_office",
    "post_box",
    "letter_box",
    "post_depot",
  ]);
  // function findPostFeature(
  //   features: FeatureCollection | null,
  //   addressed: Unit
  // ): Feature | null {
  //   if (!features?.features) return null;
  //   if (!addressed?.["post:box"]) return null;

  //   //console.log("findPostFeature", addressed, features);

  //   for (const feature of features.features) {
  //     if (!postamenities.has(feature.properties?.amenity)) continue;
  //     if (!doesHandlePost(feature.properties, addressed)) continue;

  //     return {
  //       ...feature,
  //       properties: {
  //         ...feature.properties,
  //         "post:box": addressed["post:box"],
  //         name: `Mailbox ${addressed["post:box"]}`,
  //         access: "permissive",
  //       },
  //     };
  //   }

  //   return null;
  // }

  function updateUnitPost(
    features: Readable<FeatureCollection | null | undefined>,
    addressed: Readable<Unit | null | undefined>
  ): Readable<FeatureCollection | null> {
    return derived([features, addressed], ([features, addressed]) => {
      if (!features?.features) return features;
      if (!addressed?.["post:box"]) return features;

      for (const feature of features.features) {
        if (!postamenities.has(feature.properties?.amenity)) continue;
        if (!doesHandlePost(feature.properties, addressed)) continue;

        Object.assign(feature.properties, {
          //"post:box": addressed["post:box"],
          name: `Mailbox ${addressed["post:box"]}`,
          access: "permissive",
        });
      }

      return features;
    });
  }
</script>

<script lang="ts">
  import Loading from "$components/util/Loading.svelte";
  import { query } from "$utils/router";
  import PropertyMap from "$components/map/PropertyMap.svelte";
  import PropertyDataItem from "$components/property/PropertyDataItem.svelte";
  import { selectedId as placeId, level } from "$components/geojson/stores";
  import MapMarker from "$components/map/MapMarker.svelte";
  import FeatureDataItem from "$components/geojson/FeatureDataItem.svelte";
  import FeatureSummaryDataItem from "$components/geojson/FeatureSummaryDataItem.svelte";

  import FeatureInfo from "$components/geojson/FeatureInfo.svelte";
  import MapLevelSelect from "$components/map/MapLevelSelect.svelte";
  import { position } from "$utils/uistores";
  import MapPositionMarker from "$components/map/MapPositionMarker.svelte";
  import MapCompass from "$components/map/MapCompass.svelte";
  import PositionWatchButton from "$components/position/PositionWatchButton.svelte";
  import SearchForm from "$components/form/SearchForm.svelte";
  import debounce from "lodash-es/debounce";
  import FeaturesList from "$components/geojson/FeaturesList.svelte";
  import FeaturesSearchResults from "$components/geojson/FeaturesSearchResults.svelte";
  import { event, trackPage, type TrackData } from "$utils/track";
  import { derived, writable, type Readable } from "svelte/store";
  import {
    features,
    grouped as group,
    selected as select,
    leveled as leveler,
  } from "$components/geojson";
  import RecordItem from "$components/record/RecordItem.svelte";
  import type { Feature, FeatureCollection } from "geojson";
  import UnitsDirectory from "$components/unit/UnitsDirectory.svelte";
  import { param } from "$utils/params";
  import SpacesDirectory from "$components/space/SpacesDirectory.svelte";
  import { textAsc } from "$utils/sort";

  export let account: Tenant;
  export let permits: Permits;
  let property: Property | null | undefined = account?.property;
  const us = writable(account?.unit ?? account?.subject);

  const pid = writable<string | null | undefined>(property?.id);
  const uid = writable<string | null | undefined>();
  //account?.subject?.id ?? account?.subject
  const sid = writable<string[]>([]);
  const directory = updateUnitPost(features(pid, uid, sid), us);
  const grouped = group(directory, [
    {
      type: "FeatureCollection",
      features: [],
      title: "Mail & Packages",
      match: (feature: Feature) =>
        parcelamenities.has(feature.properties?.amenity) ||
        (postamenities.has(feature.properties?.amenity) &&
          feature.properties?.access === "permissive"),
    },

    {
      type: "FeatureCollection",
      features: [],
      title: "Offices",
      match: (feature: Feature) => feature.properties?.office,
    },
    {
      type: "FeatureCollection",
      features: [],
      title: "Parking",
      match: (feature: Feature) => feature.properties?.amenity === "parking",
    },
    {
      type: "FeatureCollection",
      features: [],
      title: "Pets",
      match: (feature: Feature) =>
        feature.properties?.waste === "dog_excrement" ||
        feature.properties?.amenity === "dog_toilet" ||
        feature.properties?.leisure === "dog_park" ||
        feature.properties?.vending === "excrement_bags",
    },
    {
      type: "FeatureCollection",
      features: [],
      title: "Laundry",
      match: (feature: Feature) => feature.properties?.shop === "laundry",
    },
    {
      type: "FeatureCollection",
      features: [],
      title: "Pools",
      match: (feature: Feature) =>
        feature.properties?.leisure === "swimming_pool",
    },
    {
      type: "FeatureCollection",
      features: [],
      title: "Bikes",
      match: (feature: Feature) =>
        ["bicycle_parking", "bicycle_repair_station"].includes(
          feature.properties?.amenity
        ),
    },
    {
      type: "FeatureCollection",
      features: [],
      title: "Games & Entertainment",
      match: (feature: Feature) =>
        [
          "cinema",
          "theatre",
          "lounge",
          "library",
          "toy_library",
          "playground",
          "park",
          "garden",
        ].includes(feature.properties?.amenity) ||
        ["amusement_arcade"].includes(feature.properties?.leisure) ||
        ["cornhole"].includes(feature.properties?.sport),
    },
    {
      type: "FeatureCollection",
      features: [],
      title: "Fitness & Sports",
      match: (feature: Feature) =>
        feature.properties?.leisure === "fitness_centre" ||
        feature.properties?.leisure === "track" ||
        !!feature.properties?.sport,
    },
    {
      type: "FeatureCollection",
      features: [],
      title: "Work & Business",
      match: (feature: Feature) =>
        ["meeting", "coworking_space"].includes(feature.properties?.amenity) ||
        feature.properties?.office === "coworking",
    },
    {
      type: "FeatureCollection",
      features: [],
      title: "Food & Cooking",
      match: (feature: Feature) =>
        ["kitchen", "cafe", "bbq"].includes(feature.properties?.amenity),
    },

    {
      type: "FeatureCollection",
      features: [],
      title: "Other",
      match: (feature: Feature) =>
        feature.properties?.type !== "unit" &&
        feature.properties?.type !== "space",
    },
  ]);
  const selected = select(placeId, directory);
  const leveled = leveler(level, directory);

  $: property = account?.property;
  $: pid.set(property?.id);
  //$: uid.set(account?.subject?.id ?? account?.subject);
  $: unit = account?.unit ?? account?.subject;
  $: us.set(unit);
  $: units = [unit].reduce(
    (result, unit) => {
      if (unit) result.items[unit.id] = unit;
      return result;
    },
    { items: {} } as Units
  );
  $: spaces = Object.values(permits?.items ?? {})
    .flatMap((permit) => Object.values(permit?.spaces ?? {}))
    .reduce(
      (result, space) => {
        result.items[space.id] = space;
        return result;
      },
      { items: {} } as Spaces
    );
  // $: if (permits?.items)
  //   sid.set(
  //     Object.values(permits.items).flatMap((permit) =>
  //       Object.values(permit.spaces).map((space) => space.id ?? space)
  //     )
  //   );

  $: unitfeatures = ($directory?.features ?? []).reduce(
    (result, feature) => {
      if (feature.properties?.type === "unit") result.features.push(feature);
      if (feature.properties?.type === "space") result.features.push(feature);
      return result;
      //feature.properties?.[`ref:boss:${uid}`]);
    },
    {
      type: "FeatureCollection",
      features: [],
    } as FeatureCollection
  );

  let levels: Record<string, Leveled>;
  let placeName: string;
  let tracked = false;

  //   $: if (property?.id && $grouped) {
  //     let trackData: TrackData = {
  //       propertyId: property.id,
  //       propertyName: property.name,
  //       component: "PropertyDirectorySection",
  //     };
  //     if (selectedFeature) {
  //       const url = new URL(window.location.href);
  //       const place_id = url.searchParams.get("place");
  //       $grouped.forEach((collection) => {
  //         collection.features.forEach((feature) => {
  //           if (feature.id === place_id) {
  //             placeName = feature.properties?.title || feature.properties?.name;
  //           }
  //         });
  //       });
  //       event("community_map_navigation", { ...trackData, placeName });
  //     } else if (!tracked) {
  //       tracked = true;
  //       trackPage(trackData);
  //     }
  //   }

  $: selectedFeature = $selected;

  $: if ($placeId || !$placeId) scrollTo(0, 0);

  let searching: string | nullish = null;
  const search = debounce((value: string) => {
    logger("change=", value, searching);
    searching = value;
  }, 150);

  $: if ($placeId) searching = null;
</script>

<svelte:head>
  <meta name="theme-color" content="#fff" />
  <title>{property?.name || "Loading…"}</title>
</svelte:head>

{#if property}
  <main class="map">
    <nav>
      <header>
        <h1>
          <PropertyDataItem {property} />
          <a href="/{property.type}/{property.id}">menu</a>
        </h1>
        <SearchForm
          name="search"
          title="Search directory"
          placeholder="Search directory"
          value={searching}
          on:change={(e) => search(e.detail.value)}
        >
          <PositionWatchButton />
          <MapLevelSelect
            {levels}
            level={$level}
            on:change={(e) => query({ level: e.detail })}
          />
        </SearchForm>
      </header>
      <!-- <FeaturesList
        items={{
          type: "FeatureCollection",
          features: ($directory?.features ?? []).filter(
            (f) => !!f.properties?.[`ref:boss:${$uid}`]
          ),
        }}
      /> -->
      <!-- <RecordItem item={unit} /> -->
      <FeaturesSearchResults query={searching} source={$directory} />
      <slot />
      {#if !searching}
        {#if $unitId}
          <article class="feature details">
            <header>
              <h1>
                <RecordItem item={units?.items?.[$unitId]} />
                <button
                  type="button"
                  class=" close"
                  on:click={(e) => query({ unit: null })}
                  >close
                </button>
              </h1>
            </header>
          </article>{:else if $spaceId}
          <article class="feature details">
            <header>
              <h1>
                <RecordItem item={spaces?.items?.[$spaceId]} />
                <button
                  type="button"
                  class=" close"
                  on:click={(e) => query({ space: null })}
                  >close
                </button>
              </h1>
            </header>
          </article>
        {:else if selectedFeature}
          <article class="feature details">
            <header>
              <h1>
                <figure class="record">
                  <FeatureSummaryDataItem item={selectedFeature} /><button
                    type="button"
                    class=" close"
                    on:click={(e) => query({ place: null })}
                    >close
                  </button>
                </figure>
              </h1>
              <!-- <button type="button"
              >change</button
            > -->
            </header>
            <FeatureInfo feature={selectedFeature} />
          </article>
        {:else}
          <section class="directory">
            <!-- <header>
            <PropertyPhoto {property} />
          </header> -->
            {#if unit}
              <!-- <section>
                <h1>My Stuff</h1> -->
              <UnitsDirectory param="unit" {units} />
              <section>
                <h1>Parking</h1>
                <SpacesDirectory param="space" {spaces} />
              </section>

              <!-- <FeaturesList
                items={{
                  type: "FeatureCollection",
                  features: [findPostFeature($directory, unit)].filter(Boolean),
                }}
              /> -->
              <!-- <FeaturesList items={unitfeatures} /> -->
              <!-- </section> -->
            {/if}

            {#each $grouped ?? [] as group}
              <section>
                <h1>{group.title}</h1>
                <FeaturesList items={group} />
              </section>
            {/each}
          </section>
        {/if}
      {/if}
    </nav>
    <PropertyMap
      property={property.id}
      level={$level}
      bind:levels
      on:level={(e) => query({ level: e.detail }, { history: false })}
      minzoom={16}
      selected={$spaceId ?? $unitId}
    >
      <MapCompass />
      <MapPositionMarker position={$position.coords} />
      {#each $leveled?.features.filter((feature) => feature.id) ?? [] as feature}
        <MapMarker
          selected={selectedFeature && selectedFeature.id === feature.id}
          {feature}
          on:click={(e) => query({ place: e.detail.id })}
          ><FeatureDataItem item={feature}
            >{#if selectedFeature && selectedFeature.id === feature.id && (feature?.properties?.name || feature?.properties?.title)}<dfn
                >{feature?.properties.name || feature?.properties.title}</dfn
              >{/if}</FeatureDataItem
          ></MapMarker
        >
      {/each}
    </PropertyMap>
  </main>
{:else}
  <Loading />
{/if}
