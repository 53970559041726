<script lang="ts" context="module">
  import { writable } from "svelte/store";
  import { fetchAccountAuthToken } from "./api";

  export interface Auth {
    generated: string;
    id: string;
    token: string;
    type: "bearer";
    expires: string;
    lifetime: 86400;
    subject: string;
    accounts: {
      item: Tenant;
      items: Record<string, Tenant>;
    };
  }

  // we need to store fetched auths
  const cache = writable<Record<string, Auth>>({});
</script>

<script lang="ts">
  import TextField from "$components/form/TextField.svelte";
  import { createEventDispatcher } from "svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import Submit from "$components/form/Submit.svelte";

  export let subject: string | Unit | Tenant | null;
  export let scope: Property | string | null = null;
  export let authenticated: boolean = false;
  export let reset: string | null | undefined;
  let password: string;
  let submitting: boolean = false;

  $: id =
    ((subject as Tenant)?.subject as Unit)?.id ??
    (subject as any)?.id ??
    (subject as string) ??
    "";
  $: scopeid =
    (subject as any)?.scope ??
    (scope as Property)?.id ??
    (scope as string) ??
    "";

  $: auth = $cache[id];
  $: value = auth?.token && `${auth.type} ${auth.token}`;

  $: authenticated = !!value;

  $: submittable = id && password;

  $: if (auth) events("change", auth);

  const events = createEventDispatcher<{
    change: Auth;
  }>();

  function change(id: string, $auth: Auth) {
    cache.update(($values) => {
      $values[id] = $auth;
      return $values;
    });
    events("change", $auth);
  }

  async function submit() {
    if (submitting) return;
    submitting = true;
    fetchAccountAuthToken(id, password, scopeid).then(($json) => {
      // handle invalid response

      submitting = false;

      if ($json.message) {
        return alert($json.message);
      }

      // save staaatttteeee
      change(id, $json);
    });
  }
</script>

<UiFormFieldList>
  <slot />
  {#if !value}
    <UiFormFieldItem>
      <!-- <input type="hidden" name="Authorization" {value} />
      <ValueField label="Passcode">
        <data class="id auth" value="true">••••</data>
      </ValueField>
    {:else} -->
      <TextField
        name="password"
        on:change={(e) => (password = e.detail.value)}
        label="Passcode"
        disabled={submitting}
        required
      />
    </UiFormFieldItem>
  {/if}
</UiFormFieldList>
{#if !value}
  <footer>
    <fieldset>
      <Submit disabled={submitting || !submittable} on:submit={submit}
        >Continue</Submit
      >
      {#if reset}
        <button type="reset" disabled={submitting}>{reset}</button>
      {/if}
    </fieldset>
  </footer>
{/if}
