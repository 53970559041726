
import { permits } from "./api";
import { TemporalInstantInterval } from "$utils/temporal";

export {
    permits
};

export function cancelled(permit: Permit): boolean {
    return !!permit?.cancelled;
}
export function revoked(permit: Permit): boolean {
    return !!permit?.revoked;
}

const statustitles = {
    "valid": "Active",
    //"revoked": "Revoked",
    "cancelled": "Cancelled",
    "invalid": "Ended",
    "invalidating": "Ending Soon",
    "pending": "Upcoming",
    "validating": "Starting Soon"
}

export function statusTitle(permit: Permit, now: Temporal.Instant): string {
    var life = lifecycle(permit, now);

    //if(life === "invalid" && revoked(permit)) life = "revoked";
    return statustitles[life] || "Unknown";
}

const stages = ["valid", "invalidating", "validating", "pending", "invalid"];

export function lifecycle(permit: Permit, now: Temporal.Instant): string {
    if (cancelled(permit)) return "cancelled";
    for (const stage of stages) {
        const interval = permit.lifecycle[stage];
        if (!interval.includes("/")) continue;
        if (TemporalInstantInterval.from(interval)?.contains(now)) return stage;
    }
    return "indeterminate";
}