<script lang="ts">
  import RecordItem from "$components/record/RecordItem.svelte";
  import { title } from "$utils/text";

  export let permit: Permit;
  $: item = permit;
</script>

{#if permit}
  <dl class="subjects">
    {#if permit.visiting}
      <dt>Reason</dt>
      <dd>{permit.visiting}</dd>
    {/if}
    {#if item.media}
      <dt>
        {title(item.media.title ?? item.media.format ?? item.media.type)}
      </dt>
      <dd>
        <RecordItem item={item.media} />
      </dd>
    {/if}
    {#if item.vehicle}
      <dt>
        {title(item.vehicle.title ?? item.vehicle.format ?? item.vehicle.type)}
      </dt>
      <dd>
        <RecordItem item={item.vehicle} />
      </dd>
    {/if}
    {#if Object.values(item.spaces ?? {}).length}
      <dl>Where</dl>
      {#each Object.values(item.spaces ?? {}) as item}
        <dd>
          <RecordItem {item} />
        </dd>
      {/each}
    {/if}
    {#if item.tenant}
      <dt>
        {title(item.tenant.title ?? item.tenant.format ?? item.tenant.type)}
      </dt>
      <dd><RecordItem item={item.tenant} /></dd>
    {/if}
    <!-- {#each validate as item}
        <dt>{title(item.title ?? item.format ?? item.type)}</dt>
        <dd>
          <ul class="subjects">
            <li>
              <RecordItem {item} />
            </li>
          </ul>
        </dd>
      {/each} -->
  </dl>
{/if}
