<script lang="ts">
  import TextField from "$components/form/TextField.svelte";
  import ValueField from "$components/form/ValueField.svelte";
  import RecordItem from "$components/record/RecordItem.svelte";
  import { createEventDispatcher } from "svelte";
  import { Vehicle } from ".";

  export let label: string | nullish = "Vehicle";
  export let name: string | nullish = "vehicle";
  export let value: Vehicle | string | null = null;
  export let required = false;
  export let readonly = false;

  let results: Vehicle[] = [];

  $: if (typeof value === "string") change(value); // convert immediately

  const eventing = createEventDispatcher<{
    change: typeof value;
  }>();

  function change(updated: typeof value) {
    if (typeof updated === "string") updated = Vehicle.from(updated);

    // if (updated && value && updated.id === ((value as Vehicle).id ?? value)) {
    //   value = updated; // store the latest
    //   return;
    // }

    // do we need a compare here?
    eventing("change", (value = updated));
  }

  function blur(updated: string) {
    setTimeout(() => {
      //if (updated !== textfield.value) return; // no longer has this value
      // const autovalue = Vehicle.from(updated);
      // if (autovalue) change(autovalue);
      change(updated);
    }, 500);
  }

  function search(input: string | nullish) {
    if (!input) return (results = []);
    const created = Vehicle.from(input);
    if (!created) return (results = []);
    results = [created];
  }

  function select(id: string) {
    change(id);
  }

  $: logger("vehicle field=", value);
</script>

{#if value}
  <ValueField {label}
    ><RecordItem item={value} />{#if !readonly}
      <button class="clear" type="button" on:click={(e) => change(null)}
        >change</button
      >
    {/if}</ValueField
  >
{:else}
  <TextField
    {name}
    {label}
    {required}
    {readonly}
    placeholder="enter plate/tag"
    on:change={(e) => search(e.detail.value)}
    on:blur={(e) => false && blur(e.detail.value)}
  />
  <ul class="results">
    {#each results as item}
      <li>
        <button
          class="record"
          type="button"
          value={item.id}
          on:click={(e) => select(e.currentTarget.value)}
          ><RecordItem {item} url={false} /></button
        >
      </li>
    {/each}
  </ul>
{/if}
<slot />
