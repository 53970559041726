<script lang="ts">
  import VehicleMedia from "$components/media/VehicleMedia.svelte";
  import PersonalMedia from "$components/media/PersonalMedia.svelte";
  import {
    media,
    mediaId,
    mediaPermits,
    mediaPolicies,
  } from "$utils/recordstores";
  import Loading from "$components/util/Loading.svelte";
  import type { ComponentType, SvelteComponent } from "svelte";
  import type { Readable } from "svelte/motion";
  export let details: Readable<any | null>;

  const item = media(details);
  const permits = mediaPermits(details);
  const policies = mediaPolicies(details);

  //let property = media?.property;

  $: logger("media", $item, $permits, $policies);

  const types: Record<string, ComponentType<SvelteComponent>> = {
    vehicle: VehicleMedia,
    personal: PersonalMedia,
  };

  $: component = $item ? types[$item.usage] || types.vehicle : null;
</script>

{#if $item}
  <svelte:component
    this={component}
    media={$item}
    permits={$permits}
    policies={$policies}
  />
{:else}
  <Loading />
{/if}
