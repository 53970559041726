<script lang="ts">
  import { datetime as format, iso } from "$utils/temporal";
  export let datetime: Temporal.ZonedDateTime | Temporal.Instant | string;
  export let timezone: string = "";
  export let indefinite: string = "";
</script>

<time datetime={iso(datetime) || ""}
  ><abbr title={datetime.toString()}
    >{format(datetime, timezone) || indefinite}</abbr
  ></time
>
