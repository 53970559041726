<script lang="ts">
  export let property: Property;
  export let title: string | nullish =
    "For questions or assistance please contact";
</script>

{#if property?.contact}
  <aside class="contact">
    {#if title}
      <h1>{title} {property.contact.name || "management office"}</h1>
    {/if}
    <address>
      {#if property.contact.email}
        <a href="mailto:{property.contact.email}">{property.contact.email}</a>
      {/if}
      {#if property.contact.tel}
        <a href="tel:+{property.contact.tel}">{property.contact.tel}</a>
      {/if}
    </address>
  </aside>
{/if}
