import { auth } from "./auth";
import mixpanel from "mixpanel-browser";
import * as Sentry from "@sentry/browser";

Sentry.init({
  dsn: "https://d93985920f3433a81fcff5366b54351a@o4505835498897408.ingest.sentry.io/4505839825649664",
  // ...
});

import Umami from "@bitprojects/umami-logger-typescript";

Umami.initialize({
  baseUrl: "https://umami.is",
  websiteId: "fa083ada-412d-4557-8ad0-85c285f0052b",
});

mixpanel.init("d5bd6e9550f22ab1211d96bc633e3e08", {
  debug: false,
  track_pageview: false,
  persistence: "localStorage",
});

auth.subscribe(($auth) => {
  mixpanel.identify($auth?.user);
  // intercom?
  if (globalThis.hasOwnProperty("Intercom")) {
    // var data = {
    //   user_id: $auth?.user,
    //   email: $auth?.email,
    //   name: $auth?.name,
    //   created_at: $auth?.created / 1000,
    // };
    // logger("intercom=", data);
    globalThis.Intercom(
      "update",
      $auth?.user
        ? {
            user_id: $auth?.user,
            email: $auth?.email,
            name: $auth?.name,
            created_at: $auth?.created / 1000,
          }
        : {
            user_id: null,
            email: null,
            name: null,
            created_at: null,
          }
    );
  }
  if (globalThis.hasOwnProperty("Rollbar"))
    globalThis.Rollbar.configure({
      payload: {
        person: {
          id: $auth?.user,
          username: $auth?.name,
          email: $auth?.email,
        },
      },
    });
});

export interface TrackData {
  component?: string;
  propertyId?: string;
  propertyName?: string;
  policyTitle?: string;
  placeName?: string;
  unit?: string;
  value?: string;
}

export function event(action: string, data: TrackData) {
  mixpanel.track(action, {
    action,
    ...data,
  });
  Umami.trackEvent(action, {
    action,
    ...data,
  });
}

export function trackPage(data: TrackData) {
  mixpanel.track_pageview(data);
}
