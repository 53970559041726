<script lang="ts">
  import Submit from "$components/form/Submit.svelte";
  import { createEventDispatcher } from "svelte";
  export let validating: boolean = false;
  export let submittable: boolean = false;
  export let submitting: boolean = false;
  export let policy: PermitIssuePolicy;

  const events = createEventDispatcher<{
    submit: void;
  }>();
</script>

<footer>
  <fieldset>
    <Submit
      disabled={!submittable || submitting || validating}
      on:submit={(e) => events("submit")}
      >{validating
        ? "Validating"
        : submitting
          ? "Validating"
          : policy.issue?.title}</Submit
    >
  </fieldset>
</footer>
