<script lang="ts">
  import { createEventDispatcher, tick } from "svelte";
  import Record from "$components/record/RecordItem.svelte";
  import Select from "$components/unit/UnitSelect.svelte";

  export let predefined = true;
  export let value: Tenant | Unit | string | null = null;
  export let name = "tenant";
  export let label = "Unit";
  export let placeholder: string | nullish = null;
  export let required = false;
  export let readonly = false;
  export let source: Units | null;

  $: if (typeof value === "string") change(value); // convert immediately
  $: id = (value as Tenant)?.id ?? (value as Unit)?.id ?? "";

  // $: id = value && (value.id || value)?.toString();

  // $: item = value?.id
  //   ? value
  //   : items?.[value] ?? Object.values(items ?? []).find((i) => i.id === value);

  //$: logger("tenant field=", value, id, item, items);

  const evented = createEventDispatcher<{
    change: typeof value;
  }>();

  async function change(updated: typeof value) {
    // change is responsible for resolving the value
    if (typeof updated === "string") updated = source?.items[updated] ?? null;

    // are these some version of the same ref?
    // if (updated && value && updated.id === ((value as Vehicle).id ?? value)) {
    //   value = updated; // store the latest
    //   return;
    // }

    value = updated;
    //await tick();
    evented("change", value);
  }
</script>

{#if value}
  <figure class="value record">
    {#if label}
      <figcaption>{label}</figcaption>
    {/if}
    <input type="hidden" {name} value={id} />
    <Record item={value} url={false} />
    {#if !readonly}
      <button class="clear" type="button" on:click={(e) => change(null)}
        >change</button
      >
    {/if}
  </figure>
{:else}
  <Select
    placeholder={required ? "select…" : "select…"}
    name={!predefined ? name : null}
    {label}
    {required}
    items={Object.values(source?.items ?? {})}
    on:change={(e) => change(e.detail)}
  />
{/if}
