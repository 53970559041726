<script>
  import InfoTagScanner from "$components/util/InfoTagScanner.svelte";
  import MediaScanInstructions from "$components/media/MediaScanInstructions.svelte";
  import SignScanInstructions from "$components/util/SignScanInstructions.svelte";
  import WarningScanInstructions from "$components/violation/WarningScanInstructions.svelte";
  import Instructions from "$components/util/Instructions.svelte";
  import Help from "$components/util/Help.svelte";

  // function onurl(e) {
  //   var url = e.detail;

  //   //logger("url=", url);
  //   if (url != null) {
  //     const tagData = infotag.parse(url);
  //     //logger("tagdata=", tagData);
  //     return (window.location.href = url);
  //   }
  // }
  function infotag(e) {
    var value = e.detail;
    //logger("permit=", value);
    if (value.url) window.location.href = value.url;
  }
</script>

<svelte:head>
  <meta name="theme-color" content="#fcfcfc" />
  <title>Scan</title>
</svelte:head>

<main class="utility">
  <header>
    <a href="/">Back</a>
  </header>
  <InfoTagScanner on:infotag={infotag} />
  <Instructions
    image="/qr-products.png"
    title="Scan sign, tag, or sticker"
    description="Look for the QR code and position it in the frame"
  />
  <!-- <SignScanInstructions />
  <WarningScanInstructions />
  <MediaScanInstructions /> -->
  <!-- <button
  type="button"
  on:click={(e) => {
    scan = false;
    router("/");
  }}>Cancel</button
> -->
  <footer>
    <Help
      title="Need help?"
      message="Please contact property or management office with questions"
    />
  </footer>
</main>
