<script lang="ts" context="module">
</script>

<script lang="ts">
  // responsible for coordinating UI that takes payment and completes the flow
  import ValueField from "$components/form/ValueField.svelte";
  import UiFormFieldItem from "$components/ui/FormFieldItem.svelte";
  import UiFormFieldList from "$components/ui/FormFieldList.svelte";
  import StripePaymentStep from "$components/stripe/StripePaymentStep.svelte";
  import { createEventDispatcher } from "svelte";
  import PolicyPermitComplete from "./PolicyPermitComplete.svelte";

  const name: string = "payment";

  export let policy: PermitIssuePolicy;
  export let payment: Payment | null;
  export let validating: boolean = false;
  export let submittable: boolean = false;
  export let submitting: boolean = false;

  const events = createEventDispatcher<{
    change: Record<string, string | nullish>;
    submit: void;
  }>();

  $: haspricing = !!policy?.pricing?.enabled;

  function submit(e?: CustomEvent<string> | CustomEvent<void> | string) {
    if (typeof e === "string")
      events("change", {
        [name]: (e as string) || "",
      });
    else
      events("change", {
        [name]: e?.detail || "",
      });
    events("submit");
  }

  $: logger("payment=", payment);
</script>

{#if haspricing || !!payment?.required}
  <UiFormFieldList>
    <UiFormFieldItem>
      {#if null == payment}
        <ValueField label="Payment">
          <data class="fee" value="">calculating&hellip;</data>
        </ValueField>
      {:else if payment?.required}
        <ValueField label="Payment">
          <dl>
            {#each Object.values(payment.fees.items) as item}
              <dt>{item.name}</dt>
              <dd>
                <data class="fee" value={item.total.value}
                  >{item.total.display}</data
                >
              </dd>
            {/each}
          </dl>
          <!-- <data class="fee" value={payment.total.value}
          >{payment.total.display}</data
        > -->
        </ValueField>
      {:else if !payment?.required}
        <ValueField label="Payment"
          ><data class="fee" value={payment.total.value}>FREE</data></ValueField
        >
      {/if}
    </UiFormFieldItem>
  </UiFormFieldList>
{/if}
{#if false === payment?.required || validating}
  <!-- no payment, simplified version -->
  <slot />
  <PolicyPermitComplete
    {validating}
    {submittable}
    {submitting}
    {policy}
    on:submit={submit}
  />
{:else if payment?.required}
  <StripePaymentStep {submittable} {submitting} {payment} on:submit={submit}>
    <slot />
  </StripePaymentStep>
{/if}
