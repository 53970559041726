<script lang="ts">
  import PaymentCard from "./PaymentCard.svelte";
  export let cancelled: boolean = false;
  export let payment: any;
</script>

{#if payment}
  <data class={payment.type} value={payment.id}>
    {#if payment.captured}
      <time datetime={payment.captured.datetime}
        >Paid <PaymentCard item={payment.card} /></time
      >
    {:else if cancelled}
      <time datetime="">Cancelled</time>
    {:else if payment.authorized && payment.capture && payment.capture.explicitly}
      <time datetime={payment.authorized.datetime}
        >Holding <PaymentCard item={payment.card} /></time
      >
    {:else if payment.authorized}
      <time datetime={payment.authorized.datetime}
        >Approved <PaymentCard item={payment.card} /></time
      >
    {:else}
      <time datetime={payment.created}
        >Pending <PaymentCard item={payment.card} /></time
      >
    {/if}
  </data>
{/if}
