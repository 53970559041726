import {
  base,
  viewpoint,
  resolveProperty,
  responseJson,
} from "$utils/api";
import { param } from "$utils/params";
import { derived, writable } from "svelte/store";
import throttle from "lodash-es/throttle";
import { resolvePermits } from "$components/permit/api";

export const id = param("account");

export async function fetchTenant(id: string): Promise<Tenants> {
  if (!id) return null;

  const res = await fetch(
    `${base()}/units/tenants?tenant=${id}&viewpoint=${viewpoint()}`
  );
  const json = await res.json();

  // expand subjects
  //   for (const value of Object.values(json.items) as Tenant[]) {
  //     if (value.subject)
  //       value.subject = json.items[value.subject] || value.subject;
  //     if (value.scope) value.property = resolveProperty(value.scope, json);
  //   }

  for (const [k, v] of Object.entries(json.tenants?.items || {})) {
    const value: Tenant = (json.tenants.items[k] =
      json.items[v as string] ?? json.items[k as string] ?? v);
    if (value.subject) value.unit = json.items[value.subject] || value.subject;
    if (value.scope) value.property = resolveProperty(value.scope, json);
  }

  return json.tenants;

  //state.update(prev => merge(prev, json.items));
}

export async function fetchAccountAuthToken(
  id: string,
  password: string,
  scope?: string
): Promise<any> {
  if (!id) return null;

  var url = new URL(
    `accounts/auth/tokens?viewpoint=${viewpoint()}&tenant=${id}&password=${password}`,
    base(true)
  );
  if (scope) url.searchParams.append("scope", scope);

  const res = await fetch(url, {
    method: "POST",
  });
  const $json = await responseJson(res);

  if ($json?.accounts?.item) {
    $json.accounts.item =
      $json.accounts.items[$json.accounts.item] ?? $json.accounts.item;
  }

  return $json;

  //state.update(prev => merge(prev, json.items));
}

export const fetchPermitsForTenant = throttle(
  async function fetchPermits(
    tenant: string,
    valid?: string
  ): Promise<Permits> {
    if (!valid) valid = viewpoint() + "/";
    else valid = encodeURIComponent(valid);

    var url = `${base()}/permits?viewpoint=${viewpoint()}&tenant=${tenant}&valid=${valid}`;
    //logger("permits=", url);

    const res = await fetch(url);
    //logger("spaces res", res);

    const json = await res.json();

    if (json && json.permits) resolvePermits(json.permits, json);

    return json.permits;
  },
  3 * 1000,
  {
    leading: true,
    trailing: true,
  }
);

const data = derived<typeof id, any>(id, ($id, set) => {
  if (!$id) return set(null);

  fetchTenant($id).then(set);
});

export const tenant = derived<[typeof id, typeof data], Tenant>(
  [id, data],
  ([$id, $data]) => {
    if (!$id || !$data) return null;

    return $data.items[$id] as Tenant;
  }
);

export const permits = derived<[typeof id], Permits | null>(
  [id],
  ([$id], set) => {
    if (!$id) return set(null);

    fetchPermitsForTenant($id)?.then(set);
  }
);
