import { param, params } from "$utils/params";
import {
  base,
  viewpoint,
  resolveProperty,
  responseJson,
} from "$utils/api";
import { derived, type Readable } from "svelte/store";
import throttle from "lodash-es/throttle";
//export const id = param("permit");
import { formdata } from "$utils/formdata";

export function ids(input: typeof params): Readable<string[]> {
  return derived(input, $params => {
    console.log("$params", $params);

    const values = new Set<string>();

    for (const key of ["permit", "id"]) {
      const testvalue = $params[key];
      //console.log("testvalue", key, testvalue);
      if (!testvalue) continue;
      if (Array.isArray(testvalue)) {
        for (const value of testvalue) values.add(value);
      } else if (typeof testvalue === "string") {
        values.add(testvalue);
      }
    }

    return [...values];
  })
}


export function permits(id: Readable<string[]>): Readable<Permits | null> {

  return derived(id, ($id, set) => {
    set(null);
    if (!$id || !$id.length) return;
    fetchPermits(...$id).then(set);
  });

}

export function permit(id: Readable<string | null>): Readable<Permit | null> {
  return derived(id, ($id, set) => {
    set(null);
    if (!$id) return;
    fetchPermits($id).then((json) => {
      set(json?.items?.[$id] || null);
    });
  });
}

// export const permit = derived<typeof id, Permit>(id, ($id, set) => {
//   // fetch!!
//   if (null == $id) return null;

//   fetchPermit($id)?.then(($json) => set(Object.values($json.items ?? {})[0]));
// });

async function fetchPermits(...id: string[]): Promise<Permits> {
  var url = new URL(`${base()}/permits?viewpoint=${viewpoint()}&payments=true`);
  //logger("permits=", url);
  for (const i of id) url.searchParams.append("id", i);

  const res = await fetch(url);
  //logger("spaces res", res);

  const json = await responseJson(res);

  resolvePermits(json?.permits, json);

  return json?.permits;
}


// export const fetchPermit = throttle(fetchPermits,
//   3 * 1000,
//   {
//     leading: true,
//     trailing: true,
//   }
// );

export async function fetchPermitCreate(
  data: FormData | Record<string, FormDataEntryValue | FormDataEntryValue[]>,
  issue = true
) {
  const url = new URL(`${base()}/permits?viewpoint=${viewpoint()}`);
  // url.searchParams.set("viewpoint", viewpoint());
  // url.searchParams.set("Authorization", authHeader);
  url.searchParams.set("issue", issue ? "true" : "false");

  const form = formdata(data);

  for (const key of form.keys()) {
    const values = form.getAll(key);
    if (key === "notes") continue; // omit from query
    for (const value of values) {
      if (null == value) continue;
      if ("" === value) continue;
      if (typeof value !== "string") continue;
      //if (value.length > 100) continue;
      url.searchParams.append(key, value);
    }
  }

  // for (const [key, value] of data.entries()) {
  //   if(value.length > 100) continue;
  //   if (null != value) url.searchParams.set(key, value);
  // }

  return fetch(url, {
    method: "POST",
    body: form,
  }).then(async function (res) {
    const json = await responseJson(res);
    if (!res.ok) return json;
    resolvePermits(json?.permits, json);
    return json;
  });
}

export function resolvePermit(value: string, state: any): Permit | nullish {
  if (!value || !state) return null;
  const items = state.items || state;
  const item = items[value] || value;
  if (!item) return item;
  if (typeof item === "string") return null;

  if (!item.id) return null;

  Object.assign(item, {
    property: resolveProperty(items[item.location] || item.location, items),
    address: items[item.address] || item.address,
    policy:
      items[item.issued.policy] ||
      items[item.issued.issuer] ||
      item.issued.policy ||
      item.issued.issuer,
    contact: state.contacts?.items?.[item.id] ?? item.contact, //get(json, ["contacts", "items", item.id], item.contact),
    fees: resolveFees(state.fees?.["for"]?.[item.id], state) ?? item.fees,
    entry: state.entry?.["for"]?.[item.id] ?? item.entry, // ||json.entry?.["for"]?.[item.issued.policy],
  });

  if (item.subjects)
    for (const [k1, v1] of Object.entries(item.subjects || {})) {
      item.subjects[k1] = items[v1 as string] || items[k1] || v1;
    }

  if (item.spaces)
    for (const [k1, v1] of Object.entries(item.spaces || {})) {
      item.spaces[k1] = items[v1 as string] || items[k1] || v1;
    }

  for (const k1 of ["vehicle", "tenant", "media", "space", "source"]) {
    if (!item[k1]) continue;
    item[k1] = items[item[k1]] || item[k1]; // lookup or use existing value
  }

  return item;


}


export function resolvePermits(value: any, state: any): Permits {
  // values is the list of permits, items is the overall state
  const items = (state && state.items) || state;

  if (!value || !items) return value as Permits;

  for (const [k, v] of Object.entries(value.items ?? {})) {
    value.items[k] = resolvePermit(v, state) ?? resolvePermit(k, state);
  }

  // this should already be populated
  if (value.item) value.item = value.items[value.item]; // ?? value.item;

  // expand for
  for (const [k, v] of Object.entries(value["for"] ?? {})) {
    resolvePermits(v, state);
  }

  return value;

}

function resolveFees(values: {
  type: string,
  items: Record<string, string>;
} | Record<string, string>, json: any): {
  type: string,
  items: Record<string, any>;
} | nullish {
  // values is the list of permits, items is the overall state
  const items = (json && json.items) || json;

  logger("resolveFees", values, json);

  if (!values || !items) return;

  if (!values.type) {
    return resolveFees({
      type: "fees",
      items: Object.keys(values).reduce((result, id) => {
        result[id] = id;
        return result;
      }, {} as Record<string, string>)
    }, json);
  }

  // run expansion
  for (const [k, v] of Object.entries(values.items)) {
    const item = values.items[k] = items[v as string] || items[k] || v;
  }


  return values as { type: string, items: Record<string, any> };
}