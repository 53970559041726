<script lang="ts">
  export let permit: Permit;
  $: url = (permit as any)?.pass?.doc.pdf.url.replace(
    "https://passes.parkingboss.com",
    "https://parking-passes.imgix.net"
  );
</script>

{#if url}
  <aside class="pass">
    <h1>Pass Display Required</h1>
    <h2>Print and place on dash</h2>
    <a class="pass" href={url} target="_blank" download> Print </a>
  </aside>
{/if}
