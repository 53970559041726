<script lang="ts">
  import type { FeatureCollection } from "geojson";
  import FeatureSummaryDataItem from "./FeatureSummaryDataItem.svelte";

  export let items: FeatureCollection;
</script>

{#if items?.features.length}
  <ul class="results">
    {#each items.features as item}
      <li>
        <a
          class="feature record"
          href="{location.pathname}?place={item.id ??
            item.properties.id}&level={item.properties.level || ''}"
          ><FeatureSummaryDataItem {item} /></a
        >
      </li>
    {/each}
  </ul>
{/if}
