<script lang="ts">
  import type { Feature } from "geojson";
  import FeatureDataItem from "./FeatureDataItem.svelte";
  import RecordContent from "$components/record/RecordContent.svelte";

  export let item: Feature;
</script>

<FeatureDataItem {item}>
  {#if item.properties.type === "unit"}
    <RecordContent item={item.properties} />
  {:else if item.properties.type === "space"}
    <RecordContent item={item.properties} />
  {:else}
    <dfn>{item.properties.name || item.properties.title}</dfn>
    <address>
      {#if item.properties["building:ref"]}
        <data class="building-ref" value={item.properties["building:ref"]}
          >{item.properties["building:ref"]}</data
        >
      {/if}
      <data class="level" value={item.properties.level ?? "outside"}
        >{item.properties["level:ref"]}</data
      >
    </address>
  {/if}
</FeatureDataItem>
