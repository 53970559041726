<script lang="ts">
  import Loading from "$components/util/Loading.svelte";
  import type { Readable } from "svelte/store";
  import Permit from "$components/permit/Permit.svelte";
  import PropertySection from "$components/property/PropertySection.svelte";
  import PermitPolicy from "$components/permit/PermitPolicy.svelte";

  export let permit: Readable<Permit | null>;

  $: property = $permit?.property as Property;
</script>

{#if $permit}
  <PropertySection {property} title="Permit">
    <!-- {JSON.stringify($permit)} -->
    <section class="permit">
      <Permit permit={$permit}>
        <PermitPolicy permit={$permit} />
      </Permit>
    </section>
  </PropertySection>
{:else}
  <Loading />
{/if}
