<script lang="ts" context="module">
  const subview = param("subview");
  const activePolicy = policy(single("policy"));
</script>

<script lang="ts">
  import MediaRecord from "./MediaRecord.svelte";
  import MediaStatusHeader from "./MediaStatusHeader.svelte";
  import Help from "../util/Help.svelte";
  import PropertyHeader from "../property/PropertyHeader.svelte";
  import { id, policy } from "$components/policy";
  import Loading from "$components/util/Loading.svelte";
  import PolicyPermitCreate from "$components/policy/PolicyPermitCreate.svelte";
  import { param, params, single } from "$utils/params";
  import PropertyTenantAuth from "$components/property/PropertyTenantAuth.svelte";
  import { route } from "$utils/router";
  import type { Auth } from "$components/tenant/TenantAuth.svelte";
  import { title } from "$utils/text";
  import PolicySummary from "$components/policy/PolicySummary.svelte";

  export let media: Media;
  export let permits: Permits;
  export let policies: PermitIssuePolicies;
  const property = media.property;
  const active = Object.values(permits.items).filter((p) => !p.valid.expired);
  $: logger("active=", $activePolicy);

  async function auth({ detail: auth }: { detail: Auth }) {
    if (!auth) return;
    route(`/account/${auth.subject}?token=${auth.token}`);
  }
</script>

<main class="personal media record status">
  <MediaStatusHeader {media} {permits} />
  {#if property}
    <PropertyHeader {property} />
  {/if}
  <MediaRecord {media} />
  <section class="permits">
    <!-- <p>
      Private info is hidden. 
      <a href="/{media.type}/{media.id}/account"
        >I am the owner&hellip;</a
      >
    </p> -->
    <!-- <ul>
      {#each Object.values(active) as permit}
        <li>
          <dl>
            {#if permit.tenant}
              <dt>Unit</dt>
              <dd><RecordItem item={permit.tenant} /></dd>
            {/if}
            {#if permit.name}
              <dt>Name</dt>
              <dd>{permit.name || ""}</dd>
            {/if}
          </dl>
        </li>
      {/each}
    </ul> -->
  </section>
  {#if $subview === "account"}
    <section class="account">
      <PropertyTenantAuth
        {property}
        on:cancel={(e) => route(`/${media.type}/${media.id}`)}
        on:change={auth}
      />
    </section>
  {/if}

  <section class="policies">
    {#if policies.count}
      <ul class="policies">
        {#each Object.values(policies.items) as policy}
          <li>
            <PolicySummary {policy}>
              {#if $activePolicy?.policy === policy.policy}
                <PolicyPermitCreate
                  values={{
                    ...$params,
                  }}
                  policy={$activePolicy}
                />
              {:else if policy.policy === $id}
                <Loading />
              {:else if policy?.issue?.enabled}
                <a href="/policy/{policy.policy}?{media.type}={media.id}"
                  >{title(policy.issue?.title)}</a
                >
              {/if}
            </PolicySummary>
          </li>
          <!-- {#each Object.values(policy.documents?.items ?? {}) as document}
            <li>
              <FileDocument {document} />
            </li>
          {/each} -->
        {/each}
      </ul>
    {:else}
      <Help
        message="Please contact your property management with questions, concerns, or changes"
      />
    {/if}
  </section>
</main>
