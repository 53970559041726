<script lang="ts">
  import Form from "$components/form/Form.svelte";
  import TenantAuth, { type Auth } from "$components/tenant/TenantAuth.svelte";
  import FormFieldItem from "$components/ui/FormFieldItem.svelte";
  import Header from "$components/ui/Header.svelte";
  import Title from "$components/ui/Title.svelte";
  import { createEventDispatcher } from "svelte";
  import PropertyTenantField from "./PropertyTenantField.svelte";

  export let property: Property | string | null | undefined;

  const events = createEventDispatcher<{
    change: Auth;
    cancel: void;
  }>();

  let tenant: Tenant | Unit | string | null = null;
</script>

<Form class="editor" on:reset={(e) => events("cancel")}>
  <header>
    <h1>Unlock</h1>
  </header>
  <TenantAuth
    subject={tenant}
    on:change={(e) => events("change", e.detail)}
    reset="cancel"
  >
    <FormFieldItem>
      <PropertyTenantField
        required
        {property}
        on:change={(e) => (tenant = e.detail)}
      />
    </FormFieldItem>
  </TenantAuth>
</Form>
